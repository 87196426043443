import React, { useState } from 'react'
import './Complogo.css'
// import Carousel from "react-elastic-carousel";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Pagination, Autoplay } from "swiper";
import "swiper/css/pagination";




function Complogo() {

    const img_link = process.env.REACT_APP_IMG_LINK

    const [icons, setIcons] = useState([
        {
            id: "1",
            image: `${img_link}/Icon_1.svg`,
            image2: `${img_link}/Icon_2.svg`,
            image3: `${img_link}/Icon_3.svg`,
            image4: `${img_link}/Comp_icon_4.svg`,
        },

        {
            id: "2",
            image: `${img_link}/Comp_icon_5.svg`,
            image2: `${img_link}/Comp_Icon_6.svg`,
            image3: `${img_link}/Comp_icon_7.svg`,
            image4: `${img_link}/Comp_icon_8.svg`,


        },
        {
            id: "3",
            image: `${img_link}/Comp_icon_9.svg`,
            image2: `${img_link}/Comp_icon_10.svg`,
            image3: `${img_link}/CompIcon_11.svg`,
        },




    ]);

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 1, itemsToScroll: 2 },
        { width: 768, itemsToShow: 1 },
        { width: 1200, itemsToShow: 1 }
    ];

    return (
        <div className="caro_comp_icon">
            <div className='icon-comp-width'>
                <div className="home_max_width">

                    <div className="slide-caro-icon">
                        {/* <Carousel breakPoints={breakPoints}
                            pagination={true}
                            enableAutoPlay={true}
                            enableSwipe={true}
                            focusOnSelect={true}
                        > */}
                         <Swiper
                  spaceBetween={30}
          
                  centeredSlides={true}
                  autoplay={{
                    delay:5000,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  rewind={true}
                  navigation={true}
                  modules={[Autoplay, Pagination]}   className='testimonial-main-con mySwiper'
                >
                        {icons.map((icon, i) => (
                            <SwiperSlide key={i}>
                                <div className='caro-info' key={icon.id}>
                                    <div className="caro_comp_logo">
                                        <img src={icon.image} alt="" />
                                        <img src={icon.image2} alt="" />
                                        <img src={icon.image3} alt="" />
                                        {
                                            icon.image4 ?
                                                <img src={icon.image4} alt="" />
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                </SwiperSlide>
                            ))}
                            </Swiper>
                        {/* </Carousel> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Complogo