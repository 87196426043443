
import { Grid } from '@mui/material'
import React from 'react'
import './Benefits.css'

const Benefits = ({ cardInfo, cardTitle }) => {
  
    return (
        <div className='benefitContaner'>
            <div className='home_max_width'>
                <div className='benefitBody'>
                    <div className='titleTag' style={{marginBottom:'20px', fontWeight:'500'}}>{cardTitle}</div>
                    <Grid container spacing={3}>
                        {
                            cardInfo.map((info, index) => (
                                <Grid key={index} item xs={12} sm={6} md={3}>
                                    <div className='benefitCard'>
                                        <div className='cirular_icon' style={{ background: info.iconColor }}>
                                            {info.icon}
                                        </div>
                                        <div className='benetifTitle'>{info.title}</div>
                                        <div className='benefitSub'>{info.subtitle}</div>
                                    </div>
                                </Grid>
                            ))
                        }
                    </Grid>
                </div>
            </div>
        </div>


    )
}

export default Benefits