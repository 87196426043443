import React, { useEffect } from 'react'
import Overlayheadevent from '../../components/OverLayHeader/Overlayheadevent'
import Complogo from '../../components/Complogo/Complogo';
import Footer from '../../components/Footer/Footer';
import Topmenu from '../../components/Topmenu/Topmenu';
import Webinar_comp from '../../components/Webinar_comp/Webinar_comp'
import Countdown from '../../components/Countdown/Countdown'
import './New_webinar.css'
import { useNavigate } from 'react-router-dom'


function New_webinar() {

  const navigation = useNavigate()
  const img_link = process.env.REACT_APP_IMG_LINK;
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div >

      <div className="head_top">
        <div className="webinar_topic">
        <img onClick={() => navigation('/')} src={`${img_link}/data_head_icon1.svg`} alt='Datamellon icon' style={{ cursor: 'pointer', width: "200px" }} />
        </div>
        <div className="webinar_topic2">
        <img  src={`${img_link}/data_head_icon2.svg`} style={{  width: "100px" }} />

        </div>


      </div>
      {/* <Topmenu /> */}
      <Overlayheadevent
        pretitle=" Cloud-Powered Digital Transformation with AWS"
        title="Public Sector,"
        subtitle=" September 27, 2022 | 10:00AM - 11:30AM CAT      "
      />
      <Webinar_comp />

      <div className="countdown"><Countdown /></div>

      {/* <Complogo/> */}
      <Footer />
    </div>
  )
}

export default New_webinar