import React from 'react'
import './Whypartner2.css'

export default function Whypartner2({ whypart_header, partcontent, }) {
    // const history = useHistory();
    return (
        <div className="partner2">
            
            <div className="partner2text">
                <div className="partner2text-topic">{whypart_header}
                </div>
                <div className="partner2text-info"><p>{partcontent}</p> 
                </div>
            </div>
         </div>

    )
}