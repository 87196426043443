import React, { useEffect } from 'react';
import './Topmenu.css';
import Sidebar from './Sidebar';
import DropDownMenu from '../DropDownMenu/DropDownMenu';
import {useNavigate, useLocation} from 'react-router-dom'
import MailCustomBtn from '../Button/MailCustomButton';
import ServicesDropDownMenu from '../DropDownMenu/ServicesDropDownMenu';
function Topmenu() {

  const img_link = process.env.REACT_APP_IMG_LINK
  const navigation = useNavigate()
  let location = useLocation()

  return (
    <>
      <div className='show_desktop'>
        
        <div className="home_max_width">
         <div className='topBar'>
          <div className="Container-topmenu">
            <div className='displayDesktop'>
              <div className='topbarbody'>
                <img onClick={()=>navigation('/')} src = {`${img_link}/Datamellon_header_logo.svg`} alt='Datamellon icon' style={{cursor:'pointer'}} />
              </div>

                <div className='linksCont' >
                  {/* <span className={location.pathname === "/services" ? 'linksContLinkActive' : "linksContLink"} onClick={()=>navigation('/services')}>Services</span> */}
                  <ServicesDropDownMenu/>
                  <span className={location.pathname === "/partners" ? 'linksContLinkActive' : "linksContLink"} onClick={()=>navigation('/partners')}>Partners</span>
                  <span className={location.pathname === "/products" ? 'linksContLinkActive' : "linksContLink"} onClick={()=>navigation('')}>Products</span>
                  <span className={location.pathname === "/case-studies" ? 'linksContLinkActive' : "linksContLink"} onClick={()=>navigation('/case-studies')}>Case Studies</span>
                  <span className={location.pathname === "/careers" ? 'linksContLinkActive' : "linksContLink"} onClick={()=>navigation("/careers")}>Careers</span>
                  <DropDownMenu/>
                  <MailCustomBtn
                    type='button'
                    txtColor='white'
                    pd='4px'
                    br='7px'
                    fs='14px'
                    w='120px'
                    bg='var(--main)'
                    hoverBG='var(--main)'
                    txt="Let's Talk"
                    fw='600'
                    border="0px solid var(--main)"
                    onClick={() => {}} />
                </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className='show_mobile'>
        <Sidebar />
      </div>
    </>
  )
}

export default Topmenu