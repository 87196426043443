import React, { useEffect } from 'react'
import Footer from '../../components/Footer/Footer'
import Topmenu from '../../components/Topmenu/Topmenu'
import './Awsnewoffice.css'
import { useNavigate } from 'react-router-dom'

function Awsnewoffice() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const img_link = process.env.REACT_APP_IMG_LINK
  const navigate = useNavigate()
  return (
    <div>
      <div>
        <Topmenu />

        <div className="content_container">
          <p className="titlehead-mobile">
            AWS Opens Lagos Office, Increase Commitment to Africa.
          </p>
          <div class="container">
            <div className="flright">
              <img
                className="imgwoman"
                alt=""
                src={`${img_link}/aws-head.png`}
              />
              <p className="namesub">Amrote Abdella</p>
            </div>

            <div className="paragraph_content">
              <p className="titlehead-desktop">
                AWS Opens Lagos Office, Increase Commitment to Africa.
              </p>

              <p className="first_paragraph">
                The new Lagos office is AWS’s latest investment in Africa. Teams
                of AWS account managers, partner managers, solutions architects,
                and other roles support Nigerian customers, running everything
                from development and test environments to big data analytics;
                mobile, web, and social apps; enterprise business applications;
                Internet of Things (IoT); and mission critical workloads.
              </p>

              <p className="second_paragraph">
                The new Lagos office is AWS’s latest investment in Africa. Teams
                of AWS account managers, partner managers, solutions architects,
                and other roles support Nigerian customers, running everything
                from development and test environments to big data analytics;
                mobile, web, and social apps; enterprise business applications;
                Internet of Things (IoT); and mission critical workloads.
              </p>

              <p className="third_paragraph">
                AWS counts some of Nigeria’s fastest-growing and best-known
                startups and businesses as customers, including SeamlessHR,
                Yellow Card, BFree, Bankly, and public sector organizations.
              </p>

              <p className="fourth_paragraph">
                AWS also actively supports Nigeria startups and the community
                with programs like AWS Activate and AWS educational programs
                like AWS Academy, AWS Educate and AWS re/Start.
              </p>

              <p className="fifth_paragraph">
                AWS also actively supports Nigeria startups and the community
                with programs like AWS Activate and AWS educational programs
                like AWS Academy, AWS Educate and AWS re/Start. AWS also
                actively supports Nigeria startups and the community with
                programs like AWS Activate and AWS educational programs like AWS
                Academy, AWS Educate and AWS re/Start.
              </p>

              <p className="sixth_paragraph">
                AWS Activate provides startups with the resources they need to
                get started on AWS, including up to $100,000 in AWS credits,
                training, support, and contact with incubators, accelerators,
                and venture capital firms. AWS Academy helps university students
                and educators develop knowledge and skills about AWS Cloud
                computing, to accelerate cloud-related learning.
              </p>

              <p className="seventh_paragraph">
                AWS Academy member institutions in Nigeria include the
                University of Benin, University of Jos, and Igbinedion
                University. AWS re/Start is a 12-week, in-person, skills-based
                training program that covers fundamental AWS Cloud skills and
                practical career skills, such as interviewing and resume
                writing, to help prepare individuals for entry-level cloud
                positions.
              </p>

              <p className="eigth_paragraph">
                AWS re/Start in Nigeria has expanded the program to three
                locations—Lagos, Edo, and Benue. In Benue state, AWS Partner,
                New Vision Institute Technology, recently opened a campus
                hosting over 100 AWS re/Start learners in Otukpo, Nigeria, a
                remote rural community. AWS also has a vibrant user group in
                Lagos, with hundreds of members that organize local meetups for
                developers to network and share best practices and knowledge.
              </p>

              <span className="ninth_paragraph">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.vanguardngr.com/2022/11/aws-opens-lagos-office-increases-commitment-to-africa/"
                >
                  Sourse: https://www.vanguardngr.com/
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Awsnewoffice
