import React from "react";
import "./CaseStudyComp.css";
import Header_illustration from "./../../assets/images/Header_illustration_2.svg";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

const CaseStudyComp = ({
  title,
  subtitle,
  link,
  date,
  image,
  linkText,
  name,
}) => {
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate(`${link}`)} className="case_c">
      <div
        className={title === "Zencey" || "Trove" ? "topImagV2" : "topImag"}
        style={{ backgroundImage: image }}
      ></div>
      <div className="textares">
        <div className="textares_mini">
          {/* <h3>{title}</h3> */}
          <p>{subtitle}</p>
          <span>{date}</span>
          {/* <Link to={link}>{linkText} <IoIosArrowForward fontSize={12} /></Link> */}
        </div>
      </div>
    </div>
  );
};

export default CaseStudyComp;
