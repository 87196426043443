import React, {useEffect} from 'react'
import './App.css';
import Routing from './Routing';
import AOS from 'aos'
import 'aos/dist/aos.css'; 
import { Helmet } from "react-helmet-async";

function App() {
  useEffect(() => {
    AOS.init({
        duration: 500
      });
}, [])
  return (
    <div>
      {/* <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content={`
            frame-ancestors 'none'
            default-src 'self';
            script-src 'self';
                `}
        ></meta>
      </Helmet> */}
      <Routing/>
    </div>
  );

}

export default App;
