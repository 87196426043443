import React from 'react'
import './NewsRoom.css'
import Header_illustration from './../../assets/images/Header_illustration_2.svg'
import { Link, useNavigate } from 'react-router-dom'
import { IoIosArrowForward } from 'react-icons/io';


const NewsRoomCaseStudy = ({ title, date, subtitle, link, image, linkText, name }) => {
    const navigate = useNavigate()

    return (

        <div onClick={() => navigate(`${link}`)} className='case_c'>
            <div className='topImag' style={{ backgroundImage: image }}>

            </div>
            <div className='textares'>
                <div className='textares_mini'>
                    <h3>{title}</h3>
                    <p>{subtitle}</p>
                    <p>{date}</p>
                    <Link to={link}>{linkText} <IoIosArrowForward fontSize={12} /></Link>
                </div>
            </div>
        </div>
    )
}

export default NewsRoomCaseStudy


