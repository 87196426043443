import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import './NewsRoom.css'
import './../ReadStoryHero/ReadStoryHero.css'
import aws from '../../assets/images/awss.svg'
// import CaseStudyComp from '../CaseStudyComp/CaseStudyComp'
import CaseStudyComp from '../CaseStudyComp/NewsRoomCaseStudy'
import Footer from '../../components/Footer/Footer'
import Topmenu from '../../components/Topmenu/Topmenu'
import { useNavigate } from 'react-router-dom'
import NewsRoomCaseStudy from '../CaseStudyComp/NewsRoomCaseStudy'

const NewsRoom = () => {
  const img_link = process.env.REACT_APP_IMG_LINK
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const users = [
    {
      user_dp: `url("${img_link}/aws_circle.png")`,
      user_name: 'Bamidele Olarewaju',
      link: '/amazon-web-services-outposts-rack-launches-in-nigeria',
      title: 'Amazon Web Services (AWS) launches Outposts Rack in Nigeria.',
      subtitle:
        'Customers in Nigeria can now utilize AWS Outposts rack to store and use their data in a secure manner.',
      date: '22nd July, 2022',
    },
    {
      user_dp: `url("${img_link}/Aws_newsroom.svg")`,
      user_name: 'Bamidele Olarewaj',
      link: '/aws-opens-lagos-office-increases-commitment-to-africa',
      title: 'AWS Open Lagos Office, Increases Commitment to Africa.',
      subtitle:
        'The new Lagos office is part of AWS’s support for the growing number of customers and partners in Nigeria.',
      date: '1st November, 2022',
    },
    {
      user_dp: `url("${img_link}/rising_star.png")`,
      user_name: 'Bamidele Olarewaj',
      link: '/datamellon_awarded_aws_ssa_rising_star_partner',
      title: ' Datamellon Awarded AWS SSA Rising Star Partner of the Year Award 2023.',
      subtitle:
        'AWS recognizes Datamellon as a leader in helping customers drive innovation',
      date: 'December 1st, 2023',
    },
    // {
    //     user_dp: Header_illustration,
    //     user_name: "Bamidele Olarewju",
    //     user_role: "Software Engineer (Mobile)"
    // },
    // {
    //     user_dp: Header_illustration,
    //     user_name: "Bamidele Olaewaju",
    //     user_role: "Software Engineer (Mobile)"
    // },
    // {
    //     user_dp: Header_illustration,
    //     user_name: "Bamidle Olarewaju",
    //     user_role: "Software Engineer (Mobile)"
    // },
    // {
    //     user_dp: Header_illustration,
    //     user_name: "Bamidel Olarewaju",
    //     user_role: "Software Engineer (Mobile)"
    // }
  ]

  return (
    <div>
      <Topmenu />
      <div className="leaderContainer">
        <div className="home_max_width">
          <div className="leaderDev">
            <div className="leaderDevtext">
              <h2 className="titleTag">Newsroom</h2>
              <p className="subtitleTag">View our Newsroom</p>
            </div>
            <div className="leaderGrids">
              <Grid container spacing={{ xs: 3, md: 6 }}>
                {users.map((user) => (
                  <Grid key={user.user_name} item xs={12} sm={6} md={3}>
                    <div
                      className="grid_news"
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate(`${user.link}`)}
                    >
                      {/* <CaseStudyComp
                        title="Amazon Web Services (AWS) launches Outposts Rack in Nigeria"
                        subtitle="Customers in Nigeria can now utilize AWS Outposts rack to store and use their data in a secure manner."
                        link={user.link}
                        linkText="Read more"
                        image={user.user_dp}
                      /> */}
                      <NewsRoomCaseStudy
                        title={user.title}
                        subtitle={user.subtitle}
                        link={user.link}
                        linkText="Read more"
                        image={user.user_dp}
                        date={user.date}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default NewsRoom
