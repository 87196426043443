import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Topmenu from "../../components/Topmenu/Topmenu";
import Overhead from "../../components/OverLayHeader/Overhead";
import "./LambdaAws.css";
import { useNavigate } from "react-router-dom";
import Ready from "../../components/Ready/Ready";

const ApiGateway = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const img_link = process.env.REACT_APP_IMG_LINK;
  const navigate = useNavigate();

  return (
    <div>
      <Topmenu />
      <div className="lambda_main">
        <Overhead
          HeaderBG={`url("${img_link}/Lambda_img1.svg")`}
          title="AWS API Gateway"
          subsub="Datamellon's Tailored API Gateway Solutions"
          subtitle="API Gateway is a critical component for orchestrating communication between diverse systems."
          subtitle1="At Datamellon, an AWS Advanced Consulting Partner, we recognize the pivotal role that API Gateway plays in enabling seamless connectivity, efficient data transfer, and robust security protocols."
        />
        <div className="home_max_width">
          {/* <div
            className="Usecases"
            style={{
              marginTop: "80px",
            }}
          >
            <div className="title">Use cases</div>
            <div className="content_usecases">
              <div className="content">
                <div className="subtitlex">Financial Services</div>
                <div className="normtext">
                  AWS WAF provides a fortified defense against account takeover
                  attempts, phishing attacks, and fraudulent activities,
                  ensuring the confidentiality and integrity of financial data.
                </div>
              </div>
              <div className="content">
                <div className="subtitlex">E-commerce Platforms</div>
                <div className="normtext">
                  AWS WAF ensures the integrity of e-commerce platforms by
                  protecting customer data and transactional processes against
                  SQL injection, cross-site scripting (XSS), and other common
                  attacks.
                </div>
              </div>
              <div className="content">
                <div className="subtitlex"> Healthcare Applications</div>
                <div className="normtext">
                  WAF protects healthcare applications from data breaches,
                  ensuring compliance with industry regulations and maintaining
                  patient confidentiality.
                </div>
              </div>
              <div className="content">
                <div className="subtitlex">Media and Entertainment</div>
                <div className="normtext">
                  Web applications in the media and entertainment sector often
                  face distributed denial of service (DDoS) attacks. AWS WAF
                  shields against these attacks, ensuring uninterrupted access
                  to content for users around the globe.
                </div>
              </div>
            </div>
          </div> */}

          <div>
            <div className="home_max_width">
              <div className="containPadding">
                <div className="titler">Key Features</div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Unified Entry Point</div>
                      <div className="cstudynormtxt2">
                        API Gateway provides a single entry point for accessing
                        multiple services or microservices, simplifying the
                        complexity of the underlying architecture.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Request Routing and Transformation
                      </div>
                      <div className="cstudynormtxt2">
                        It enables dynamic routing of requests to appropriate
                        backend services based on defined rules and the ability
                        to transform request and response payloads to meet
                        specific requirements.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Authentication and Authorization
                      </div>
                      <div className="cstudynormtxt2">
                        API Gateway offers robust authentication mechanisms,
                        including AWS Identity and Access Management (IAM),
                        OAuth, and custom authorizers, ensuring secure access
                        control to APIs.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Traffic Management</div>
                      <div className="cstudynormtxt2">
                        It allows for throttling, caching, and rate limiting of
                        API requests, ensuring optimal performance and resource
                        utilization.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flexer">
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Monitoring and Logging</div>
                      <div className="cstudynormtxt2">
                        API Gateway provides comprehensive monitoring
                        capabilities, including request/response logging,
                        metrics collection, and integration with AWS CloudWatch
                        for real-time insights into API usage and performance.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="home_max_width">
              <div className="containPadding">
                <div className="titler">Use Cases</div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Microservices Architecture
                      </div>
                      <div className="cstudynormtxt2">
                        API Gateway plays a crucial role in orchestrating
                        communication between microservices, enabling seamless
                        interaction and decoupling of services.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Serverless Applications
                      </div>
                      <div className="cstudynormtxt2">
                        In serverless architectures, API Gateway is the front
                        door for invoking AWS Lambda functions facilitating
                        event-driven, scalable applications.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Mobile and Web Applications
                      </div>
                      <div className="cstudynormtxt2">
                        It serves as a backend for frontend (BFF) in mobile and
                        web applications, abstracting complex backend services
                        and providing a unified API interface.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        {" "}
                        Third-Party Integration
                      </div>
                      <div className="cstudynormtxt2">
                        Organizations can leverage API Gateway to expose APIs to
                        third-party developers or integrate with external
                        services, fostering ecosystem growth and innovation.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flexer">
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Legacy System Integration
                      </div>
                      <div className="cstudynormtxt2">
                        API Gateway facilitates the integration of legacy
                        systems with modern cloud-native applications through
                        RESTful APIs or SOAP-to-REST conversion.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Ready />
      </div>
      <Footer />
    </div>
  );
};
export default ApiGateway;
