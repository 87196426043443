import { Grid } from '@mui/material'
import React from 'react'
import './LeaderTeam.css'
import Header_illustration from '../../assets/images/Header_illustration.svg';

const LeaderTeam = () => {

    const users = [
        {
            user_dp: Header_illustration,
            user_name: "Bamidele Olarewaju",
            user_role: "Software Engineer (Mobile)"
        },
        {
            user_dp: Header_illustration,
            user_name: "Bamidele Olarewaj",
            user_role: "Software Engineer (Mobile)"
        },
        {
            user_dp: Header_illustration,
            user_name: "Bamidele Olarewju",
            user_role: "Software Engineer (Mobile)"
        },
        {
            user_dp: Header_illustration,
            user_name: "Bamidele Olaewaju",
            user_role: "Software Engineer (Mobile)"
        },
        {
            user_dp: Header_illustration,
            user_name: "Bamidle Olarewaju",
            user_role: "Software Engineer (Mobile)"
        },
        {
            user_dp: Header_illustration,
            user_name: "Bamidel Olarewaju",
            user_role: "Software Engineer (Mobile)"
        }
    ]

    return (
        <div className='leaderContainer'>
            <div className="home_max_width">
                <div className="leaderDev">
                    <div className="leaderDevtext">
                        <h2 className='titleTag'>
                            Leadership Team
                        </h2>
                        <p className="subtitleTag">
                            Meet the Datamellon Leadership Team
                        </p>
                    </div>
                    <div className="leaderGrids">
                        <Grid container spacing={{ xs: 3, md: 6 }}>
                            {
                                users.map((user) => (
                                    <Grid key={user.user_name} item xs={6} md={3}>
                                        <div className="grid_it">
                                            <img src={user.user_dp} alt="leader dp" className="leader_dp" />
                                            <p className="u_name">
                                                {user.user_name}
                                            </p>
                                            <span className="u_role">
                                                {user.user_role}
                                            </span>
                                        </div>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LeaderTeam
