import React, {useEffect} from 'react'
import Topmenu from '../../components/Topmenu/Topmenu'
import Footer from '../../components/Footer/Footer'
import AllProducts from '../../components/ProductCard/AllProduct'

function ProductPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Topmenu/>
      <AllProducts/>
      <Footer/>
    </div>
  )
}

export default ProductPage