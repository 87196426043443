import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
// import Overlayheadevent from '../../components/OverLayHeader/Overlayheadevent'

import "./New_webinar.css";

import OverlayheadeventB from "../../components/OverLayHeader/OverlayheadeventB";
import AwsStartup from "../../components/Aws_startup/AwsStartup";

import AwsStartup2 from "../../components/Aws_startup/AwsStartuptwo";

function New_webinar_for_startups() {
  const navigation = useNavigate();
  const img_link = process.env.REACT_APP_IMG_LINK;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="awsstartupbg">
      <div className="head_top">
        <div className="webinar_topic">
          <img
            onClick={() => navigation("/")}
            src={`${img_link}/data_head_icon1.svg`}
            alt="Datamellon icon"
            style={{ cursor: "pointer", width: "200px" }}
          />
        </div>
        <div className="webinar_topic2">
          <img
            alt="data_head_icon.svg"
            src={`${img_link}/data_head_icon3.svg`}
            style={{ width: "120px" }}
          />
        </div>
      </div>

      <OverlayheadeventB
        //   pretitle=" Cloud-Powered Digital Transformation with AWS"
        title="ReInvent Your Startup with the Power of the Cloud"
      />

      <AwsStartup
        heading_one={"Zero-fee on-demand AWS Consulting and Support"}
        sub_heading={
          '"We harness the tremendous power of AWS Cloud to help you activate what you need, when you need it. The best bit? Zero cost to you. Less time worrying about cloud means more time to grow your startup."'
        }
        icon={`${img_link}/powerful_automation.svg`}
        main_title={"Powerful automation"}
        sub_title={
          "Simplify and automate the setup and management of your AWS estate from day 1.	"
        }
        icon2={`${img_link}/unlimited_support.svg`}
        main_title2={"Unlimited support - 24/7"}
        sub_title2={
          "Get the answers you need whenyou need them about your AWS Cloud workload.	"
        }
        icon3={`${img_link}/round_the_clock.svg`}
        main_title3={"Round the clock Solution Architects"}
        sub_title3={
          "Work wih our certified AWS Solution Architects and Developers, and benefits from decades of thorough cloud-native knowledge and experiences across industry vericals."
        }
      />

      <AwsStartup2
        heading_one={"Foremost zero-fee Managed Services Provider"}
        sub_heading={
          '"Choosing the right cloud solution is a complex undertaking. Our priority is to support you to envision and execute a holistic, value-driven, end-to-end business digital transformation with tangible values - architecture review, cost optimisation and advance automation."'
        }
        icon4={`${img_link}/design_build_deploy.svg`}
        main_title4={"Optimize, Scale & Billing Management"}
        sub_title={` "Which technology is most secure, scalable and cost efficient?"
"Get expert recommendations tailored for your business and ensure deployments are designed and built to scale from the start."`}
        icon5={`${img_link}/optimise.svg`}
        main_title5={"Unlimited support - 24/7"}
        sub_title2={
          "Get the answers you need whenyou need them about your AWS Cloud workload.	"
        }
        icon6={`${img_link}/reinvent.svg`}
        main_title6={"Reinvent with Data"}
        sub_title3={
          "Work wih our certified AWS Solution Architects and Developers, and benefits from decades of thorough cloud-native knowledge and experiences across industry vericals."
        }
      />

      <div
        style={{
          marginTop: "100px",
        }}
      >
        <Footer />
      </div>
    </div>
  );
}

export default New_webinar_for_startups;
