import React, { useEffect } from 'react'
import Footer from '../../../components/Footer/Footer'
import Topmenu from '../../../components/Topmenu/Topmenu'
import './Cstudy1.css'

export default function Drcc() {
  const img_link = process.env.REACT_APP_IMG_LINK
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Topmenu />
      <div className="cstudy1">
        <div className="home_max_width">
          <div className="casestudcontainer">
            <div className="csheadbg"></div>
            <div className="cstudbody">
              <div className="cstudyclient">
                <div>
                  <div className="cstudytopic">Business need</div>
                  <div className="cstudynormtxt">
                    <p>
                      As part of Vodafone multi-cloud strategy, there is a need
                      to have an API driven automated pipeline for provisioning
                      compartments in OCI DRCC regions across Europe for
                      different local market tenants. The compartments need to
                      be configured and hardened with security baselines and
                      guardrails of the company.
                    </p>
                  </div>

                  {/* <div className="cstudynormtxt">
                    Datamellon are a trusted partner on <b>Vodafone's</b> cloud
                    adoption journey.
                    <p>
                      The skills from their team have helped accelerate the use
                      of AWS within our global organisation.
                    </p>
                  </div>
                  <div className="cstudysmalltxt">
                    - Oj Adekoya | Principal Cloud Architect GDC Public Cloud
                    Services
                  </div> */}
                </div>
                <div className="vodafone">
                  <img src={`${img_link}/vodafone_new.svg`} alt="" />
                </div>
              </div>
              {/* <div className="cstudychallenge">
                <div className="cstudytopic">The Challenge</div>
                <div className="cstudynormtxt">
                  Vodafone Group Public Cloud Services(PCS) team manages the
                  global public cloud estate of Vodafone all over the world.
                  Business divisions and the local market’s journey to AWS cloud
                  starts with an AWS account to be provisioned, configured and
                  hardened with security baselines and guardrails by the PCS
                  team. The team needs to evolve an innovative self-service
                  solution to enable everyone in Vodafone wherever they are in
                  the world to create an AWS account within minutes.
                </div>
              </div> */}
              <div className="cstudysolution">
                <p>
                  <div className="cstudytopic">Solution</div>
                  <div className="cstudynormtxt">
                    Datamellon architected, designed and built a serverless OCI
                    DRCC compartment provision pipeline solution with a number
                    of AWS managed serverless services.
                  </div>
                </p>
                <div className="icon_text_sub_cont">
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">API Integration</div>
                      <div className="cstudynormtxt2">
                        Amazon API Gateway was used to create, publish, monitor
                        and secure all the solution APIs and route requests to
                        appropriate backend Lambda functions. API Gateway
                        handles all the tasks involved in accepting and
                        processing up to hundreds of thousands of concurrent API
                        calls, including traffic management, CORS support,
                        authorization and access control, throttling,
                        monitoring, and API version management.
                      </div>
                    </div>
                  </div>

                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Compute</div>
                      <div className="cstudynormtxt2">
                        The application backend logic was implemented with AWS
                        Lambda. AWS Lambda automatically runs the application
                        code without requiring us to provision or manage
                        servers. Lambda continuously scales with sub second
                        metering and consistent performance.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Database</div>
                      <div className="cstudynormtxt2">
                        Amazon DynamoDB Global Tables with deployments in two
                        regions. Fully managed NoSQL service that provides fast
                        and predictable performance with seamless scalability.
                        DynamoDB performs all of the necessary tasks to maintain
                        and propagate ongoing data changes between the tables
                        across the regions.
                      </div>
                    </div>
                  </div>

                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Deployment</div>
                      <div className="cstudynormtxt2">
                        The solution was deployed with AWS native continuous
                        integration and continuous deployment (CI/CD) toolchains
                        of AWS CodeCommit, CodePipeline and CodeBuild.
                        Deployment artifacts are stored in Amazon Simple Storage
                        Service (Amazon S3). Amazon Simple Storage Service
                        (Amazon S3) is an object storage service that offers
                        industry-leading scalability, data availability,
                        security and performance.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Security</div>
                      <div className="cstudynormtxt2">
                        All data is encrypted at rest and in transit. Deployment
                        artifacts are encrypted with strong, self-signed
                        cryptographic customer keys managed by AWS KMS. User
                        access management was implemented with Amazon Cognito. A
                        simple and secure user sign-up, sign-in, and access
                        control.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cstudybenefits">
                <div className="cstudytopic">The Benefits</div>
                <div className="cstudynormtxt">
                  The solution enables the Vodafone data center team to
                  provision compartment with the pipeline seamlessly with
                  Vodafone security guardrails under the respective local market
                  tenant account within minutes without any interaction with any
                  member of the PCS team. The solution freed up a significant
                  amount of time for the PCS team to focus on other innovations.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
