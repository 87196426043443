import React, { useEffect } from 'react'
import Footer from '../../components/Footer/Footer'
import Topmenu from '../../components/Topmenu/Topmenu'

import { useNavigate } from 'react-router-dom'

import './Newsroom.css'

export default function AwsNewsroom() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const img_link = process.env.REACT_APP_IMG_LINK
  const navigate = useNavigate()

  return (
    <div>
      <div className="outpost_container">
        <Topmenu />

        <div className="container_news">
          <div className="main_cont">
            <div className="inner_top_upper">
              <h1 className="upper_title-mobile">
                Amazon Web Services Outposts Rack Launches in Nigeria
              </h1>
            </div>

            <div className="upper_section">
              <div className="left_upper_section">
                <div className="inner_top_upper">
                  <h1 className="upper_title-desktop">
                    Amazon Web Services Outposts Rack Launches in Nigeria
                  </h1>
                </div>

                <div className="upper_bottom_content">
                  <p className="sub_title">
                    Amazone Web Services Outposts Rack Launches in Nigeria
                  </p>

                  <div className="upper_left_main_content">
                    <p className="first_text">
                      Amazon Web Service (AWS), a leading provider of e-commerce
                      technology, has introduced AWS Outposts to improve the
                      delivery of its extensive and well-liked cloud solutions
                      in Nigeria. After announcing that it would expand to
                      Nigeria in April 2023, this may be the first move toward
                      establishing a presence here.
                    </p>
                    <p className="second_text">
                      In order to enable customers to run AWS services
                      on-premises, such as compute, storage, and databases, AWS
                      Outposts are fully managed and programmable computing and
                      storage racks constructed with hardware designed by AWS.
                      These racks allow for seamless connection to AWS's
                      extensive range of services in the cloud.
                    </p>
                    <p className="third_text">
                      Data centers, co-location facilities, and on-premises
                      facilities can now benefit from an AWS infrastructure and
                      operational models thanks to the AWS Outposts rack.
                      Customers can deliver a consistent experience by using the
                      same APIs, control plane, tools, and hardware on-premises
                      as in the AWS Regions with AWS Outposts rack.
                    </p>
                  </div>
                </div>
              </div>

              <div className="right_upper_section">
                {/* <img
                  src="https://res.cloudinary.com/femakin/image/upload/v1667433824/image_16_2_ivbvrb.png"
                  alt="amazonpageimg"
                  className="circle_upper_img"
                /> */}
                <img
                  className="circle_upper_img"
                  alt="amazonpageimg"
                  src={`${img_link}/aws-servers.png`}
                />
              </div>
            </div>

            <div className="middle_section">
              <h1 className="middle_text">
                Speaking on the development,{' '}
                <span className="middlename">Amrote Abdella</span>, General
                Manager at AWS, Sub-Saharan Africa, said.
              </h1>
            </div>

            <div className="second_middle">
              <p className="first_inner_middle">
                “We are excited to bring AWS Outposts rack to Nigeria as we
                continue to deliver advanced cloud services to meet AWS
                customer's business needs. Customers in Nigeria who have
                workloads that require low latency, data processing, or data
                storage on premises can benefit from AWS Outposts.”
              </p>

              <p className="second_inner_middle">
                “These workloads include applications that might need to
                generate near real-time responses, communicate with other
                on-premises systems, or control on-site equipment, such as
                factory floor equipment, health management systems, and retail
                point-of-sale systems. Customers can also use AWS Outposts rack
                to securely store and use customer data in Nigeria, which is
                important for organizations in highly regulated industries and
                data sovereignty requirements”, he added.
              </p>
            </div>

            <div className="awsoutposts">
              <p className="aws_title_outpost">What the AWS Outposts bring</p>

              <div className="customers_content">
                <p className="first_customer">
                  Customers can create and operate apps on-premises using the
                  same programming APIs as in AWS Regions thanks to AWS Outposts
                  rack. Customers using AWS Outposts can select from a variety
                  of standard Amazon Elastic Compute Cloud (Amazon EC2)
                  instances, both with and without local storage options, Amazon
                  Elastic Block Store (Amazon EBS) volume options, and Amazon
                  Simple Storage Service (Amazon S3) on Outposts.
                </p>

                <p className="second_customer">
                  Customers can then use a variety of AWS services on a local
                  level, including Amazon Elastic Container Service (Amazon
                  ECS), Amazon Elastic Kubernetes Service (Amazon EKS), Amazon
                  Relational Database Service (Amazon RDS), and EMR.
                  Additionally, they can use public or private connections to
                  directly access regional services like Amazon CloudWatch and
                  Amazon DynamoDB.
                </p>
              </div>
            </div>

            <div className="bottom_content">
              <div className="upper_section">
                <div className="left_upper_section">
                  <div className="upper_bottom_content">
                    <div className="upper_left_main_content">
                      <p className="first_text_bottom">
                        “AWS" reliability has enabled us to seamlessly scale our
                        operations, and exponentially grow transaction volumes.
                        We are excited by the launch of AWS Outposts rack in
                        Nigeria which brings cloud-scale innovations and
                        services to Nigeria's tech ecosystem,” .Ezra Olubi,
                        Paystack Co-founder and CTO
                      </p>
                      <p className="second_text_bottom">
                        According to Francis Osifo, vice president of 54Gene,
                        “Using AWS, we have been able to deploy new digital
                        services faster than it would have taken us previously.
                        Continuous innovation is part of 54Gene’s DNA. The
                        launch of the AWS Outposts rack in Nigeria allows us to
                        extend and run AWS services on-premises. We are thrilled
                        with this.”
                      </p>
                    </div>
                  </div>
                </div>

                <div className="right_upper_section">
                  {/* <img
                    src="https://res.cloudinary.com/femakin/image/upload/v1667433830/image_14_idbr1p.png"
                    alt="amazonpageimg"
                    className="circle_upper_img"
                  /> */}
                  <img
                    className="circle_upper_img"
                    alt="amazonpageimg"
                    src={`${img_link}/aws-services.png`}
                  />
                </div>
              </div>
            </div>

            <div className="last_part">
              <p className="last_text">
                With more than 200 fully featured services for computing,
                storage, databases, networking, analytics, machine learning, and
                artificial intelligence, AWS has been steadily growing its
                offerings over the past 15 years to accommodate practically
                every cloud task (AI). Millions of customers rely on AWS to
                power their infrastructure, increase productivity, and save
                costs. The largest enterprises, the quickest-growing startups,
                and the most prestigious government agencies are some of these
                clientele.
              </p>
            </div>

            <span className="last_link">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://technext.ng/2022/07/22/amazon-aws-launches-outposts-in-nigeria/"
              >
                Source: Technext.ng
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
