import React, {useEffect} from 'react'
import './Leadership.css'
import Topmenu from '../../components/Topmenu/Topmenu'
import Footer from '../../components/Footer/Footer'
import LeaderTeam from '../../components/LeaderTeam/LeaderTeam'
import ReadStoryHero from '../../components/ReadStoryHero/ReadStoryHero'

function Leadership() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div>
      <Topmenu/>
      <LeaderTeam/>
      <ReadStoryHero/>
      <Footer/>
    </div>
  )
}

export default Leadership