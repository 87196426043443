import React from "react";
import CustomBtn from "../Button/CustomButton";
import "./style.css";
import { Grid } from "@mui/material";
import { openPositions } from "./openPositions";
import { useNavigate } from "react-router-dom";

function OpenPositions() {

  const navigate = useNavigate()

  return (
    <div className="mainhome desktopPadding" style={{ background: 'whitesmoke' }}>

      <h1 className="openpositionshome">Open positions</h1>
      <Grid container spacing={4}>
        {
          openPositions.map((pos, index) => (
            // <Grid key={index} item xs={12} md={6} lg={4} xl={3}>
            <Grid key={index} item xs={12} md={6} lg={4} xl={3}>

              <div className="jobCard">
                <h2>{pos.title}</h2>
                <p>{pos.description}</p>
                {/* <div style={{ marginTop: '10px', marginBottom: '40px' }}>{pos.location} | {pos.mode}</div> */}
                <div style={{ display: "flex", width: '100%', justifyContent: 'center' }}>
                  <CustomBtn type="button" txtColor="white" pd="3px" br="8px" fs="14px" w="100px" bg="var(--main)" hoverBG="var(--main)"
                    txt="Apply"
                    fw="500" border="1px solid var(--main)"
                    onClick={() => navigate(`/career?position=${pos.slug}`)}
                  />
                </div>
              </div>
            </Grid>
          ))
        }

      </Grid>
    </div>
  );
}

export default OpenPositions;
