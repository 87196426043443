import React from "react";
import "./AwsStartup.css";
import powerful_automation from "../../assets/images/powerful_automation.svg";
import Hexagon from "react-hexagon";

function AwsStartup2({heading_one, sub_heading, icon, main_title, sub_title,     heading_one2, sub_heading2, icon4, icon5, icon6, main_title2, sub_title2, icon3, main_title3, main_title4, main_title5, main_title6, sub_title3}) {
  return (
    <div className="aws_main_container">
      <div className="aws_container">
        <div className="aws_main_title">
          <h1 className="aws_title">
           {heading_one}
          </h1>
        </div>

        <div className="aws_sub_main_title">
          <h2 className="aws_sub_title">
           {sub_heading}
          </h2>
        </div>

        <div className="aws_main_features">
        
          <div className="aws_main_features_contaier">
            <div className="aws_main_features_one">
              <div className="main_features_icon1">
                <img src={icon4} alt="" />
              </div>
              <div className="main_features_sub_text">
                <p className="sub_four">{main_title4} </p>{" "}
              </div>
              <div className="main_features_sub_text_third">
                <p className="main_third">
                 {sub_title}
                </p>{" "}
              </div>
            </div>

            <div className="aws_main_features_one">
              <div className="main_features_icon1">
                <img src={icon5} alt="" />
              </div>
              <div className="main_features_sub_text">
                <p className="sub_five">{main_title5} </p>{" "}
              </div>
              <div className="main_features_sub_text_third">
                <p className="main_third">
                 {sub_title2}
                </p>{" "}
              </div>
            </div>

            <div className="aws_main_features_one">
              <div className="main_features_icon1">
                <img src={icon6} alt="" />
              </div>
              <div className="main_features_sub_text">
                <p className="sub_six">{main_title6} </p>{" "}
              </div>
              <div className="main_features_sub_text_third">
                <p className="main_third">
                 {sub_title3}
                </p>{" "}
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default AwsStartup2;
