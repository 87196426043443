import React, { useEffect } from 'react'
import Footer from '../../components/Footer/Footer'
import Topmenu from '../../components/Topmenu/Topmenu'
import Overhead from '../../components/OverLayHeader/Overhead'
import voda from '../../assets/images/vodafone.svg'
import dil from '../../assets/images/Dillivry.svg'
import "./LambdaAws.css"
import { useNavigate } from 'react-router-dom';
import { HiOutlineChevronDoubleRight } from 'react-icons/hi';
import Ready from '../../components/Ready/Ready'

const DynamoBDAws = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const img_link = process.env.REACT_APP_IMG_LINK;
  const navigate = useNavigate();

  return (
    <div>
      <Topmenu />
      <div className='lambda_main'>
        <Overhead HeaderBG={`url("${img_link}/Lambda_img1.svg")`}
          title="Amazon DynamoDB Specialization"
          subsub="Fexible, fast and NoSQL database"
          subtitle="Fully managed, serverless, key-value NoSQL database designed to run high-performance applications at any scale. DynamoDB offers built-in security, continuous backups, automated multi-Region replication, in-memory caching, and data import and export tools."
          subtitle1 = "Datamellon have deep expertise and a proven track record of delivering scalable, resilient and cost-effective solutions with DynamoDB at scale."
        />
        <div className="class_cases">
          <div className='home_max_width'>
            <div className="containPadding">
              <div className='titler'>Benefits</div>
              <div className="flexer" style={{marginBottom:'40px'}}>
                <div className="icon_text_sub">
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <div className="content__main">
                    <div className="cstudytopic2">
                      Performance at scale
                    </div>
                    <div className="cstudynormtxt2">
                      DynamoDB can support tables of virtually any size with horizontal scaling. This enables DynamoDB to scale to more than 10 trillion requests per day with peaks greater than 20 million requests per second, over petabytes of storage.
                    </div>
                  </div>
                </div>
                <div className="icon_text_sub">
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <div className="content__main">
                    <div className="cstudytopic2">
                      Serverless
                    </div>
                    <div className="cstudynormtxt2">
                    There are no servers to provision, patch, or manage and no software to install, maintain or operate. DynamoDB automatically scales tables to adjust for capacity and maintains performance with zero administration. Availability and fault tolerance are built in, eliminating the need to architect your applications for these capabilities.
                      </div>
                  </div>
                </div>
              </div>
              <div className="flexer">
                <div className="icon_text_sub">
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <div className="content__main">
                    <div className="cstudytopic2">
                      Enterprise ready
                    </div>
                    <div className="cstudynormtxt2">
                      Built for mission-critical workloads, including support for atomicity, consistency, isolation, and durability (ACID) transactions for a broad set of applications that require complex business logic. DynamoDB secures data with encryption and continuously backs up your data for protection, with guaranteed reliability through a service level agreement.
                     </div>
                  </div>
                </div>
                <div className="icon_text_sub">
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <div className="content__main">
                    <div className="cstudytopic2">
                      Integration with other AWS services
                    </div>
                    <div className="cstudynormtxt2">
                      Amazon DynamoDB is integrated with other AWS services which enable us to automate repeating tasks or build applications that span multiple services. 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='home_max_width'>
          <div className='Usecases' style={{paddingTop:'50px'}}>
            <div className='title'>
              Customers
            </div>
            <div className='content_usecases' style={{marginBottom:'0px'}}>
              <div className='content'>
                <div className='subtitle' >
                  <img src={voda} alt="" />
                  <div className='imgsub_title'>Group</div>
                </div>
                <div className='normtextx' onClick={() => navigate("/vodafone-case-study")} target="_blank" style={{ cursor: 'pointer', }} >
                  Vodafone delivers Digital FrontDoor apps using AWS Lambda and Amazon DynamoDB <HiOutlineChevronDoubleRight fontSize={10} />
                </div>
              </div>
              <div className='content'>
                <div className='subtitle' >
                  <img src={dil} alt="" />
                  <div style={{ padding: "10px" }}></div>
                </div>
                <div className='normtextx'>
                  Dillivry built an event driven online logistics marketplace with AWS Lambda and Amazon DynamoDB <HiOutlineChevronDoubleRight fontSize={10} />
                </div>
              </div>
              <div className='content'>
                <div className='subtitle'>
                  <img src={voda} alt="" />
                  <div className='imgsub_title'>UK</div>
                </div>
                <div className='normtextx'>
                Vodafone UK serves static assets using CloudFront and AWS Lambda@Edge to process all requests to its website <HiOutlineChevronDoubleRight fontSize={10} />
                </div>
              </div>
              <div className='content'>
                <div className='subtitle' >
                  <img src={voda} alt="" />
                  <div className='imgsub_title' >Cloud Analytics</div>
                </div>
                <div className='normtextx'>
                  Vodafone Group Cloud Analytics automates and delivers a unified machine learning platform with AWS Lambda and Amazon DynamoDB <HiOutlineChevronDoubleRight fontSize={10} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Ready bg="white" />
      </div>
      <Footer />
    </div>
  );
}
export default DynamoBDAws