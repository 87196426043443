import React from 'react';
import './HeroSection.css';
import ImageSwitcher from './../ImageSwitcher/ImageSwitcher';
import ImageSwitchV2 from '../ImageSwitcher/ImageSwitchV2';
import MailCustomBtn from '../Button/MailCustomButton';

function HeroSection() {
  return (
    <div className="herocontainer" >
      <div className="home_max_width">
      <div className='herosectionbody' >
        <div className="left-hero-section">
            <h2 className="lhero-section-text-bold">Simple, Fast and Secure Cloud Journey</h2>

            <div className="lefthero-section-text-norm">
              <h3>When it comes to Cloud, we're a must-have. We harness the tremendous power of Cloud to help you activate what you need, when you need it.</h3>
              <h3> Whether your business is early in its journey or well on its way to digital transformation, we help chart a path to success.</h3>
            </div>
            <div className='getintouch-btn-div' >
              <MailCustomBtn
                type='button'
                txtColor='white'
                pd='10px'
                br='8px'
                fs='18px'
                w='200px'
                bg='var(--main)'
                hoverBG='var(--main)'
                txt='Get in touch'
                fw='500'
                border="1px solid var(--main)"
                onClick={() => { }} />
            </div>
        </div>
        <div className="right-hero-section">
          <ImageSwitchV2/>
        </div>

      </div>
      </div>
    </div>
  )
}

export default HeroSection