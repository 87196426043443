import React, {useEffect} from 'react'
import Topmenu from '../../components/Topmenu/Topmenu'
import Footer from '../../components/Footer/Footer'
import OverLayHeader from '../../components/OverLayHeader/OverLayHeader'
import AllServices from '../../components/SCard/AllServices'

function Servicepage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const img_link = process.env.REACT_APP_IMG_LINK
  return (
    <div>
      <Topmenu/>
      <OverLayHeader
        HeaderBG={`url("${img_link}/Servicebackground_image.svg")`}
        title="Our Services"
        subtitle="Choosing the right cloud solution and adoption is a complex undertaking. Our priority is to support you to envision and execute a holistic, value-driven, end-to-end business digital transformation with tangible values."
      />
      <AllServices/>
      <Footer/>
    </div>
  )
}

export default Servicepage