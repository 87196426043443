import React from 'react'
import Partnercmp2 from './Partnercmp2';

import './Partnercmp.css'

function Partnercmp() {

    const img_link = process.env.REACT_APP_IMG_LINK

    const Partnersinfo = [
        {
            text: "Experience the broad range of AWS services to power your business innovation with agility and lower costs ",

            textund: "",

            texthead: "Amazon Web Services",

            webicon: `${img_link}/aws_icon.svg`,

            mobicon: `${img_link}/awsicon_mobile.svg`,

            url: "/aws",
        },

        {
            text: "Explore how google cloud solutions can help improve efficiency and agility, reduce cost, participate in new business models, and capture new market opportunities.",
            
            textund: "",

            texthead: "Google cloud",

            webicon: `${img_link}/Google_cloud_webview.svg`,

            mobicon: `${img_link}/Google_cloud_mobile.svg`,

            url:"https://cloud.google.com/",
        },

        {
            text: "Bring new solutions to life with Azure to solve today's challenges and create the future for your business.",
            // textund: "With AWS applications such as CodePipeline, CodeBuild, CodeDeploy, and CodeStar (in addition to open-source tooling like Jenkins, HashiCorp, Chef, Puppet, and Ansible), we can create and configure CI/CD pipelines; automate the configuration and deployment of software delivery; and monitor application and infrastructure performance",
            textund: "",

            texthead: "Azure",

            webicon: `${img_link}/Azure_Partner_web_view.svg`,

            mobicon: `${img_link}/Azure_Partner_mobile.svg`,

            url:"https://azure.microsoft.com/",
        },

        {
            text: "Rethink your engineering and systems design with Oracle cloud innovations that accelerate migrations, deliver better reliability and performance for all applications.",

            // textund: "With AWS applications such as CodePipeline, CodeBuild, CodeDeploy, and CodeStar (in addition to open-source tooling like Jenkins, HashiCorp, Chef, Puppet, and Ansible), we can create and configure CI/CD pipelines; automate the configuration and deployment of software delivery; and monitor application and infrastructure performance",
            textund: "",

            texthead: "Oracle",

            webicon: `${img_link}/Oracle_webview.svg`,

            mobicon: `${img_link}/oracle_mobileview.svg`,

            url:"https://www.oracle.com/",
        },

    ]

    return (
        <div className="partnrcontainer">
            <div className="partner-01">
                <div className="pner-contain">
                    {
                        Partnersinfo.map((x, i) => {
                            return (
                                <Partnercmp2 partheader={x.texthead}
                                    key={i}
                                    text_a={x.text}
                                    text_b={x.textund}
                                    webimage={x.webicon}
                                    mobimage={x.mobicon}
                                    url={x.url}
                                />

                            )
                        })
                    }

                </div>
            </div>

        </div>
    )

}

export default Partnercmp