import React, {useEffect} from 'react'
import AllCases from '../../components/CaseStudyComp/AllCases'
import Footer from '../../components/Footer/Footer'
import Topmenu from '../../components/Topmenu/Topmenu'
import AllWar from '../../components/WAR/AllWar'
import './CaseStudy.css'

const CaseStudy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Topmenu/>
      <AllCases />
      {/* <AllWar/> */}
      <Footer/>
    </div>
  )
}

export default CaseStudy
