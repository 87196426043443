import React, { useEffect } from 'react'
import Footer from '../../components/Footer/Footer'
import Topmenu from '../../components/Topmenu/Topmenu'
import Overhead from '../../components/OverLayHeader/Overhead'
import voda from '../../assets/images/vodafone.svg'
import dil from '../../assets/images/Dillivry.svg'
import "./LambdaAws.css"
import { useNavigate } from 'react-router-dom';
import { HiOutlineChevronDoubleRight } from 'react-icons/hi';
import Ready from '../../components/Ready/Ready'

const LambdaAws = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const img_link = process.env.REACT_APP_IMG_LINK;
  const navigate = useNavigate();
 
  return (
    <div>
      <Topmenu />
      <div className='lambda_main'>
        <Overhead HeaderBG={`url("${img_link}/Lambda_img1.svg")`}
          title="AWS Lambda Specialization"
          subsub="Run code without thinking about servers or clusters"
          subtitle="Serverless, event-driven compute service that lets you run code for virtually any type of application or backend service without provisioning or managing servers and only pay for what you use.
          Our AWS Lambda Solution Services help you build AWS Serverless solutions, drive innovation and accelerate cloud journey."
        />
        <div className='home_max_width'>
          <div className='boxes'>
            <div className='contentsBlueBox'>
              <div className='normtext'>
                Run code without provisioning or managing infrastructure. Simply write and upload code as a .zip file or container image.
              </div>
            </div>
            <div className='contentsBlueBox'>
              <div className='normtext'>
                Automatically respond to code execution requests at any scale, from a dozen events per day to hundreds of thousands per second.
              </div>
            </div>
            <div className='contentsBlueBox'>
              <div className='normtext'>
                Save costs by paying only for the compute time you use—by per-millisecond—instead of provisioning infrastructure upfront for peak capacity.
              </div>
            </div>
            <div className='contentsBlueBox'>
              <div className='normtext'>
                Optimize code execution time and performance with the right function memory size. Respond to high demand in double-digit milliseconds with Provisioned Concurrency.
              </div>
            </div>
          </div>
          <div className='Usecases'>
            <div className='title'>
              Use cases
            </div>
            <div className='content_usecases'>
              <div className='content'>
                <div className='subtitlex'>
                  Process data at scale
                </div>
                <div className='normtext'>
                  Execute code at the capacity you need, as you need it. Scale to match your data volume automatically and enable custom event triggers.
                </div>
              </div>
              <div className='content'>
                <div className='subtitlex'>
                  Run interactive web and mobile backends
                </div>
                <div className='normtext'>
                  Combine AWS Lambda with other AWS services to create secure, stable, and scalable online experiences.
                </div>
              </div>
              <div className='content'>
                <div className='subtitlex'>
                  Enable powerful ML insights
                </div>
                <div className='normtext'>
                  Preprocess data before feeding it to your machine learning (ML) model. With Amazon Elastic File System (EFS) access, AWS Lambda handles infrastructure management and provisioning to simplify scaling.
                </div>
              </div>
              <div className='content'>
                <div className='subtitlex'>
                  Create event-driven applications
                </div>
                <div className='normtext'>
                  Build event-driven functions for easy communication between decoupled services. Reduce costs by running applications during times of peak demand without crashing or over-provisioning resources.
                </div>
              </div>
            </div>
          </div>
          <div className='Usecases'>
            <div className='title'>
              Customers
            </div>
            <div className='content_usecases'>
              <div className='content'>
                <div className='subtitle' >
                  <img src={voda} alt="" />
                  <div className='imgsub_title'>Group</div>
                </div>
                <div className='normtextx' onClick={() => navigate("/vodafone-case-study")} target="_blank" style={{ cursor: 'pointer', }} >
                  Vodafone delivers Digital FrontDoor apps using AWS Lambda and Amazon DynamoDB <HiOutlineChevronDoubleRight fontSize={10} />
                </div>
              </div>
              <div className='content'>
                <div className='subtitle' >
                  <img src={dil} alt="" />
                  <div style={{ padding: "10px" }}></div>
                </div>
                <div className='normtextx'>
                  Dillivry built an event driven online logistics marketplace with AWS Lambda and Amazon DynamoDB <HiOutlineChevronDoubleRight fontSize={10} />
                </div>
              </div>
              <div className='content'>
                <div className='subtitle'>
                  <img src={voda} alt="" />
                  <div className='imgsub_title'>UK</div>
                </div>
                <div className='normtextx'>
                Vodafone UK serves static assets using CloudFront and AWS Lambda@Edge to process all requests to its website <HiOutlineChevronDoubleRight fontSize={10} />
                </div>
              </div>
              <div className='content'>
                <div className='subtitle' >
                  <img src={voda} alt="" />
                  <div className='imgsub_title' >Cloud Analytics</div>
                </div>
                <div className='normtextx'>
                  Vodafone Group Cloud Analytics automates and delivers a unified machine learning platform with AWS Lambda and Amazon DynamoDB <HiOutlineChevronDoubleRight fontSize={10} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Ready />
      </div>
      <Footer />
    </div>
  );
}
export default LambdaAws