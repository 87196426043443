import React from 'react'
import './SCard.css'
import { useNavigate } from 'react-router-dom'

const SCard = ({flexDirection, title, subtitle, link,img}) => {
const navvigate = useNavigate()
  const Card = () =>(
      <div className='card_mini'>
        <h3>{title}</h3>
        <p>{subtitle}</p>
        <span onClick={()=>navvigate(`${link}`)}>Learn more</span>
      </div>
    )
    
  return (
      <div className='service_c' style={{flexDirection: flexDirection}}>
        <div className='serve_card'>
          <Card/>
        </div>
        <div className='serv_img'>
          <img className='img_card' src={img}/>
        </div>
      </div>
  )
}

export default SCard


