import React from 'react'
import './ProductCard.css'
import {BsArrowRight} from 'react-icons/bs'

const ProductCard = ({reverse, title, subtitle,subtitle1,subtitle2, link,img}) => {

  const Card = () =>(
      <div className='prod_mini'>
        <p><span>{title + " "}</span>{subtitle}</p>
        <p>{subtitle1}</p>
        <p>{subtitle2}</p>
        <a href={link}>Learn more <BsArrowRight size={20}/></a>
      </div>
    )
    
  return (
    
      <div className={reverse ? 'product_c_reverse' : "product_c"}>
        <div className='prod_card'>
          <Card/>
        </div>
        <div className='prod_img'>
          <img className='img_cardd' src={img}/>
        </div>
      </div>
  )
}

export default ProductCard


