import React, { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";
import Topmenu from "../../../components/Topmenu/Topmenu";
import zencey from "../../../assets/images/Zencey-Logo-1.png";
import "./Cstudy1.css";

export default function Zencey() {
  const img_link = process.env.REACT_APP_IMG_LINK;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Topmenu />
      <div className="cstudy1">
        <div className="home_max_width">
          <div className="casestudcontainer">
            <div className="csheadbg"></div>
            <div className="cstudbody">
              <div className="cstudyclient">
                <div>
                  <div className="cstudytopic">Case Study</div>
                  <div className="cstudynormtxt">
                    Datamellon carried out an architecture review of Zencey’s
                    existing infrastructure and recommended and executed a
                    well-architected DevOps deployment with a multi-AWS account
                    strategy.
                  </div>
                  {/* <div className="cstudysmalltxt">
                    - Oj Adekoya | Principal Cloud Architect GDC Public Cloud
                    Services
                  </div> */}
                </div>
                <div className="vodafone">
                  <img src={zencey} alt="Zencey Logo" />
                </div>
              </div>
              {/* <div>
                  <div className="cstudytopic">The Client</div>
                  <div className="cstudynormtxt">
                    Datamellon are a trusted partner on <b>Vodafone's</b> cloud
                    adoption journey.
                    <p>
                      The skills from their team have helped accelerate the use
                      of AWS within our global organisation.
                    </p>
                  </div>
                  <div className="cstudysmalltxt">
                    - Oj Adekoya | Principal Cloud Architect GDC Public Cloud
                    Services
                  </div>
                </div> */}
              <div className="cstudychallenge">
                <div className="cstudytopic">The Challenge</div>
                <div className="cstudynormtxt">
                  <b>Zencey:</b> One App, All Things Health. <br />
                  An on-demand, 24/7 healthcare platform that makes patients’
                  access to medication easy and affordable. Zencey's AI-powered
                  digital assistant asks simple, easy-to-understand questions,
                  just like a doctor, to identify symptoms and personal risk
                  factors for patients. Zencey technology analyzes the answers
                  and then uses the knowledge from millions of medical
                  publications to identify possible causes. <br /> <br />
                  Patients get relevant medical information, personalized health
                  solutions, and help navigating to the right point of
                  treatment. Patients can place an order online from an
                  e-prescription, upload a paper prescription, or simply order
                  OTC medication and pick up the medicine at the nearest
                  pharmacy or get it delivered to your doorstep. <br /> <br />
                  Being a modern-day healthcare app, the Zencey healthtech app
                  requires a scalable, reliable, cost efficient, and
                  well-architected deployment to connect its vast network of
                  doctors, pharmacies, health sensors and devices, and patients.
                </div>
              </div>

              <div className="cstudysolution">
                <p>
                  <div className="cstudytopic">The Solution</div>
                  <div className="cstudynormtxt">
                    Datamellon carried out an architecture review of Zencey’s
                    existing infrastructure, recommended, and executed a
                    well-architected DevOps deployment with a multi-AWS account
                    strategy. We worked with the Zencey team to design and build
                    a multi-account environment with the AWS Control Tower. The
                    solution is secure, scalable, and cost-efficient. multi-tier
                    architecture within an AWS organization The suites of AWS
                    managed services used for the implementation are as below:
                  </div>
                </p>
                <div className="icon_text_sub_cont">
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">AWS Control Tower</div>
                      <div className="cstudynormtxt2">
                        The AWS Control Tower simplifies AWS experiences by
                        orchestrating multiple AWS services on customers' behalf
                        while maintaining the security and compliance needs of
                        your organization.
                      </div>
                    </div>
                  </div>

                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Compute</div>
                      <div className="cstudynormtxt2">
                        Amazon Elastic Compute Cloud (Amazon EC2) provides
                        scalable computing capacity in the Amazon Web Services
                        (AWS) cloud. EC2 instances were provisioned into an
                        auto-scaling group with scale-up and scale-down policies
                        according to traffic and compute utilization. Thereby,
                        elasticity is guaranteed, and the customer only pays for
                        what it uses. Traffic to the instances in the Auto
                        Scaling group is routed via an application load
                        balancer. Application Load Balancer: load balance HTTP
                        and HTTPS traffic with advanced request routing targeted
                        at the delivery of modern applications.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Database</div>
                      <div className="cstudynormtxt2">
                        Amazon RDS is used for the backend database and has 3-5X
                        faster latency than popular alternatives, which have
                        latency ranging from microseconds to milliseconds.
                        Amazon RDS is a managed relational database service for
                        MySQL on the AWS Cloud. AWS continuously monitors your
                        database clusters to keep your workloads running with
                        self-healing storage and automated scaling, so that you
                        can focus on application development. Amazon RDS is
                        secure, highly available, and built for
                        business-critical enterprise workloads, offering high
                        availability, reliability, and security.
                      </div>
                    </div>
                  </div>

                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Deployment</div>
                      <div className="cstudynormtxt2">
                        The solution was deployed with the AWS native continuous
                        integration and continuous deployment (CI/CD) toolchains
                        of AWS CodeCommit, CodePipeline, and CodeBuild. Amazon
                        Simple Storage Service (Amazon S3) stores deployment
                        artifacts. Amazon Simple Storage Service (Amazon S3) is
                        an object storage service that offers industry-leading
                        scalability, data availability, security, and
                        performance.The solution was deployed with the AWS
                        native continuous integration and continuous deployment
                        (CI/CD) toolchains of AWS CodeCommit, CodePipeline, and
                        CodeBuild. Amazon Simple Storage Service (Amazon S3)
                        stores deployment artifacts. Amazon Simple Storage
                        Service (Amazon S3) is an object storage service that
                        offers industry-leading scalability, data availability,
                        security, and performance.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Security</div>
                      <div className="cstudynormtxt2">
                        All data is encrypted at rest and in transit. Deployment
                        artifacts are encrypted with strong, self-signed
                        cryptographic customer keys managed by AWS KMS. The load
                        balancer endpoint is protected with AWS WAF for
                        protection against common attacks such as SQL injection,
                        cross-site scripting, etc. DDoS attacks are mitigated by
                        AWS Shield.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cstudybenefits">
                <div className="cstudytopic">The Benefits</div>
                <div className="cstudynormtxt">
                  The solution enables Zencey infrastructure to automatically
                  scale according to business transaction traffic and compute
                  utilization. New features and updates can now be deployed to
                  the respective environments through automated CI/CD pipelines.
                  A well-architected deployment is a reliable, secure,
                  performant, and cost-optimized solution for Zencey growth.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}