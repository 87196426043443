import React, { useEffect } from "react";
import CaseStudyComp from "../../components/CaseStudyComp/CaseStudyComp";
import Compe_card from "../../components/Comp_card/Compe_card";
import DiscoverCard from "../../components/DiscoverCard/DiscoverCard";
import Footer from "../../components/Footer/Footer";
import OverLayHeader3 from "../../components/OverLayHeader/OverLayHeader3";
import Ready from "../../components/Ready/Ready";
import Topmenu from "../../components/Topmenu/Topmenu";
import "./Aws.css";
import AwsCards from "./AwsCards";
import aws from '../../assets/images/aws2.jpeg'

export default function Aws() {
  const img_link = process.env.REACT_APP_IMG_LINK;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const data = [
    {
      icon: `${img_link}/Aws_exp_img1.svg`,
      text: "Cloud Adoption & Foundation",
    },
    {
      icon: `${img_link}/Aws_exp_img2.svg`,
      text: "Cloud Migration & Managed Operations",
    },
    {
      icon: `${img_link}/Aws_exp_img3.svg`,
      text: "Data Analytics",
    },
    {
      icon: `${img_link}/Aws_exp_img4.svg`,
      text: "Cloud-Native Application Development",
    },
    {
      icon: `${img_link}/Aws_exp_img5.svg`,
      text: "Security, Compliance & DevSevOps",
    },
    {
      icon: `${img_link}/Aws_exp_img6.svg`,
      text: "Cost Management & Optimization",
    },
    {
      icon: `${img_link}/Aws_exp_img7.svg`,
      text: "IoT",
    },
    {
      icon: `${img_link}/Aws_exp_img8.svg`,
      text: "Machine Learning & Artificicial Intelligence",
    },
  ];
  const data1 = [
    {
      title: "AWS Digital FrontDoor",
      subtitle: "Vodafone Group",
      image: `${img_link}/success_story1.svg`,
      linkup: "/vodafone-case-study ",
    },
    {
      title: "Logistics Marketplace",
      subtitle: "Dillivry",
      image: `${img_link}/success_story2.svg`,
    },
    {
      title: "Google Cloud Digital FrontDoor",
      subtitle: "Vodafone Group",
      image: `${img_link}/success_story3.svg`,
    },
    {
      title: "Azure Digital FrontDoor",
      subtitle: "Vodafone Group",
      image: `${img_link}/success_story4.svg`,
    },
    {
      title: "Serving Static Assets with CloudFront",
      subtitle: "Vodafone UK",
      image: `${img_link}/success_story5.svg`,
    },
    {
      title: "SuccessFactor",
      subtitle: "Vodafone Ireland",
      image: `${img_link}/success_story6.svg`,
    },
    {
      title: "DevOps",
      subtitle: "NewWaves Ecosystem",
      image: `${img_link}/success_story7.svg`,
    },
    {
      title: "AI Booster",
      subtitle: "Vodafone Group Cloud Analytics",
      image: `${img_link}/success_story8.svg`,
    },
  ];

  const data2 = [
    {
      title: "VCloudSmart",

      subtitle: "Vodafone Group",

      image: `${img_link}/success_story9.svg`,
    },
    {
      title: "OCI Digital FrontDoor",

      subtitle: "Vodafone Group",

      image: `${img_link}/success_story10.svg`,
    },
    {
      title: "Oracle DRCC DigitalFront Door",

      subtitle: "Vodafone Group",

      image: `${img_link}/success_story11.svg`,
    },
  ];

  return (
    <div>
      <Topmenu />
      <main className="aws_main">
        <OverLayHeader3
          HeaderBG={`url("${img_link}/consulting_patbg1.svg")`}
          title={<span className="aws_heead">AWS ADVANCED PARTNER </span>}
          subtitle="As an AWS APN Advanced Partner we use the tremendous power of AWS Cloud to activate what our customers need when they need it."
        />
        <div className="aws_con">
          <div className="tex_shift_img">
            <p className="aws_text">
              Whether you are looking to leverage AWS for innovation, agility,
              cost savings, operation efficiency, analytics, machine learning &
              artificial intelligence or all of the above, as an AWS Partner
              Network (APN) Advanced Partner, our expertise with Amazon Web
              Services means we've been there and done it when it comes to AWS
              Cloud. We know you want to do it right and we'll guide and show
              you how to get there.{" "}
            </p>
            <div className="shift_img">
              {/* <img src={`${img_link}/Consulting_img.svg`} alt="" /> */}
              <img src={aws} alt="serve-icon" className="rcomp__image" />
            </div>
          </div>
        </div>
        <section className="second_aws_section">
          <div className="aws_con">
            <h2>Discover the Datamellon AWS Expert Services</h2>
            <div className="discoverCon">
              {data.map((x, i) => {
                return <DiscoverCard key={i} text={x.text} icon={x.icon} />;
              })}
            </div>
          </div>
        </section>
        <section className="compe_sec">
          <div className="aws_con">
            <Compe_card />
          </div>
        </section>

        <section className="third_aws_section">
          <div className="aws_con">
            <h2>Success Stories and Case Studies</h2>
            <div className="awscards1">
              {data1.map((x, i) => {
                return (
                  <AwsCards
                    key={i}
                    title={x.title}
                    subtitle={x.subtitle}
                    image={x.image}
                    linkup={x.linkup}
                  />
                );
              })}
            </div>

            <div className="awscards2">
              {data2.map((x, i) => {
                return (
                  <AwsCards
                    key={i}
                    title={x.title}
                    subtitle={x.subtitle}
                    image={x.image}
                  />
                );
              })}
            </div>
          </div>

          {/* <div className='awscards3'>
                        <AwsCards
                          title = "AWS Digital FrontDoor"
                          subtitle = "Vodafone Group"
                          image = { `${img_link}/aw_services1.svg`}
                        
                        />
                </div> */}
        </section>
        <Ready />
      </main>
      <Footer />
    </div>
  );
}
