import React from "react";
import "./Compe_card.css";
import Compecard_2 from "../Comp_card/Compecard_2";
import { useNavigate } from "react-router-dom";

function Compe_card({ titled }) {
  const img_link = process.env.REACT_APP_IMG_LINK;
  const navvigate = useNavigate();

  const Compe_Cardinfo = [
    {
      text: "Run code without thinking about servers or clusters.",
      texthead: "AWS Lambda",
      color: "#474545",
      link: "/aws-lambda",
    },

    {
      text: "Fexible, fast and NoSQL database",
      texthead: "Amazon DynamoDB",
      color: "#474545",
      link: "/amazon-dynamodb",
    },

    {
      text: "The trusted, secure and reliable cloud for Windows",
      texthead: "Amazon EC2 for Microsoft Windows Specialization",
      color: "#474545",
      link: "/aws-service-delivery-amazon-ec2-for-microsoft-windows",
    },

    {
      text: "Tailored, Real-time Web Application Defense",
      texthead: "Amazon WAF( Web Application Firewall) Specialization",
      color: "#474545",
      link: "/aws-waf",
    },
    {
      text: "Datamellon's Tailored AWS Control Tower Solutions",
      texthead: "Control Tower Specialization",
      color: "#474545",
      link: "/controltower",
    },
    {
      text: "Datamellon's API Gateway Solutions",
      texthead: "API Gateway Specialization",
      color: "#474545E",
      link: "/aws-api-gateway",
    },
    {
      text: "Datamellon's CloudFormation Solutions",
      texthead: "CloudFormation Specialization",
      color: "#474545E",
      link: "/aws-cloudformation",
    },
    {
      text: "Datamellon's Service Catalog Solutions",
      texthead: "Service Catalog Specialization",
      color: "#474545E",
      link: "/service-catalog",
    },
    {
      text: "Coming soon",
      texthead: "DevOps",
      color: "#3498FE",
      link: "",
    },
    {
      text: "Coming soon",
      texthead: "AWS Well-Architected Framework",
      color: "#3498FE",
      link: "",
    },
    {
      text: "Coming soon",
      texthead: "CloudFront",
      color: "#3498FE",
      link: "",
    },
    {
      text: "Coming soon",
      texthead: "AWS Glue",
      color: "#3498FE",
      link: "",
    },
  ];
  return (
    <div className="Compe_cardhome">
      <div className="compe_contain">
        <div className="compehome_max_width">
          {
            <div className="competxt-major">
              <h2
                className="com_ctrl-txt"
                onClick={() => navvigate(`${"/competences"}`)}
              >
                {" "}
                AWS{" "}
                <span>
                  {" "}
                  <img
                    className="comp"
                    src={`${img_link}/Competence_icon.svg`}
                  />{" "}
                </span>
                Competences
              </h2>
            </div>
          }

          <div className="com_cardcapability-contain">
            {Compe_Cardinfo.map((x, i) => {
              return (
                <Compecard_2
                  content_header={x.texthead}
                  key={i}
                  content={x.text}
                  url={x.link}
                  color={x.color}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Compe_card;
