import React from 'react'
import MailCustomBtn from '../Button/MailCustomButton'
import './Ready.css'

export default function Ready({bg}) {
  return (
    <div className='ready' style={{backgroundColor: bg? bg : "#F0F8FF" }}>
            <h1>Ready to get started?</h1>
            <MailCustomBtn
                    type='button'
                    txtColor='white'
                    pd='4px'
                    br='7px'
                    fs='14px'
                    w='120px'
                    bg='var(--main)'
                    hoverBG='var(--main)'
                    txt="Let's Talk"
                    fw='600'
                    border="0px solid var(--main)"
                    onClick={() => {}} />

          </div>
  )
}
