import React, {useState} from 'react'
import './Carouselslide.css'
// import Carousel  from "react-elastic-carousel";
// import userAvatar from "../../../assets/userAvatar.jpeg"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Pagination, Autoplay } from "swiper";
import "swiper/css/pagination";

export default function Carouselslide() {

    const [items, setItems] = useState([
        { 
          id:"1",  
          name: 'Daniela Ziemer',
          comp_name:"1st solution consulting Ltd",
          text:"Datamellon is delivering outstanding services to the client and we're very happy with them. They have a widespread knowledge of AWS and can support and offer solutions to occuring problems easily. We have not had one hiccup with their services, hence we can only recomment their services as they are doing an amazing job.", 
        },

        { 
            id:2,
            name: 'Gerard Conway ',
          comp_name:"West Myra Digital Ltd",
          text:"Great technical team Able to support complex solutions in challenging corporate environments Worked very well with the existing customer team to deliver in the required time frames Hope to be working together again soon.", 
        },

        { 
            id:3,
            name: 'Simon Martyr',
          comp_name:"Vodafone Group",
          text:"I found the services provided by Datamellon to be first rate. Their technical knowledge was first rate, and the pro-activity and drive they demonstrated on the project I worked on with them was most impressive. They also handled the end-to-end management of the project extremely well too.", 
        },

        { 
            id:4,
            name: 'Oluwaseyi Ogidiolu',
          comp_name:"NewWaves Ecosystem Ltd",
          text:"The competence and professionalism level displayed over the period of engagement - which is still ongoing has been of immense benefit to the project and the organisation. The business philosophy in client engagement is Satisfactory Delivery first. This has made the project successful thus far. As the Project Manager of NewWaves, what Datamellon has sold to me is not cloud service but the AWS power. For me, Datamellon has created a niche above peers and have made AWS a recurring vocabulary in our own client and stakeholder engagements.", 
        },

        { 
            id:5,
            name: 'Sam Semakula',
          comp_name:"1st solution consulting gmbh",
          text:"Datamellon's Cloud Technology competence and Services have always been exemplary in our Enterprise customer space. They enabled us to deliver various Projects at Vodafone with very high customer satisfaction.", 
        },

        {   
            id:6,
            name: 'Olufemi Williams',
          comp_name:"Newwaves Ecosystem Ltd",
          text:"They exhibited highest level of Professionalism. Customer support was excellent", 
        },
        
        
    ]);

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 1, itemsToScroll: 2 },
        { width: 768, itemsToShow: 1 },
        { width: 1200, itemsToShow: 1 }
      ];

  return (
    <div className='Carouselslide'>
        <div className="home_max_width">
            <div className="CarouselslideCont">
            <h2 className='heroTitles'>What Our Clients say about us</h2>
            <div className="carousel-wrapper">
                {/* <Carousel breakPoints={breakPoints}
                    pagination ={true}
                    enableAutoPlay={true}
                    enableSwipe = {true}
                    focusOnSelect={true}
                > */}
                <Swiper
                  spaceBetween={30}
          
                  centeredSlides={true}
                  autoplay={{
                    delay:4000,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  rewind={true}
                  navigation={true}
                  modules={[Autoplay, Pagination]}   className='testimonial-main-con mySwiper'
                >
                 
                {items.map((item) => (
                   <SwiperSlide>
                    <div className='caro-info' key={item.id}>
                        <div className='caro_full'>
                            <div className='caro_txt' >
                                {item.text}
                            </div>
                            <div className="caro-name">
                                <p>{item.name}</p>
                                
                            </div>
                            <div className='caro-cpname'>
                                {item.comp_name}
                            </div>
                        </div>
                        
                    </div>
                    </SwiperSlide>
                ))}
                </Swiper>

                {/* </Carousel> */}
            </div>
            </div>
        </div>
    </div>
  )
}
