import React, { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";
import Topmenu from "../../../components/Topmenu/Topmenu";
import smallsmall from "../../../assets/images/smallsmall-svglogo.svg";
import "./Cstudy1.css";

export default function Trove() {
  const img_link = process.env.REACT_APP_IMG_LINK;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Topmenu />
      <div className="cstudy1">
        <div className="home_max_width">
          <div className="casestudcontainer">
            <div className="csheadbg"></div>
            <div className="cstudbody">
              <div className="cstudyclient">
                <div>
                  <div className="cstudytopic">Executive Summary</div>
                  <div className="cstudynormtxt">
                    <p>
                      {" "}
                      SmallSmall Technology is a property technology firm that
                      provides cost-effective and adaptable solutions for home
                      renting, buying, listing, and staying. The company
                      encountered difficulties with scalability and
                      cost-efficiency of their web application, which was
                      previously hosted by another service provider. To address
                      these challenges, they partnered with Datamellon, an AWS
                      advanced tier partner, to facilitate a seamless migration
                      of their workload to Amazon Web Services (AWS) using
                      industry-leading practices and tools. As a result of this
                      migration, SmallSmall Technology experienced enhanced
                      performance, security, and reliability for their
                      application, while also benefiting from reduced
                      operational and management overhead.
                    </p>
                  </div>
                </div>
                <div className="vodafone">
                  <img src={smallsmall} alt="Zencey Logo" />
                </div>
              </div>

              <div className="cstudychallenge">
                <div className="cstudytopic">Customer Challenge</div>
                <div className="cstudynormtxt">
                  <p
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    Established in 2019, SmallSmall Technology's mission
                    revolves around facilitating seamless and efficient living
                    solutions for individuals. The company's journey began with
                    the introduction of RentSmallSmall, a property letting
                    service enabling customers to effortlessly search for homes,
                    conduct free unit inspections, and conveniently pay monthly.
                    Building on their success, they subsequently launched
                    Buysmallsmall, which empowers customers to invest in real
                    estate and start earning assured rent. Their most recent
                    addition to the product lineup is StayOne, a premium quality
                    living space solution catering to those seeking short-term
                    rentals that can be paid on a nightly basis.
                  </p>
                  <div
                    style={{
                      marginBottom: "30px",
                    }}
                  >
                    <p
                      style={{
                        marginBottom: "20px",
                      }}
                    >
                      Initially, the company's web application was hosted on a
                      traditional web hosting provider. However, as the company
                      experienced rapid growth, they encountered several
                      challenges with their hosting environment:
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginBotom: "20px",
                      }}
                    >
                      <div className="icon_img">
                        <img src={`${img_link}/Bulb.svg`} alt="" />
                      </div>
                      <p
                        style={{
                          marginBottom: "30px",
                        }}
                      >
                        <strong>Scalability:</strong> With increasing traffic
                        and customer demand, the company needed a hosting
                        solution that could scale up or out effectively.
                        Unfortunately, their existing hosting service had
                        limited resources and lacked options for seamless
                        scalability.
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div className="icon_img">
                        <img src={`${img_link}/Bulb.svg`} alt="" />
                      </div>
                      <p
                        style={{
                          marginBottom: "30px",
                        }}
                      >
                        <strong>Availability:</strong> Ensuring high
                        availability and uptime of their application, especially
                        during peak periods, was a priority for the company.
                        However, their current hosting service lacked redundancy
                        and failover mechanisms, making it susceptible to
                        downtime and potential data loss.
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div className="icon_img">
                        <img src={`${img_link}/Bulb.svg`} alt="" />
                      </div>
                      <p
                        style={{
                          marginBottom: "30px",
                        }}
                      >
                        <strong>Cost-efficiency:</strong> Seeking to optimize
                        their hosting costs and only pay for the resources they
                        use, the company faced a hurdle with their hosting
                        service's fixed pricing plans that charged based on
                        allocated resources, regardless of actual usage.
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div className="icon_img">
                        <img src={`${img_link}/Bulb.svg`} alt="" />
                      </div>
                      <p
                        style={{
                          marginBottom: "30px",
                        }}
                      >
                        <strong> Operational and management overhead:</strong>{" "}
                        The company aimed to concentrate on their core business
                        and innovation rather than getting bogged down by
                        hosting infrastructure management. However, the existing
                        hosting service required manual tasks such as backups,
                        updates, patches, and security configurations, adding to
                        their operational burden.
                      </p>
                    </div>
                  </div>
                  <p>
                    To overcome these challenges and improve their web
                    application's performance, reliability, and
                    cost-effectiveness, the company sought a new hosting
                    solution. The company realized that they needed a more
                    modern, agile and scalable cloud service provider that could
                    meet their current and future needs without compromising on
                    security and compliance.
                  </p>
                </div>
              </div>

              <div className="cstudychallenge">
                <div className="cstudytopic">Why AWS</div>
                <div className="cstudynormtxt">
                  <p
                    style={{
                      marginBottom: "30px",
                    }}
                  >
                    The company chose AWS as their cloud service provider for
                    several reasons:
                  </p>

                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div className="icon_img">
                        <img src={`${img_link}/Bulb.svg`} alt="" />
                      </div>
                      <p
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        AWS offers a wide range of services and features that
                        enable customers to build, deploy and manage
                        applications in the cloud with ease and flexibility.
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div className="icon_img">
                        <img src={`${img_link}/Bulb.svg`} alt="" />
                      </div>
                      <p
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        AWS provides scalability and elasticity that allow
                        customers to adjust their resources according to their
                        demand and pay only for what they use.
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div className="icon_img">
                        <img src={`${img_link}/Bulb.svg`} alt="" />
                      </div>
                      <p
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        AWS ensures high availability and reliability of
                        applications by offering multiple regions, availability
                        zones, load balancers, auto scaling groups and backup
                        solutions.
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div className="icon_img">
                        <img src={`${img_link}/Bulb.svg`} alt="" />
                      </div>
                      <p
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        AWS delivers security and compliance by offering
                        encryption, firewalls, identity and access management,
                        monitoring and auditing tools.
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <p
                      style={{
                        marginBottom: "20px",
                      }}
                    >
                      AWS supports innovation and experimentation by offering
                      low-cost and free-tier services, as well as various tools
                      and frameworks for developing new products and features.
                    </p>
                  </div>
                </div>
              </div>

              <div className="cstudysolution">
                <p>
                  <div className="cstudytopic">Why Datamellon</div>
                  <div className="cstudynormtxt" style={{
                    marginBottom: "20px"
                  }}>
                    The company decided to partner with Datamellon, an AWS
                    advanced tier partner, to help them migrate their workload
                    to AWS. Datamellon is a cloud consulting firm that
                    specializes in cloud migration, modernization, optimization
                    and managed services. Datamellon has extensive experience
                    and expertise in working with AWS services and tools, as
                    well as following AWS best practices and methodologies.
                    Datamellon also has a strong track record of delivering
                    successful projects for various customers across different
                    industries.
                  </div>
                </p>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginBottom: "20px"
                  }}
                >
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <p>
                    Datamellon provided end-to-end support throughout the
                    migration process, from planning and design to execution and
                    testing.
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginBottom: "20px"
                  }}
                >
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <p
                   
                  >
                    Datamellon leveraged AWS tools such as Control Tower,
                    CodePipeline, IAM Identity Center Set up, EC2, Route 53, RDS
                    and Certificate Manager to migrate the workload efficiently
                    and securely.
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginBottom: "20px"
                  }}
                >
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <p
                    
                  >
                    Datamellon used Terraform as the infrastructure as code tool
                    to automate the deployment of all resources and
                    infrastructure on AWS.
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginBottom: "20px"
                  }}
                >
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <p
                    
                  >
                    Datamellon ensures minimal disruption and downtime during
                    the migration by performing a seamless cutover from their
                    current hosting environment to AWS.
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <p
                    
                  >
                    Datamellon offered post-migration support and guidance to
                    ensure smooth operation and maintenance of the workload on
                    AWS.
                  </p>
                </div>
              </div>

              <div className="cstudysolution">
                <p>
                  <div className="cstudytopic">Partner Solution</div>
                  <div className="cstudynormtxt">
                    Datamellon designed and implemented a comprehensive solution
                    for migrating the workload from the current environment to
                    AWS. The solution involved the following steps:
                  </div>
                </p>
                <div className="icon_text_sub_cont">
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Architect and Implement Project Infrastructure Baseline
                      </div>
                      <div className="cstudynormtxt2">
                      
                        This involved designing and deploying the network,
                        security, storage and database components for the
                        workload on AWS.
                      </div>
                    </div>
                  </div>

                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Implement Cloud Foundation using Control Tower to set up
                        a multi-account strategy and enable GuardDuty</div>
                      <div className="cstudynormtxt2">
                        Tower is a service that automates the creation of a
                        well-architected multi-account AWS environment based on
                        best practices. GuardDuty is a service that continuously
                        monitors for malicious or unauthorized activity across
                        AWS accounts.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Repository Setup & Management using CodePipeline
                        suite(CodeCommit, CodeBuild, CodeDeploy).
                      </div>
                      <div className="cstudynormtxt2">
                        The
                        application codebase is in their GitHub repository.
                        CodePipeline is a service that automates the continuous
                        integration and continuous delivery (CI/CD) pipeline for
                        applications. GitHub is a platform that hosts code
                        repositories and enables collaboration among developers.
                      </div>
                    </div>
                  </div>

                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Setting up account access using IAM Identity Center Set
                        up.
                      </div>
                      <div className="cstudynormtxt2">
                        IAM Identity Center is a feature that simplifies the
                        management of users and groups across AWS accounts and
                        services using temporary AWS credentials to grant
                        access.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Workload Migration</div>
                      <div className="cstudynormtxt2">
                        Workload Migration from their previous environment to
                        AWS where their application runs on Amazon EC2. EC2 is a
                        service that provides scalable and secure compute
                        capacity in the cloud.
                      </div>
                    </div>
                  </div>

                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Update of SSL certificate to AWS Certificate Manager
                      </div>
                      <div className="cstudynormtxt2">
                        Certificate Manager is a service that provides and
                        manages SSL/TLS certificates for AWS resources.
                      </div>
                    </div>
                  </div>

                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Deployment of all resources and infrastructure using
                        Infrastructure as Code-Terraform
                      </div>
                      <div className="cstudynormtxt2">
                        Terraform is a tool that enables customers to define,
                        provision and manage infrastructure as code.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cstudybenefits">
                <div className="cstudytopic">Results and Benefits</div>
                <div className="cstudynormtxt" style={{
                    marginBottom: "20px"
                }}>
                  The migration to AWS resulted in several benefits for the
                  company, such as:
                </div>
                <div className="icon_text_sub" style={{
                    marginBottom: "20px"
                }}>
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <div className="content__main">
                    <div className="cstudytopic2">Improved performance</div>
                    <div className="cstudynormtxt2">
                      The company was able to scale their application to handle
                      higher traffic and demand from their customers without
                      compromising on speed or quality.{" "}
                    </div>
                  </div>
                </div>
                <div className="icon_text_sub" style={{
                    marginBottom: "20px"
                }}>
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <div className="content__main">
                    <div className="cstudytopic2">Enhanced Security</div>
                    <div className="cstudynormtxt2">
                      The company was able to protect their application and data
                      from unauthorized access or attacks by using encryption,
                      firewalls, identity and access management, monitoring and
                      auditing tools provided by AWS.
                    </div>
                  </div>
                </div>

                <div className="icon_text_sub" style={{
                    marginBottom: "20px"
                }}>
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <div className="content__main">
                    <div className="cstudytopic2">Increased reliability</div>
                    <div className="cstudynormtxt2">
                      The company was able to ensure high availability and
                      uptime of their application by using multiple regions,
                      availability zones, load balancers, auto scaling groups
                      and backup solutions provided by AWS.{" "}
                    </div>
                  </div>
                </div>

                <div className="icon_text_sub" style={{
                    marginBottom: "20px"
                }}>
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <div className="content__main">
                    <div className="cstudytopic2">Reduced Cost</div>
                    <div className="cstudynormtxt2">
                      The company was able to optimize their hosting costs by
                      paying only for what they use and taking advantage of
                      low-cost and free-tier services offered by AWS.{" "}
                    </div>
                  </div>
                </div>

                <div className="icon_text_sub" style={{
                    marginBottom: "20px"
                }}>
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <div className="content__main">
                    <div className="cstudytopic2">
                      Decreased operational and management overhead
                    </div>
                    <div className="cstudynormtxt2">
                      The company was able to focus on their core business and
                      innovation rather than managing their hosting
                      infrastructure by using automation, orchestration and
                      managed services provided by AWS and Datamellon.{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="cstudybenefits" style={{
                    marginTop: "40px"
                }}>
                <div className="cstudytopic">Next Steps</div>
                <div className="cstudynormtxt">
                  Datamellon will continue to offer post-migration support and
                  guidance to the company to ensure smooth operation and
                  maintenance of their workload on AWS. Datamellon will also
                  help the company monitor and optimize their workload
                  performance, security, reliability and cost on AWS.
                  Additionally, Datamellon will discuss with the company the
                  possibility of modernizing their workload to become
                  cloud-native by using serverless, containerized and
                  microservices architectures on AWS. This will enable the
                  company to further enhance their agility, scalability and
                  innovation capabilities on AWS.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
