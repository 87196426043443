import React, { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";
import Topmenu from "../../../components/Topmenu/Topmenu";
import trove from "../../../assets/images/new-trove.png";
import "./Cstudy1.css";

export default function Trove() {
  const img_link = process.env.REACT_APP_IMG_LINK;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Topmenu />
      <div className="cstudy1">
        <div className="home_max_width">
          <div className="casestudcontainer">
            <div className="csheadbg"></div>
            <div className="cstudbody">
              <div className="cstudyclient">
                <div>
                  <div className="cstudytopic">Case Study</div>
                  <div className="cstudynormtxt">
                    Datamellon migrated Trove’s Kubernetes cluster workload from
                    MainOne - an Equinix Company. We worked closely with the
                    Trove development team to re-organize their current aws
                    accounts on a functional basis by separating the
                    development, production, and staging environments into
                    separate accounts through AWS organization.
                  </div>
                </div>
                <div className="vodafone">
                  <img src={trove} alt="Zencey Logo" />
                </div>
              </div>

              <div className="cstudychallenge">
                <div className="cstudytopic">The Challenge</div>
                <div className="cstudynormtxt">
                  <b>Trove:</b> An investment company that allows end-users to
                  invest in American, Chinese, and Nigerian stocks, Bonds, ETFs,
                  and more with a minimum start-up fee. As a modern-day
                  investment platform built on a microservice architecture, the
                  need to have a highly scalable, flexible, and highly available
                  infrastructure was required, as this will optimize cost and
                  make the platform highly available to prevent any downtime for
                  investors.
                </div>
              </div>

              <div className="cstudysolution">
                <p>
                  <div className="cstudytopic">The Solution</div>
                  <div className="cstudynormtxt">
                    Datamellon migrated Trove’s Kubernetes cluster workload from
                    on-premises to AWS EKS (Elastic Kubernetes Service). We
                    worked closely with the Trove development team to
                    re-organize their current aws accounts on a functional basis
                    by separating the development, production, and staging
                    environments into separate accounts through AWS
                    organization. Also, we brought Trove’s production
                    environment from the bare metal into the new AWS production
                    account created. The migration was accomplished with suites
                    of AWS-managed services below
                  </div>
                </p>
                <div className="icon_text_sub_cont">
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">AWS Control Tower</div>
                      <div className="cstudynormtxt2">
                        AWS Control Tower was set up to provide a centralized,
                        secure, and consistent approach to set up and govern the
                        multiple AWS accounts created. By leveraging AWS Control
                        Tower during the migration process, we created
                        multi-account environments with predefined security
                        guardrails to ensure a secure, and compliant environment
                        for the migrated Kubernetes cluster.
                      </div>
                    </div>
                  </div>

                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Compute</div>
                      <div className="cstudynormtxt2">
                        During the migration process, Amazon EKS (Elastic
                        Kubernetes Service) was needed for computing because it
                        provided a managed Kubernetes service on AWS. EKS
                        allowed us to deploy and manage containerized
                        applications using Kubernetes, which offered a scalable
                        and flexible computing platform. EKS handled the
                        underlying infrastructure, such as server provisioning,
                        scaling, and availability, allowing us to focus on
                        deploying and running the application seamlessly.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Database</div>
                      <div className="cstudynormtxt2">
                        The Trove infrastructure had a MySQL database. We
                        replicated the database by migrating the database from
                        the master account to the production account. Once the
                        migration was complete, we had to shut down the
                        production database to optimize cost while pointing the
                        other services running on the Kubernetes cluster to
                        their respective database.
                      </div>
                    </div>
                  </div>

                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Deployment</div>
                      <div className="cstudynormtxt2">
                        The solution was deployed, retaining Trove’s current
                        CI/CD pipeline from BitBucket. The AWS infrastructure
                        resources were provisioned with AWS CI/CD toolchain of
                        codecommit, codepipeline, and codebuild. During the
                        migration process, their existing Amazon ECR (Elastic
                        Container Registry) was used for deployment because it
                        provided a secure and scalable repository for storing
                        and managing container images. ECR allows organizations
                        to securely store, version, and share their container
                        images, making it easier to deploy applications to the
                        target environment. ECR simplifies the deployment
                        process by providing a centralized and reliable
                        repository for container images, ensuring consistent and
                        controlled deployments as part of the migration process.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Security</div>
                      <div className="cstudynormtxt2">
                        Access to services and resources was restricted to users
                        and roles using the least privileged principle. This
                        principle refers to the practice of granting users or
                        roles the minimum level of permissions necessary to
                        perform their required tasks or functions. It reduces
                        the risk of unauthorized access to AWS resources. Also,
                        a Multi-Factor authentication was enabled to help
                        mitigate the risk of unauthorized access to AWS accounts
                        by making it significantly more difficult for attackers
                        to gain control.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cstudybenefits">
                <div className="cstudytopic">The Benefits</div>
                <div className="cstudynormtxt">
                  The solution provided a highly scalable, flexible, and highly
                  available system with zero downtime and cost optimization.
                  This improves the user experience of the platform making sure
                  users don’t experience any downtime while making use of the
                  application while providing an efficient infrastructure that
                  is flexible, scalable, and saves cost for the company.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
