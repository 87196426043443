import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CareersPage from './pages/Careers/CareersPage';
import CaseStudy from './pages/CaseStudy/CaseStudy';
import Home from './pages/Home/Home';
import ProductPage from './pages/Product/Product';
import Partners from './pages/Partners/Partners';
import Servicepage from './pages/Servicepage/Servicepage';
import WhyDatamellon from './pages/WhyDatamellon/WhyDatamellon';
import OurStory from './pages/OurStory/OurStory';
import Leadership from './pages/Leadership/Leadership';
import NewsRoomPage from './pages/NewsRoomPage/NewsRoomPage';
import CloudFormation from './pages/ViewServiceTemplate/Services/CloudFormation';
import JoinUs from './pages/JoinUs/JoinUs';
import CloudMigration from './pages/ViewServiceTemplate/Services/CloudMigration';
import CareersV2 from './pages/CareersV2/CareersV2';
// import Vodafone from './pages/CaseStudy/Cstudy1/Cstudy1';
import Lambda from './pages/LambdaAws/LambdaAws';
import NewsRoom from './components/NewsRoom/NewsRoom';
import AwsNewsroom from './pages/NewsRoomPage/AwsNewsroom';

import VodafoneCaseStudy from './pages/CaseStudy/Cstudy1/CstudyTemp';
import Aws from './pages/Aws/Aws';
import Competences from './pages/Competences/Competences';
import New_webinar from './pages/New_webinar/New_webinar';
import DynamoBDAws from './pages/LambdaAws/DynamoBDAws';
import Drcc from './pages/CaseStudy/Cstudy1/Drcc';
import Awsnewoffice from './pages/Awsnewoffice/Awsnewoffice';
import New_webinar_for_startups from './pages/New_webinar/New_webinar_for_startups';
import Trove from './pages/CaseStudy/Cstudy1/Trove';
import Zencey from './pages/CaseStudy/Cstudy1/Zencey';
import NewEvent from './pages/StartupsEvent/NewEvent';
import Providus from './pages/CaseStudy/WarFiles/Providus';
import Fincra from './pages/CaseStudy/WarFiles/Fincra';
import SmallSmall from './pages/CaseStudy/Cstudy1/SmallSmall'
import WindowsEC2 from './pages/LambdaAws/WindowsEC2';
import PublicEvent from './pages/StartupsEvent/PublicEvent';
import AllEvents from './pages/Events/AllEvents';
import MigrationFSI from './pages/Servicepage/MigrationFSI';
import Payday from './pages/CaseStudy/Cstudy1/Payday';
import ViewCareer from './pages/CareersV2/ViewCareer';
import RisingStarAward from './pages/NewsRoomPage/RisingStarAward';
import WAF from './pages/LambdaAws/WAF';
import ProvidusCaseStudy from './pages/CaseStudy/Cstudy1/ProvidusCaseStudy';
import ControlTower from './pages/LambdaAws/ControlTower';
import CrcCaseStudy from './pages/CaseStudy/Cstudy1/CrcCaseStudy';
import ApiGateway from './pages/LambdaAws/ApiGateway';
import CloudFormationSpecialization from './pages/LambdaAws/CloudFormationSpecialization';
import ServiceCatalog from './pages/LambdaAws/ServiceCatalog';
import TicketsirCaseStudy from './pages/CaseStudy/Cstudy1/TicketsirCaseStudy';
import GTCaseStudy from './pages/CaseStudy/Cstudy1/GTCaseStudy';
import Moonshoot from './pages/Moonshoot/Moonshoot';


const Routing = () => {
    return (
        <Routes>
            <Route index path='/' element={<Home />} />
            <Route path='/career' element={<ViewCareer />} />
            <Route path='/services' element={<Servicepage />} />
            <Route path='/migration-fsi' element={<MigrationFSI />} />
            <Route path='/cloud-foundation' element={<CloudFormation />} />
            <Route path='/cloud-migration' element={<CloudMigration />} />
            <Route path='/products' element={<ProductPage />} />
            <Route path='/case-studies' element={<CaseStudy />} />
            <Route path='/aws-newsroom' element={<NewsRoom />} />
            <Route path='/careers' element={<CareersV2 />} />
            <Route path='/partners' element={<Partners />} />
            <Route path='/why-datamellon' element={<WhyDatamellon />} />
            <Route path='/our-story' element={<OurStory />} />
            <Route path='/leadership' element={<Leadership />} />
            <Route path='/aws-opens-lagos-office-increases-commitment-to-africa' element={<Awsnewoffice />} />
            <Route path='/datamellon_awarded_aws_ssa_rising_star_partner' element={<RisingStarAward />} />
            <Route path='/newsroom' element={<NewsRoomPage />} />
            <Route path='/join-us' element={<JoinUs />} />
            {/* <Route path='/aws-newsroom' element={<AwsNewsroom />} /> */}
            <Route path='/amazon-web-services-outposts-rack-launches-in-nigeria' element={<AwsNewsroom />} />

            <Route path='/aws-lambda' element={<Lambda />} />
            <Route path='/amazon-dynamodb' element={<DynamoBDAws />} />
            <Route path='/aws-service-delivery-amazon-ec2-for-microsoft-windows' element={<WindowsEC2 />} />
            <Route path='/aws-waf' element={<WAF />} />
            <Route path='/aws-api-gateway' element={<ApiGateway />} />
            <Route path='/aws-cloudformation' element={<CloudFormationSpecialization/>} />
            <Route path='/service-catalog' element={<ServiceCatalog/>} />
            {/* <Route path='/service-catalog' element={<ServiceCatalog/>} /> */}

            <Route path='/aws' element={<Aws />} />
            <Route path='/controltower' element={<ControlTower />} />
            <Route exact path='/vodafone-case-study' element={<VodafoneCaseStudy />} />
            <Route exact path='/vodafone-drcc-compartment-pipeline' element={<Drcc />} />
            <Route exact path='/trove-case-study' element={<Trove />} />
            <Route exact path='/zencey-case-study' element={<Zencey />} />
            <Route exact path='/providus-war' element={<Providus />} />
            <Route exact path='/fincra-war' element={<Fincra />} />
            <Route exact path='/small-small' element={<SmallSmall />} />
            <Route exact path='/payday-case-study' element={<Payday />} />
            <Route exact path='/providus-case-study' element={<ProvidusCaseStudy />} />
            <Route exact path='/crc-case-study' element={<CrcCaseStudy />} />
            
            <Route exact path='/ticketsir' element={<TicketsirCaseStudy />} />
            <Route exact path='/gtb' element={<GTCaseStudy />} />

            {/* <Route path='/case-study/vodafone' element={<Vodafone/>}/> */}
            <Route path='/competences' element={<Competences />} />
            <Route path='/public-sector-cloud-powered-digital-transformation-with-aws' element={<New_webinar />} />
            <Route path='/startups' element={<New_webinar_for_startups />} />
            <Route path='/lsw-devjam' element={<NewEvent />} />
            <Route path='/events' element={<AllEvents />} />

            <Route path='/moonshot' element={<Moonshoot />} />

            
            {/* <Route path='/events/public-sector' element={<PublicEvent />} /> */}

        </Routes>
        // </BrowserRouter>

    )
}

export default Routing
