import React from 'react'
import eventone from '../../assets/images/event-1.png'
import eventtwo from '../../assets/images/event-2.png'
import eventthree from '../../assets/images/event-3.png'
import eventfour from '../../assets/images/event-4.png'
import eventfive from '../../assets/images/event-5.png'
import eventsix from '../../assets/images/event-6.png'
import './events.css'


const EventPictures = () => {
  return (
    <div className='eventpictures__container'>
        <div className="eventlist__heading">
        <h3>Celebrating our Event Successes</h3>
        <p>Completed Event Gallery</p>
      </div>
      <div className="eventpictures__main">

        <div>
          <img src={eventone} alt="DataMellon Event" />
          <img src={eventtwo} alt="DataMellon Event" />
        </div>
        <div>
          <img src={eventthree} alt="DataMellon Event" />
          <img src={eventfour} alt="DataMellon Event" />
        </div>
        <div>
          <img src={eventfive} alt="DataMellon Event" />
          <img src={eventsix} alt="DataMellon Event" />
        </div>
      </div>
    </div>
  )
}

export default EventPictures