import React from 'react'
import Header_illustration from './../../assets/images/Header_illustration_2.svg'
import { Link } from 'react-router-dom'
import { IoIosArrowForward } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

export default function AwsCards( {title, subtitle,image,linkup}) {
  const navigate = useNavigate();
  return (
    <div className='AwsCards' style={{ cursor: 'pointer' , }} onClick={() => navigate(linkup)}>
    <div className='toImag' >
   <img src={image}/>
    </div>
    <div className='textares'>
     <div className='textares_mini'>
       <h3>{title}</h3>
       <p>{subtitle}</p>
       {/* <Link to={link}>{linkText} <IoIosArrowForward fontSize={12}/></Link> */}
     </div>
    </div>
   </div>
  )
}
