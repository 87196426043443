import React from "react";
import CaseStudyComp from "./CaseStudyComp";

const AllCases = () => {
  const img_link = process.env.REACT_APP_IMG_LINK;

  const cases = [
    {
      title: "TicketSir",
      subtitle:
        "How Datamellon helped GTBANK Migrate its GTWorld Mobile App to AWS Cloud",
      link: "/gtb",
      img: `url("${img_link}/gtb-co.png")`,
      name: "gtb",
    },
    {
      title: "TicketSir",
      subtitle:
        "TicketSir Optimizes Performance and Cost with AWS Migration",
      link: "/ticketsir",
      img: `url("${img_link}/ticketsir.png")`,
      name: "ticketsir",
    },
    
    {
      title: "CRC Credit Bureau",
      subtitle:
        "CRC Credit Bureau Migrates its Applications and Data to AWS",
      link: "/crc-case-study",
      img: `url("${img_link}/crc.png")`,
      name: "crc",
    },
    {
      title: "Providus",
      // subtitle:
      //   "Providus Bank Modernizes API Management with AWS & Datamellon",
      subtitle: "Empowering Digital Banking: ProvidusBank’s Seamless Migration to AWS with Datamellon",
      link: "/providus-case-study",
      img: `url("${img_link}/Providus_Bank_logo.png")`,
      name: "providus",
    },
    {
      title: "Payday",
      subtitle:
        "Payday Cuts Cost by over 40% and Tightens Security Posture",
      link: "/payday-case-study",
      img: `url("${img_link}/bg-payday.png")`,
      name: "payday",
    },
    {
      title: "SmallSmall Technology",
      subtitle:
        "SmallSmall Technology Migrates to AWS with Datamellon",
      link: "/small-small",
      img: `url("${img_link}/smallsmall.svg")`,
      name: "smallsmall",
    },
    {
      title: "Fincra",
      subtitle:
        "Fincra Achieves Cost Savings and Optimization with Well-Architected Review",
      link: "/fincra-war",
      img: `url("${img_link}/fincra-background.png")`,
      name: "fincra",
    },
    {
      title: "Providus Bank",
      subtitle:
        "ProvidusBank Achieves Cost Savings with Well-Architected Review",
      link: "/providus-war",
      img: `url("${img_link}/Providus_Bank_logo.png")`,
      name: "providus_bank",
    },
    {
      title: "Trove",
      subtitle:
        "Datamellon migrated Trove’s Kubernetes cluster workload from MainOne - an Equinix Company.  ",
      // subtitle:
      //   "Datamellon migrated Trove’s Kubernetes cluster workload from on-premises to AWS EKS (Elastic Kubernetes Service).",
      link: "/trove-case-study",
      img: `url("${img_link}/new-trove.png")`,
      name: "trove_self_service",
    },

    {
      title: "Zencey",
      subtitle:
        "Datamellon carried out an architecture review of Zencey’s existing infrastructure and recommends a well-architected DevOps deployment.",
      link: "/zencey-case-study",
      img: `url("${img_link}/Zencey.png")`,
      name: "vodafone_self_service",
    },

    {
      title: "Vodafone",
      subtitle:
        "Datamellon delivers Self-Service Multi Master AWS Account Provisioning and Management for Vodafone Group.",
      link: "/vodafone-case-study",
      // img: `url("${img_link}/vodafone_group_3.png")`,
      img: `url("${img_link}/vodafone_new.svg")`,
      // img: Vodafone_dm,
      name: "vodafone_self_service",
    },

    {
      title: "Vodafone",

      subtitle:
        "Datamellon builds DRCC Compartment Provisioning Pipeline for Vodafone Group.",
      link: "/vodafone-drcc-compartment-pipeline",
      // img: `url("${img_link}/vodafone_group_1.png")`,
      img: `url("${img_link}/vodafone_new.svg")`,
      name: "vodafone_drcc",
    },
  ];

  return (
    <div className="home_max_width">
      <div className="casestudy__container">
        {cases.map((x, index) => (
          <div className="Parent_case">
            <div style={{ display: "flex", width: "100%" }}>
              <CaseStudyComp
                image={x.img}
                key={index}
                title={x.title}
                subtitle={x.subtitle}
                link={x.link}
                img={x.img}
                linkText="View Case Study"
                name={x.name}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllCases;
