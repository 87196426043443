import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Topmenu from "../../components/Topmenu/Topmenu";
import Overhead from "../../components/OverLayHeader/Overhead";
import "./LambdaAws.css";
import { useNavigate } from "react-router-dom";
import Ready from "../../components/Ready/Ready";

const ControlTower = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const img_link = process.env.REACT_APP_IMG_LINK;
  const navigate = useNavigate();

  return (
    <div>
      <Topmenu />
      <div className="lambda_main">
        <Overhead
          HeaderBG={`url("${img_link}/Lambda_img1.svg")`}
          title="AWS Control Tower Specialization"
          subsub="Datamellon's Tailored AWS Control Tower Solutions"
          subtitle="In the ever-evolving landscape of cloud computing, effective governance is paramount for organizations to ensure secure, compliant, and scalable operations."
          subtitle1="Datamellon, as an AWS Advanced Consulting Partner, is at the forefront of providing comprehensive solutions, and our AWS Control Tower Specialization is designed to empower businesses with robust cloud governance capabilities."
        />
        <div className="home_max_width">
          {/* <div
            className="Usecases"
            style={{
              marginTop: "80px",
            }}
          >
            <div className="title">Use cases</div>
            <div className="content_usecases">
              <div className="content">
                <div className="subtitlex">Financial Services</div>
                <div className="normtext">
                  AWS WAF provides a fortified defense against account takeover
                  attempts, phishing attacks, and fraudulent activities,
                  ensuring the confidentiality and integrity of financial data.
                </div>
              </div>
              <div className="content">
                <div className="subtitlex">E-commerce Platforms</div>
                <div className="normtext">
                  AWS WAF ensures the integrity of e-commerce platforms by
                  protecting customer data and transactional processes against
                  SQL injection, cross-site scripting (XSS), and other common
                  attacks.
                </div>
              </div>
              <div className="content">
                <div className="subtitlex"> Healthcare Applications</div>
                <div className="normtext">
                  WAF protects healthcare applications from data breaches,
                  ensuring compliance with industry regulations and maintaining
                  patient confidentiality.
                </div>
              </div>
              <div className="content">
                <div className="subtitlex">Media and Entertainment</div>
                <div className="normtext">
                  Web applications in the media and entertainment sector often
                  face distributed denial of service (DDoS) attacks. AWS WAF
                  shields against these attacks, ensuring uninterrupted access
                  to content for users around the globe.
                </div>
              </div>
            </div>
          </div> */}

          <div>
            <div className="home_max_width">
              <div className="containPadding">
                <div className="titler">Key Features</div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Account Vending</div>
                      <div className="cstudynormtxt2">
                        AWS Control Tower automates the creation of new accounts
                        using predefined account blueprints. Datamellon enhances
                        this feature by customizing blueprints to align with
                        organizational structures, ensuring seamless and
                        standardized account provisioning.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Guardrails and Best Practices
                      </div>
                      <div className="cstudynormtxt2">
                        Datamellon integrates tailored guardrails and best
                        practices into AWS Control Tower, ensuring adherence to
                        compliance standards, security policies, and operational
                        excellence. Our specialized approach goes beyond default
                        configurations to meet the specific needs of diverse
                        industries.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Multi-Account Management
                      </div>
                      <div className="cstudynormtxt2">
                        Efficiently manage multiple AWS accounts under a single
                        organizational unit. Datamellon leverages its expertise
                        to streamline the account management process, enabling
                        organizations to optimize resource allocation, cost
                        control, and resource sharing.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Identity and Access Management (IAM) Integration
                      </div>
                      <div className="cstudynormtxt2">
                        Enhance security and compliance by seamlessly
                        integrating IAM policies within AWS Control Tower.
                        Datamellon configures IAM settings to enforce the
                        principle of least privilege, mitigating security risks
                        and ensuring a secure cloud environment.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flexer">
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Custom Landing Zones</div>
                      <div className="cstudynormtxt2">
                        Datamellon goes beyond the default landing zones offered
                        by AWS Control Tower, creating custom landing zones that
                        align with organizational goals and compliance
                        requirements. This ensures a tailored, secure foundation
                        for workloads across different business units.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="home_max_width">
              <div className="containPadding">
                <div className="titler">Use Cases</div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Enterprise-scale Cloud Adoption
                      </div>
                      <div className="cstudynormtxt2">
                        For large enterprises embarking on cloud journeys,
                        Datamellon's AWS Control Tower Specialization
                        facilitates a smooth transition by providing a secure
                        and well-governed cloud environment. This is crucial for
                        maintaining compliance, ensuring security, and
                        optimizing costs across the organization.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Regulatory Compliance</div>
                      <div className="cstudynormtxt2">
                        Industries such as finance, healthcare, and government
                        are subject to stringent regulatory requirements.
                        Datamellon's specialized AWS Control Tower
                        implementation helps organizations in these sectors
                        achieve and maintain regulatory compliance, reducing the
                        burden of manual compliance checks.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Simplified Resource Management
                      </div>
                      <div className="cstudynormtxt2">
                        Datamellon's AWS Control Tower Specialization simplifies
                        resource management for organizations with diverse
                        workloads. By implementing tailored guardrails and best
                        practices, we enable clients to manage resources
                        efficiently, optimize costs, and enhance overall
                        operational performance.
                      </div>
                    </div>
                  </div>
                  {/* <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        {" "}
                        Automated Incident Response and Mitigation
                      </div>
                      <div className="cstudynormtxt2">
                        Speed is paramount in responding to security incidents.
                        AWS WAF includes automated incident response and
                        mitigation capabilities. When predefined security rules
                        are triggered, automated actions are initiated to
                        swiftly respond to and mitigate potential threats in
                        real-time. This ensures that your web applications are
                        protected around the clock, reducing the risk of
                        prolonged exposure to security vulnerabilities.
                      </div>
                    </div>
                  </div> */}
                </div>

                {/* <div className="flexer">
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Comprehensive Web Traffic Insights for Informed
                        Decision-Making
                      </div>
                      <div className="cstudynormtxt2">
                        Understanding your web traffic is fundamental to
                        strengthening your security posture. AWS WAF provides
                        comprehensive analytics and insights into your web
                        traffic patterns. This valuable information allows you
                        to continually identify potential threats, track user
                        behavior, and make data-driven decisions to refine and
                        optimize your security strategy.
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Ready />
      </div>
      <Footer />
    </div>
  );
};
export default ControlTower;
