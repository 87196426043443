import React from "react";
import Footer from "../../components/Footer/Footer";
import FSIAccelerate from "../../components/FSIcomponents/FSIAccelerate";
import FSIBenefits from "../../components/FSIcomponents/FSIBenefits";
import FSICarousel from "../../components/FSIcomponents/FSICarousel";
import FsiSolutions from "../../components/FSIcomponents/FsiSolutions";
import FSIHerosection from "../../components/HeroSection/FSIHerosection";
import Topmenu from "../../components/Topmenu/Topmenu";

const MigrationFSI = () => {
  return (
    <>
      <Topmenu />
      <FSIHerosection />
      <FsiSolutions />
      <FSIBenefits />
      <FSICarousel/>
      <FSIAccelerate />
      <Footer />
    </>
  );
};

export default MigrationFSI;
