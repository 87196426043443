import React, { useEffect } from 'react'
import Footer from '../../components/Footer/Footer'
import Topmenu from '../../components/Topmenu/Topmenu'

import { useNavigate } from 'react-router-dom'
import './Risingstar.css'
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

function RisingStarAward() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const img_link = process.env.REACT_APP_IMG_LINK
    const navigate = useNavigate()

    return (
        <div>


            <div>
                <Topmenu />

                <div className='rising_container' >

                    <div className='rising_title_main' >
                        <div> <h1 className='rising_title_head' >
                            Datamellon Awarded AWS SSA Rising
                        </h1>
                        </div>
                        <div>
                            <h1 className='rising_title_head' >Star Partner of the Year Award 2023</h1>
                        </div>
                    </div>
                    <div className='rising_star_img_con' >
                        <img src={`${img_link}/rising_star.png`} alt="rising_star_banner" className='rising_star_banner' style={{}} />
                    </div>

                    <div className='risingdate_con' >
                        <p className='rising_date' >December 1st, 2023</p>
                    </div>


                    <div className='risingdate_con_heading' >
                        {/* <p className='rising_sub_text' >AWS recognizes AWS Partners in Nigeria as leaders in helping customers drive innovation</p> */}
                        <p className='rising_sub_text'>AWS recognizes Datamellon as a leader in helping customers drive innovation</p>

                    </div>


                    <div className='content_con'  >

                        <div className='par_one' >
                            <p>[Lagos, Nigeria - November 28 , 2023]  Datamellon ltd is happy to announce that they have been recognized with the Rising Partner of the year  Award by Amazon Web Services, Inc. (AWS), an Amazon.com company for the 2023 Sub-Saharan Africa AWS Partner Award which recognizes Datamellon’s  key role in helping customers drive innovation and build solutions on AWS.</p>
                        </div>


                        <div className='par_one' >
                            <p>As a distinguished AWS Advanced Consulting Partner, the Rising Star Partner of the Year in Sub-Saharan Africa underscores our expertise which spans across a broad spectrum of cloud computing services, including cloud foundation, migration, and modernization. We specialize in cloud-native application development, Security & DevSecOps, Cloud Analytics, Artificial Intelligence (AI) & Machine Learning (ML), and Generative AI. With a significant focus on collaborative partnerships within the financial sector, Datamellon extends its transformative cloud technologies across diverse industries, leading the way in innovative solutions. </p>
                        </div>


                        <div className='par_one' >
                            <p>The cornerstone of Datamellon's success lies in our proficiency in AWS services. As an Advanced tier Consulting Partner, we demonstrate a high level of expertise and experience in delivering AWS solutions. Our knowledgeable team adheres to AWS best practices, ensuring clients not only benefit from cutting-edge technology but also receive solutions aligned with industry standards and optimized for performance, scalability, and cost-effectiveness </p>
                        </div>


                        <div className='par_one' >
                            <p>One of the key strengths of Datamellon is our client-centric approach, understanding the unique challenges and business needs of our clients. This tailored approach allows us to address specific pain points and contribute to the overarching goals of AWS customers. This is particularly evident in our work with top financial institutions, where precision is crucial. Datamellon's ability to comprehend the intricacies of the financial industry and deliver targeted solutions sets it apart in the competitive consulting landscape. </p>
                        </div>


                        <div className='par_one' >
                            <p>In the realm of emerging technologies, Datamellon takes a leading role, offering expertise in artificial intelligence, machine learning and generative AI. These capabilities position the company to assist clients in unlocking the full potential of their data, fostering innovation, and gaining a competitive edge in their respective markets. </p>
                        </div>

                        <div className='par_one' >
                            <p>Ultimately, Datamellon's success is not solely rooted in its technical capabilities but also in its commitment to building lasting partnerships with clients. By combining technical excellence with a profound understanding of business objectives, Datamellon empowers organizations to navigate the complexities of cloud adoption, achieve digital transformation, and drive innovation in their industries. </p>
                        </div>

                        {/* <div className='par_one' >
                            <p>Ultimately, Datamellon's success is not solely rooted in its technical capabilities but also in its commitment to
                                building lasting partnerships with clients. By combining technical excellence with a profound understanding of business objectives,
                                Datamellon empowers organizations to navigate the complexities of cloud adoption,
                                achieve digital transformation, and drive innovation in their industries. </p>
                        </div> */}



                    </div>


                    <div className='quote_con'>
                        <p className='quote_date'>
                            <FaQuoteLeft color='#0FB213' size={20} /> We must say that we are honored to be recognized as the AWS 2023 SSA Rising Star Partner of the Year. <br /> This prestigious award is a testament to our unwavering commitment to growth and vision for <br /> accelerated Cloud adoption and Digital innovation in Africa. At Datamellon, our teams consistently  <br />  strive to deliver
                            outstanding customer experiences with speed and agility, leveraging
                            AWS  <br /> technology to its fullest potential. As we embark on the
                            journey ahead, we are excited to continue <br />  our commitment to
                            innovation and delivery of cutting-edge solutions while leveraging
                            the power of <br />  GenerativeAI to shape the technological landscape for
                            our clients. <FaQuoteRight color='#0FB213' size={20} />
                        </p>

                        <p className='author' > Wale Adedeji, CEO of Datamellon.</p>
                    </div>


                    <div className='quote_con'>
                        <p className='quote_date'>
                            <FaQuoteLeft color='#0FB213' size={20} /> Datamellon seamlessly guided GTBank through the migration of GTWorld Mobile App to AWS. Their<br />expertise was instrumental in not just achieving over 60% cost-optimized solution, but also in <br />
                            enhancing scalability, security, and performance efficiency of the banking app which has <br /> empowered us to meet the dynamic demands of our banking services. Their expertise in <br />
                            implementing robust security measures has fortified our systems against potential threats, and the <br />
                            well architected cloud foundation ensures we can seamlessly scale while adapting to evolving  <br />  demands. Datamellon’s  post Go-Live Support coupled with comprehensive knowledge transfer, <br />

                            played a crucial role in ensuring a smooth transition and contributing to our success in the digital <br /> landscape. <FaQuoteRight color='#0FB213' size={20} />
                        </p>

                        <p className='author' > Olusina Ayegbusi, Group CIO GTBank</p>
                    </div>


                    <div className='bottom_content' >
                        <div className='content_con'    >

                            {/* <div className='par_one' >
                                <p>The AWS Partner Network (APN), is a global partner program, focused on helping partners build successful AWS-based businesses or solutions by providing business, technical, marketing, and go-to-market support. The APN includes Independent Software Vendors (ISVs) and Systems Integrators (SIs) around the world, with AWS Partner participation growing significantly during the past 12 months. </p>
                            </div>


                            <div className='par_one' >
                                <p> "AWS partners play a vital role in addressing the business needs of customers across industries, driving both influence and impact at scale" says Vittorio Sanvito, Director, EMEA Partner Development. “This year's Partner Award winners should be proud of the contribution they are making in accelerating our customers' cloud transformation journey. We look forward to strengthening our partnership in 2024".</p>
                            </div> */}





                        </div>
                    </div>


                </div>

                <Footer />
            </div>
        </div>
    )
}

export default RisingStarAward