import React from 'react'

function Button({text, color, bg, style, classname}) {
  return (
    <div>
<button className={classname} >{text}</button>

    </div>
  )
}

export default Button