import React from "react";
import { Link } from "react-router-dom";
import "./events.css";
import abuja from '../../assets/images/abuja-small.png'
import devjam from '../../assets/images/devjam.png'
import cto from '../../assets/images/cto.png'
import gtb from '../../assets/images/gtb.png'
import cloud from '../../assets/images/cloudtransformation.png'
import data from '../../assets/images/data.png'
import fxchallenge from "../../assets/images/fxchallenge.svg"

const EventList = () => {
  const eventList = [
    {
      img: fxchallenge,
      heading: "Navigating FX Challenge: Practical FinOps Strategies for Cloud Cost",
      link: "",
      linkText: "",
    },
    {
      img: abuja,
      heading: "Datamellon Summit: Public Sector Digital Innovation Summit",
      // link: "/events/public-sector",
      link: "",
      linkText: "View Event",
    },
    {
      img: devjam,
      heading: "Datamellon Summit: Lagos Startup Week (Dev Jam)",
      link: "/lsw-devjam",
      linkText: "View Event",
    },
    {
      img: cto,
      heading: "Datamellon Summit: CTO Mixer",
      link: "",
      linkText: "",
    },
    {
      img: gtb,
      heading: "Datamellon Summit: GT Immersion Day",
      link: "",
      linkText: "",
    },
    {
      img: cloud,
      heading:
        "Datamellon Summit: Public Sector, Cloud-Powered digital Transformation with AWS",
      link: "",
      linkText: "",
    },
    {
      img: data,
      heading: "Datamellon Summit: Data Transformation Day",
      link: "",
      linkText: "",
    },
    

  ];
  return (
    <div className="eventlist__container" style={{

    }} >
      <div className="eventlist__heading" style={{
        marginTop: '150px'
      }}  >
        <h3>Events & Webinar</h3>
        <p>Upcoming and Past Events</p>
      </div>
      <div className="eventlist__main">
        {eventList.map((event) => (
          <div
            key={event.heading}
            className="oneevent__container"
          >
            <img src={event.img} alt={event.heading} />
            <div className="oneevent__container--text">
              <p>{event.heading}</p>
              <Link to={event.link}>{event.linkText}</Link>
            </div>

          </div>
        ))}
      </div>
    </div>
  );
};

export default EventList;
