import Input from '@mui/material/Input';
import React from 'react'
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: "#75b748",
    },
  },
});

function InputField({id, label, type,onChange, endAdornment, error  }) {
  return (
    <ThemeProvider theme={theme}>
    <>
        <FormControl variant="standard" fullWidth size='small'>
            <TextField 
                label={label} 
                type={type}
                id={id}
                endAdornment = {endAdornment}
                error = {error}
                onChange = {onChange}
            />
        </FormControl>
    </>
    </ThemeProvider>
  )
}

export default InputField
