import React from "react";
import "./Aws_compe.css";
import { useNavigate } from "react-router-dom";
import aws from "../../assets/images/aws2.jpeg";

function Aws_compe() {
  const img_link = process.env.REACT_APP_IMG_LINK;
  const navvigate = useNavigate();

  return (
    <div className="aws_comp_main">
      <div
        className="awsfull"
        style={{ cursor: "pointer" }}
        onClick={() => navvigate(`${"/competences"}`)}

      >
        {/* console.log(hello, 'hello') */}
        <div className="aws_lcomp">
          <div className="lcomp_items">
            <span>
              AWS
              <span>
                <img
                  className="comp"
                  src={`${img_link}/Competence_icon.svg`}
                  alt="APN Logo"
                />
              </span>
              Competences
            </span>
          </div>
        </div>
        <div className="aws_rcomp">
          <div className="rcomp_card">
            <img src={aws} alt="serve-icon" className="rcomp__image" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aws_compe;