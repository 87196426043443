import React from 'react'
import './events.css'
import LatestEvent from './LatestEvent'
import { Flex } from 'antd'
import envelope from '../../assets/images/envelope.png'

const EventHeroSection = () => {
  return (
    <div className='eventhero__container' style={{
      marginBottom: "300px"
    }}  >
      <div className="eventhero__description" style={{
        marginTop: "500px"
      }} >
        <h2>Events</h2>
        <p>Get updated on the most recent developments in the <br /> industry, including news, interviews, cutting-edge <br /> technologies, and valuable resources.</p>


        {/* <div class="custom-input" style={{
          marginBottom: '100px'
        }} >
          <input type="email" placeholder="Enter your email" />
          <button type="submit">Subscribe</button>
        </div> */}

        {/* <div class="input-container" style={{
          marginBottom: '30px',
          marginTop: "30px"
        }} >
          <span class="icon"><img src={envelope} alt="Icon" /></span>
          <input type="text" class="input-field" placeholder="Enter your text" />
          <button type="submit" class="button">Button</button>
        </div> */}

        <div className="input-container"

          style={{
            marginBottom: '30px',
            marginTop: "30px"
          }}
        >
          <span className="icon"><img src={envelope} alt="Icon" /></span>
          <input type="text" className="input-field" placeholder="Enter your work email address" />
          <button type="submit" className="button">Subscribe</button>
        </div>







        <div className='' style={{
          // marginTop: '200px',
          // width: "100%",
          // maxWidth: "100%"
        }} >
          <LatestEvent />
        </div>

      </div>




    </div>
  )
}

export default EventHeroSection