import React from 'react'
import CustomBtn from '../Button/CustomButton'
import './Services_card.css'

const ServicesCard2 = ({ title, subtitle,img, onClick }) => {
  return (
    <div className='serv_card2'>
      <div style={{width:'100%', display:"flex", gap:'15px'}}>
        <img src={img} className="card_images"/>
        <h3>{title}</h3>
      </div>
      
      <span>{subtitle}</span>
      <CustomBtn
        type='button'
        txtColor='var(--main)'
        pd='4px'
        br='10px'
        fs='14px'
        w='150px'
        bg='white'
        hoverBG='whitesmoke'
        txt='Learn More'
        fw='700'
        border="1px solid var(--main)"
        onClick={onClick} />
    </div>
  )
}

export default ServicesCard2
