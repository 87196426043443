import React, {useEffect} from 'react'
import './WhyDatamellon.css'
import Topmenu from '../../components/Topmenu/Topmenu'
import Footer from '../../components/Footer/Footer'
import WhyHero from '../../components/WhyHero/WhyHero'
import WhyAdv from '../../components/WhyAdv/WhyAdv'

function WhyDatamellon() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Topmenu/>
      <WhyHero/>
      <WhyAdv/>
      <Footer/>
    </div>
  )
}

export default WhyDatamellon