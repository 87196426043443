import React, {useEffect} from 'react'
import Footer from '../../components/Footer/Footer'
import Topmenu from '../../components/Topmenu/Topmenu'
import Partnercmp from '../../Partnercomponents/Partnercmp'
import './Partners.css'

function Partners() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
     <Topmenu/>
     <Partnercmp/> 
     <Footer/>  
    </div>
  )
}

export default Partners