import React from 'react'
import "./Partnercmp2.css"
import { useNavigate } from 'react-router-dom';


export default function Partnercmp2({ partheader, webimage, text_a, text_b, mobimage,url }) {
    const navigate = useNavigate();
    return (
        <div className='mainpartner'>
            <div className="maincontainpartner">
                <div className='home_max_width'>
                <div className='containerpt'>
                    <div className="leftpartnersection">
                        <div className="partner_icon">
                        {/* <a style={{textDecoration:'none',color: "#75B748"}} href={url}  target="_blank" rel="noopener noreferrer">
                            <img src={webimage} alt="partner-icon" style={{}} />
                            

                            </a> */}

{
                    url === '/aws' ?
                        <img src={webimage} alt="partner-icon" style={{cursor: 'pointer' }} onClick={() => navigate(url)} />

                        :
                        <a style={{cursor: 'pointer'}} href={url} target="_blank" rel="noopener noreferrer">
                            <img src={webimage} alt="partner-icon" />
                        </a>
                }
                        </div>

                    </div>
                    <div className="rightpartnersection">

                        <div className="partner-text">
                            <div className="text-topicptn">
                                {partheader}
                                <div className="mobviewicon">
                                    <div className="partner_icon2">
                                    <a style={{textDecoration:'none',color: "#75B748"}} href={url}  target="_blank" rel="noopener noreferrer">
                                        <img src={mobimage} alt="partner-icon2" style={{}} />

                                        </a>
                                    </div>
                                </div>

                            </div>
                            {/* <div className="mobviewicon">
                                <div className="partner_icon2">
                                    <img src={mobimage} alt="partner-icon2" style={{}} />
                                </div>
                            </div> */}
                            <div className="textnormal">
                                <div className="partnertext-info"><p>{text_a}</p>
                                </div>
                                <div className="partnertext-info2"><p>{text_b}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>

        </div>
    )
}
