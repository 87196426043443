import React, { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";
import Topmenu from "../../../components/Topmenu/Topmenu";
import providus from "../../../assets/images/Providus_Bank_logo.png";
import "./Cstudy1.css";

const CrcCaseStudy = () => {
  const img_link = process.env.REACT_APP_IMG_LINK;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topmenu />
      <div className="payday__container">
        <div className="payday__overview flex">
          <div className="payday__overview--content">
            <h3 className="cstudytopic">Overview</h3>
            <p className="cstudynormtxt2">
              CRC Credit Bureau provides a nationwide information repository on
              credit profiles of consumers and corporate entities, thus
              improving the ability of credit providers to make informed lending
              decisions ranging from customer acquisition, credit analysis,
              monitoring, skip tracing, and recovery.
            </p>
          </div>
          <div className="payday__overview--img">
            <img src={`${img_link}/crc.png`} alt="CRC Logo" />
            {/* src={`${img_link}/crc.png`} */}
          </div>
        </div>

        <div className="payday__opportunity">
          <h3 className="cstudytopic">Opportunity</h3>
          <p className="cstudynormtxt2">
            As CRC Credit Bureau approached the end-of-life of its hardware
            infrastructure, it took the decision to migrate its applications and
            data to the cloud which offers numerous advantages beyond addressing
            the immediate hardware limitations. Here's an expanded list of
            benefits and advantages: Infinite Scalability, Pay-as-You-Go
            Pricing, Improved Security, Enhanced Reliability, Optimal
            Performance, Global Reach, Scalable Storage Innovation and Agility.
            Overall, migrating to the cloud offers CRC Credit Bureau the
            opportunity to modernize its IT infrastructure, improve operational
            efficiency, and unlock new capabilities for innovation and growth.
            By harnessing the benefits of cloud computing, the organization can
            better serve its growing customer base while remaining agile and
            competitive in a rapidly evolving business landscape.
          </p>
        </div>

        <div className="payday__opportunity">
          <h3 className="cstudytopic">The Challenge</h3>
          <p className="cstudynormtxt2">
            CRC Credit Bureau, a leading player in the financial services
            industry, was confronted with the end-of-life of its hardware. At
            the same time the company experienced a surge in data processing
            requirements and user traffic, its existing infrastructure struggled
            to maintain optimal performance levels. This bottleneck not only
            risked customer satisfaction but posed a significant impediment to
            CRC's growth trajectory.
            <br />
            To address this pressing challenge, CRC sought a robust solution
            that seamlessly accommodated its escalating workload demands while
            ensuring reliability, scalability, and performance. Recognizing the
            major need for a comprehensive cloud infrastructure, CRC turned to
            industry leader Amazon Web Services (AWS) for a transformative
            solution that could propel its operations to new heights of
            efficiency and agility.
          </p>
        </div>

        <div className="payday__aws">
          <h3 className="cstudytopic">Why AWS</h3>
          <p className="cstudynormtxt2">
            AWS was the natural choice for CRC due to its reputation as a leader
            in cloud computing services. With a comprehensive suite of tools and
            global infrastructure, AWS offered CRC the ideal platform to
            modernize operations and achieve scalability and performance.
            <br />
            AWS's elastic infrastructure allowed CRC to scale resources in
            response to demand, ensuring optimal performance and
            cost-efficiency. Its reliable, globally distributed data centers
            guaranteed uninterrupted service, while robust security measures
            provided peace of mind for sensitive data.
            <br />
            Flexibility in services and pricing allowed CRC to tailor its cloud
            environment, and access to expert support via the AWS Partner
            Network ensured a smooth migration and ongoing operational
            excellence.
          </p>
        </div>

        <div className="payday__datamellon">
          <h3 className="cstudytopic">Why Datamellon</h3>
          <p className="cstudynormtxt2">
            Datamellon is an AWS Advanced Consulting Partner specializing in
            Generative AI, Cloud migration, DevOps, Data Analytics, Artificial
            Intelligence (AI), and Machine Learning (ML). Datamellon has
            extensive experience working with financial institutions and helping
            them leverage cloud technologies to transform their businesses.
            Datamellon has a proven track record of delivering successful
            projects for its clients, such as GTBank, using agile methodologies
            and best practices.
          </p>
        </div>

        <div className="cstudysolution">
          <p>
            <div className="cstudytopic">Partner Solution</div>
            <div className="cstudynormtxt">
              In response to CRC Credit Bureau's migration needs, Datamellon, an
              AWS Advanced Consulting Partner, offered a comprehensive suite of
              professional services tailored to ensure a seamless transition to
              the AWS. Leveraging their expertise and experience, Datamellon
              delivered a robust solution encompassing the following key
              components:
            </div>
          </p>
          <div className="icon_text_sub_cont">
            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">AWS Cloud Foundation:</div>
                <div className="cstudynormtxt2">
                  Datamellon designed and set up a robust cloud foundation and
                  Control Tower Landing Zone, ensuring a secure and compliant
                  AWS environment. DevOps practices were implemented across all
                  environments, and networking architecture, including VPC
                  design and implementation, was meticulously crafted to
                  optimize performance and security. Cloud governance and
                  security guardrails were established to enforce compliance
                  standards and mitigate risks effectively.
                </div>
              </div>
            </div>

            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Automated CI/CD Pipelines:</div>
                <div className="cstudynormtxt2">
                  Datamellon implemented automated CI/CD pipelines for
                  infrastructure and application deployments across different
                  environments. This streamlined the development and deployment
                  processes, enhancing efficiency, reducing errors, and
                  accelerating time-to-market for CRC's applications and
                  services.
                </div>
              </div>
            </div>
            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Migration Services:</div>
                <div className="cstudynormtxt2">
                  Datamellon facilitated the migration of CRC's application and
                  web servers to the AWS Cloud using AWS Migration services such
                  as Application Migration Service (MGN) and Migration Tool,
                  ensuring a smooth and efficient transition with minimal
                  disruption to operations.
                </div>
              </div>
            </div>

            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">
                  Integration with On-premise Database:
                </div>
                <div className="cstudynormtxt2">
                  Datamellon successfully connected CRC's workloads in AWS to
                  their on-premise database, enabling seamless data access and
                  synchronization between cloud and on-premise environments.
                </div>
              </div>
            </div>
            <p
              style={{
                marginTop: "35px",
              }}
            >
              Datamellon utilized a range of AWS services to deliver these
              solutions, including AWS Control Tower and Landing Zone setup for
              multi-account provisioning and controls, AWS CodeCommit for
              version control of infrastructure code, AWS CodePipeline for CI/CD
              pipeline orchestration, AWS CodeBuild for source code compilation,
              Amazon EC2 for hosting application and web servers, AWS IAM for
              identity management, AWS Key Management System for security and
              encryption, AWS Security Hub for security orchestration, AWS S3
              for object storage, and AWS VPC for virtual network environments.
            </p>
          </div>
        </div>

        <div className="cstudybenefits">
          <div className="cstudytopic">Outcome</div>
          <div
            className="cstudynormtxt"
            style={{
              marginBottom: "20px",
            }}
          >
            The solutions provided by Datamellon yielded transformative outcomes
            for CRC Credit Bureau, revolutionizing its operations and
            positioning the company for sustainable growth and innovation. Here
            are the key outcomes of the implemented solutions:
          </div>
          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Enhanced Scalability and Performance:
              </div>
              <div className="cstudynormtxt2">
                With the migration of CRC's workloads to the AWS Cloud and the
                implementation of scalable infrastructure and automated CI/CD
                pipelines, CRC experienced a significant enhancement in
                scalability and performance. The elastic nature of AWS resources
                allowed CRC to effortlessly scale its infrastructure in response
                to fluctuating demand, ensuring optimal performance during peak
                periods and cost-efficiency.
              </div>
            </div>
          </div>
          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Improved Reliability and Resilience:
              </div>
              <div className="cstudynormtxt2">
                By leveraging AWS's globally distributed data centers and robust
                infrastructure, CRC achieved enhanced reliability and resilience
                for its critical workloads. Establishing a cloud foundation and
                Control Tower Landing Zone, coupled with robust networking
                architecture and security guardrails, ensured uninterrupted
                access to services and data, even in the face of unforeseen
                disruptions.
              </div>
            </div>
          </div>

          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Streamlined Development and Deployment Processes:
              </div>
              <div className="cstudynormtxt2">
                Implementing automated CI/CD pipelines for infrastructure and
                application deployments streamlined CRC's development and
                deployment processes, significantly reducing manual efforts,
                minimizing errors, and accelerating time-to-market for new
                features and updates. This agility enabled CRC to respond
                rapidly to evolving customer needs and market dynamics, driving
                innovation and competitive advantage.
              </div>
            </div>
          </div>

          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Improved Security and Compliance:{" "}
              </div>
              <div className="cstudynormtxt2">
                With the establishment of cloud governance and security
                guardrails, coupled with AWS's advanced security services such
                as IAM, Key Management System, and Security Hub, CRC bolstered
                its security posture and achieved compliance with industry
                regulations and standards. Data encryption, identity management,
                and security orchestration mechanisms provided comprehensive
                protection against potential threats and vulnerabilities,
                instilling confidence in CRC's customers and stakeholders.
              </div>
            </div>
          </div>
          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Seamless Integration with On-premise Systems:
              </div>
              <div className="cstudynormtxt2">
                The successful connection of CRC's workloads in AWS to its
                on-premise database facilitated seamless data access and
                synchronization between cloud and on-premise environments. This
                integration enabled CRC to leverage the scalability and
                flexibility of the cloud while preserving investments in
                existing on-premise infrastructure, ensuring a smooth transition
                and uninterrupted business operations.
              </div>
            </div>
          </div>
          {/* <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Improved User Experience and Reliability:
              </div>
              <div className="cstudynormtxt2">
                The collaborative solution addressed immediate challenges and
                contributed to an improved user experience. The reliability of
                the AWS infrastructure, coupled with the scalability of the API
                Gateway and serverless architecture, ensure uninterrupted
                services during peak periods, enhancing customer satisfaction.
              </div>
            </div>
          </div> */}
        </div>

        <div
          className="cstudybenefits"
          style={{
            marginTop: "40px",
          }}
        >
          <div className="cstudytopic">Next Steps</div>
          <div className="cstudynormtxt">
            In partnering with Datamellon and leveraging the advanced
            capabilities of Amazon Web Services (AWS), CRC Credit Bureau
            successfully addressed its scalability challenges. The seamless
            migration to the AWS Cloud, automated CI/CD pipelines, and robust
            security measures enabled CRC to enhance scalability, reliability,
            and security while streamlining development and deployment
            processes. The outcome is a transformed operational landscape
            characterized by enhanced agility, resilience, and competitiveness.
            With a solid foundation, CRC is poised to capitalize on emerging
            opportunities, drive continued growth, and deliver unparalleled
            value to its customers in the dynamic financial services sector.
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CrcCaseStudy;
