import React, {useEffect} from 'react'
import './CareersPage.css'
import Topmenu from '../../components/Topmenu/Topmenu'
import Footer from '../../components/Footer/Footer'
import CareerHero from '../../components/CareerHero/CareerHero'
import CareerCard from '../../components/CareerCard/CareerCard'
import MeetTeam from '../../components/MeetTeam/MeetTeam'

function CareersPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Topmenu/>
      <CareerHero/>
      <CareerCard/>
      <MeetTeam/>
      <Footer/>
    </div>
  )
}

export default CareersPage