import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';



function CustomBtn({type,border, txtColor,pd, br,fs, w, bg, hoverBG,txt, fw, onClick,txtside,jcont,pleft,fm, mw}) {

   const ColorButton = styled(Button)(() => ({
    color: txtColor,
    backgroundColor: bg, 
    borderRadius:br,
    width: w,
    maxWidth:mw,
    padding:pd,
    fontWeight:fw,
    textTransform:"none",
    fontSize:fs,
    border:border,
    textAlign:txtside,
    justifyContent:jcont,
    paddingLeft:pleft,
    fontFamily:fm,
    textDecoration:"none",
    
    
    '&:hover': {
       background: hoverBG,
    },
  }));

    return (
           <ColorButton type={type} disableElevation variant="contained" onClick={onClick}> {txt}</ColorButton>
    )
}

export default CustomBtn