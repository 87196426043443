import React, {useState, useEffect} from 'react'
import './ImageSwitcher.css'
import Header_illustration_2 from '../../assets/images/Header_illustration_2.svg';
import Header_illustration from '../../assets/images/Header_illustration.svg';

export default function ImageSwitchV2() {
    const [index, setindex] = useState(0);
    const img_link = process.env.REACT_APP_IMG_LINK
   
    const switches = [
        {
            images: `${img_link}/Header_illustration_1.svg`,
        },
        {
            images: `${img_link}/Header_illustration_2.svg`,

        },
        {
            images: `${img_link}/Header_illustration_3.svg`,

        },
        {
            images: `${img_link}/Header_illustration_4.svg`,

        },
       
    ]

    const switchText = (i)=> {
        setTimeout(() => {
            if(i === (switches.length - 1)){
                setindex(0)
            }
            else{
                setindex(i + 1)   
            }
        }, 5000)
    }

    useEffect(() => {
        switchText(index)
      }, [index])
      
  return (
    // <div className="image-context">
    //     <img data-aos="fade-right" src={switches[index]?.images} alt="Heroimage" className='heroimage' style={{}} /> 
    // </div>
    <div className="image-context">
        <img data-aos="fade-right" src={switches[index]?.images} alt="Heroimage" className='heroimage' style={{}} /> 
    </div>
  )
}
