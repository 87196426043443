import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Topmenu from "../../components/Topmenu/Topmenu";
import Overcenterhead from "../../components/OverLayHeader/Overcenterhead";
import Compe_card from "../../components/Comp_card/Compe_card";
import Readstrt from "../../components/Readstrt/Readstrt";
import "./Competence.css";
import Ready from "../../components/Ready/Ready";
import aws from "../../assets/images/aws2.jpeg";

const Competences = () => {
  // const img_link = process.env.REACT_APP_IMG_LINK;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const img_link = process.env.REACT_APP_IMG_LINK;

  return (
    <div>
      <Topmenu />
      <Overcenterhead
        HeaderBG={`url("${img_link}/Competencebg.svg")`}
        pretitle="20+"
        title="  AWS Certifications"
        subtitle="AND COUNTING"
      />
      <div className="offsetAwsImgCon">
        {/* <img src={`${img_link}/Consulting_img.svg`} alt="" /> */}
        <img src={aws} alt="serve-icon" className="rcomp__image" />
      </div>

      <Compe_card />
      <Ready />
      {/* <Readstrt/> */}

      <Footer />
    </div>
  );
};

export default Competences;
