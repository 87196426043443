import React from 'react'
import "./FlexImageText.css"

const FlexImageText = ({bgColor,paragraph, serviceImage}) => {
    return (
        <div className='textFlexImage' style={{backgroundColor: bgColor}}>
            <div className="home_max_width">
                <div className="textFlexBody">
                    <div className="textflexx">
                        {
                            paragraph.map((para, index)=>(
                                <p key={index} className="subtitleTag">
                                {para}
                            </p>
                            ))
                        }
                    </div>
                    <div className="imageFlexx">
                        <img src={serviceImage} alt="" style={{ borderRadius: '20px' }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FlexImageText
