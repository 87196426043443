import React from 'react'
import './DiscoverCard.css'
import { useNavigate } from 'react-router-dom';

export default function DiscoverCard({icon, text,linkup}) {

  const navigate = useNavigate();
  return (
    <div className='DiscoverCardCon'>
        <div > <img src={icon} alt="" /></div>
        <div>{text}</div>
    </div>
  )
}
