import React from "react";
import "./fsi.css";

const FSIAccelerate = () => {
  const pdfUrl =
    "https://drive.google.com/uc?export=download&id=1PvE15JEDXNkrMPXGD68qDNqZUkXUzUsi";
  return (
    <div className="fsiaccelerate__container">
      <div className="fsiaccelerate__text">
        <h4>Ready to accelerate your FSI’s Migration Journey?</h4>
        <p>
          Embark on your FSI's migration and modernization journey with
          Datamellon. Let's work together to unlock your FSI's full potential
          and thrive in the digital age.
        </p>

        <a
          href={pdfUrl}
          target="_blank"
          rel="noopener noreferrer"
          download
          style={{
            textDecoration: "none",
          }}
        >
          <button>Get Started!</button>
        </a>
      </div>
    </div>
  );
};

export default FSIAccelerate;
