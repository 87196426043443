import React from 'react'
import './CloudMigration.css'
const Approach = () => {
    return (
        <div classname="approachcontainer">
            <div className="home_max_width">
                <div className='mainaproach'>
                    <div className="approachhead"> Our Approach</div>
                    <div className='approachbody'>
                        <div className='apprbody1'>
                            <div className='apprbodytopic'>
                                Strategy & Assessment
                            </div>
                            <div className='apprbodytext'>
                                <ul className='overb2d2'>
                                    <li className='bodytxt'>Conduct a holistic assessment of your IT environment and evolve a secure, unbiased and successful roadmap to the cloud.</li>
                                    <li className='bodytxt'>Catalog your existing applications, infrastructure and network architecture to help prioritise workloads and applications.</li>
                                    <li className='bodytxt'>Determine which cloud platform(s) fit your overall transformation objectives.</li>
                                    <li className='bodytxt'>Design a high-level target infrastructure and cloud platform architecture that accommodates your security and risk requirements.</li>
                                    <li className='bodytxt'>Assess your existing workloads and predict future cloud consumption to estimate your costs.</li>
                                    <li className='bodytxt'>Assess your existing workloads and predict future cloud consumption to estimate your costs.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='apprbody2'>
                            <div className='apprbodytopic'>
                                Implementation
                            </div>
                            <div className='apprbodytext'>
                                <ul className='overb2d2'>
                                    <li className='bodytxt'>Move the right applications to the right cloud.</li>
                                    <li className='bodytxt'>Through our cloud migration workshops, we’ll work with you to identify which applications should be migrated to the cloud, and develop a high-level plan.</li>
                                    <li className='bodytxt'>Build confidence in a manageable and cost-effective cloud migration by starting with a single, meaningful workload.</li>
                                    <li className='bodytxt'>Our experts will handle your application migration and remediation.</li>
                                    <li className='bodytxt'>We’ll work with you to validate your applications, data and network accessibility, and perform cut-over and go-live procedures.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Approach