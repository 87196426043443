import { Grid } from '@mui/material'
import React, {useEffect} from 'react'
import CustomBtn from '../../components/Button/CustomButton'
import Footer from '../../components/Footer/Footer'
import InputField from '../../components/InputField/InputField'
import Topmenu from '../../components/Topmenu/Topmenu'

const JoinUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
            <Topmenu/>
            <div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '100%', maxWidth: '600px', padding: '25px', paddingTop:'0' }}>
                    <div className="titleTag" style={{ textAlign: 'center', padding: '30px' }}>
                        Join us
                    </div>
                    <form style={{ width: '100%' }} >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <InputField
                                    type="text"
                                    name="fullname"
                                    id="fullname"
                                    label="Fullname"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    type="text"
                                    name="email"
                                    id="email"
                                    label="Email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    type="text"
                                    name="portfolio"
                                    id="portfolio"
                                    label="Portfolio link(Optional)"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    type="text"
                                    name="resume"
                                    id="resume"
                                    label="Resume link(Optional)"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    type="text"
                                    name="fullname"
                                    id="fullname"
                                    label="Enter message here"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomBtn
                                    type='submit'
                                    txtColor='white'
                                    pd='5px'
                                    br='8px'
                                    fs='18px'
                                    w='100%'
                                    bg='var(--main)'
                                    hoverBG='var(--main)'
                                    txt='Send'
                                    fw='500'
                                    border="1px solid var(--main)"
                                    onClick={() => { }} />
                            </Grid>
                        </Grid></form>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default JoinUs
