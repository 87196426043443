import React, { useEffect, useState } from 'react'
import './Circular_Image.css'
import ServicesCard from './ServicesCard'
import Services from './Services.svg'
import ServicesCard2 from './ServicesCard2'
import { useNavigate } from 'react-router-dom'


const CircularImage = () => {
    const navigate = useNavigate()
    const img_link = process.env.REACT_APP_IMG_LINK
    const [screenSize, setScreenSize] = useState(undefined)
    useEffect(() => {

        const handleResize = () => setScreenSize(window.innerWidth);

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // useEffect(() => {
    //     if (screenSize <= 900) {
    //         setActiveMenu(false);
    //     } else {
    //         setActiveMenu(true);
    //     }
    // }, [screenSize]);

    if (screenSize === undefined) {
        return (<>loading..</>)
    }

    return (
        <>
            {
                screenSize <= 1200 ?
                    <div className='circle-wrapper-mobile'>
                        <ServicesCard2 
                            img={`${img_link}/Cloud_foundation_icon.svg`}
                            title="Cloud Foundation"
                            subtitle="Build and automate designed platform architecture with speed into secure,     scalable and cost optimized cloud infrastructure platforms."
                            onClick={() => navigate("/cloud-foundation")}
                        />
                        <ServicesCard2 
                            img={`${img_link}/Cloud_migration.svg`}
                            title="Cloud Migration"
                            subtitle="Migrate application workloads onto built cloud platforms, re-architect and modernise applications throughout the process."
                            onClick={() => navigate("/cloud-migration")}
                        />
                        <ServicesCard2 
                            img={`${img_link}/Security_DevSecOps_services.svg`}
                            title="Security & DevSecOps"
                            subtitle="Improve and modernise your approach to IT security, shifting application and platform security concerns earlier in the development lifecycle and increasing the use of automation."
                            onClick={() => { }}
                        />
                        <ServicesCard2 
                            img={`${img_link}/Cost_management _ optimization.svg`}
                            title="Cost Management & Optimization"
                            subtitle="Continuously analyze consumption trends against budgets and get maximum business value with data-driven spending decision."
                            onClick={() => { }}
                        />
                        <ServicesCard2 
                            img={`${img_link}/Application_modern.svg`}
                            title="Application Modernisation with Kubernetes"
                            subtitle="Deliver and manage containerized, legacy, and cloud-native applications, as well as those being refactored into microservices with Kubernetes."
                            onClick={() => { }}
                        />
                        <ServicesCard2 
                            img={`${img_link}/Cloud-native_Application.svg`}
                            title="Cloud-Native Application Development"
                            subtitle="Improve time-to-market with cloud-native application and products to maximise the game-changing benefits of the cloud. "
                            onClick={() => { }}
                        />
                        <ServicesCard2 
                            img={`${img_link}/Data analytics _ Machine learning.svg`}
                            title="Data Analytics & Machine Learning"
                            subtitle="Build big data, analytics, insight and AI/ML by leveraging cloud platforms and data capabilities. "
                            onClick={() => { }}
                        />
                       
                    </div>
                    :
                    <div className="circle-wrapper-desktop">
                        <div style={{
                            width: '100%',
                            height: '100%',
                            // backgroundColor:'lightblue',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <img src={Services} alt='services' />
                        </div>
                        <div className="circle degree-1">
                            <ServicesCard
                                title="Cloud-Native Application Development"
                                subtitle="Improve time-to-market with cloud-native application and products to maximise the game-changing benefits of the cloud. "
                                onClick={() => { }}
                            />
                        </div>
                        <div className="circle degree-2">
                            <ServicesCard
                                title="Application Modernisation with Kubernetes"
                                subtitle="Deliver and manage containerized, legacy, and cloud-native applications, as well as those being refactored into microservices with Kubernetes."
                                onClick={() => { }}
                            />
                        </div>
                        <div className="circle degree-3">
                            <ServicesCard
                                title="Cost Management & Optimization"
                                subtitle="Continuously analyze consumption trends against budgets and get maximum business value with data-driven spending decision."
                                onClick={() => { }}
                            />
                        </div>
                        <div className="circle degree-4">
                            <ServicesCard
                                title="Security & DevSecOps "
                                subtitle="Improve and modernise your approach to IT security, shifting application and platform security concerns earlier in the development lifecycle and increasing the use of automation."
                                onClick={() => { }}
                            />
                        </div>
                        <div className="circle degree-5">
                            <ServicesCard
                                title="Cloud Migration"
                                subtitle="Migrate application workloads onto built cloud platforms, re-architect and modernise applications throughout the process."
                                onClick={() => navigate("/cloud-migration")}
                            />
                        </div>
                        <div className="circle degree-6">
                            <ServicesCard
                                title="Cloud Foundation"
                                subtitle="Build and automate designed platform architecture with speed into secure, scalable and cost optimized cloud infrastructure platforms."
                                onClick={() => navigate("/cloud-foundation")}
                            />
                        </div>
                        <div className="circle degree-7">
                            <ServicesCard
                                title="Data Analytics & Machine Learning"
                                subtitle="Build big data, analytics, insight and AI/ML by leveraging cloud platforms and data capabilities. "
                                onClick={() => { }}
                            />
                        </div>
                    </div>

            }


        </>
    )
}

export default CircularImage
