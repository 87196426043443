import React from "react";
import "./fsi.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";

const FSICarousel = () => {
  const img_link = process.env.REACT_APP_IMG_LINK;

  return (
    <div className="fsicarousel__container">
      <h2>Our Customers</h2>
      <div className="fsiswiper__parent">
        <Swiper
          slidesPerView={2}
          spaceBetween={50}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          //   centeredSlides={true}
          modules={[Autoplay]}
          className="swiper__image"
        >
          <SwiperSlide>
            <img
              src={`${img_link}/gtb-logo.png`}
              alt="Financial Institution"
              className="swiper__gtb"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={`${img_link}/Providus_Bank_logo.png`}
              alt="Financial Institution"
              className="swiper__providus"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={`${img_link}/carousel-trove.png`}
              alt="Financial Institution"
              className="swiper__trove"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={`${img_link}/carousel-fincra.png`}
              alt="Financial Institution"
              className="swiper__fincra"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={`${img_link}/crc.png`}
              alt="Financial Institution"
              className="swiper__crc"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={`${img_link}/stanbic.png`}
              alt="Financial Institution"
              className="swiper__stanbic"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default FSICarousel;
