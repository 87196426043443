import React from 'react'
import './StoryHero.css'

const StoryHero = () => {
    return (
        <div className='storyDevContainer'>
            <div className="home_max_width">
                <div className="storyDev">
                    <div className="storyDevtext">
                        <h2 className='titleTag'>
                        How does Datamellon work?
                        </h2>
                        <p className="subtitleTag">
                        Datamellon are a trusted partner on Vodafone's cloud adoption journey. The skills from their team have helped accelerate the use AWS within our global organisation. Datamellon are a trusted partner on Vodafone's cloud adoption journey. The skills from their team have helped accelerate the use of AWS within our global organisation. Datamellon are a trusted partner on Vodafone's cloud adoption journey. The skills from their team have helped accelerate the use of AWS within our global organisation. Datamellon are trusted partner on Vodafone's cloud adoption journey. The skills from their team have helped accelerate the use of AWS within our global organisation.
                        </p>
                    </div>
                    <div className="storyDevtext">
                        <h2 className='titleTag'>
                        Datamellon Facts
                        </h2>
                        <p className="subtitleTag">
                        Vodafone Group Public Cloud Services(PCS) team manages the global public cloud estate of Vodafone all over the world. Business divisions and the local market’s journey to AWS cloud starts with an AWS account to be provisioned, configured and hardened with security baselines and guardrails by the PCS team. The team needs to evolve an innovative self-service solution to enable everyone in Vodafone wherever they are in the world to create an AWS account within minutes.
                        </p>
                    </div>
                    <div className="storyDevtext_full">
                        <h2 className='titleTag'>
                        What matters to us
                        </h2>
                        <div className="wat_matter">
                            <div className="card_div_card">
                                <div className="card_d_head">
                                Our clients
                                </div>
                                <div className="card_d_text">
                                We understand the importance of approaching each work integrally, thereby delivering solutions that meets our clients’ need. We understand the importance of approaching integrally, thereby delivering solutions that meets our clients’ need.
                                </div>

                            </div>
                            <div className="card_div_card_mid">
                                <div className="card_d_head">
                                Our employees
                                </div>
                                <div className="card_d_text">
                                We understand the importance of approaching each work integrally, thereby delivering solutions that meets our clients’ need. We understand the importance of approaching integrally, thereby delivering solutions that meets our clients’ need.
                                </div>

                            </div>
                            <div className="card_div_card_mid">
                                <div className="card_d_head">
                                Our shareholders
                                </div>
                                <div className="card_d_text">
                                We understand the importance of approaching each work integrally, thereby delivering solutions that meets our clients’ need. We understand the importance of approaching integrally, thereby delivering solutions that meets our clients’ need.
                                </div>

                            </div>
                            <div className="card_div_card_last">
                                <div className="card_d_head">
                                Diversity & Inclusion
                                </div>
                                <div className="card_d_text">
                                We understand the importance of approaching each work integrally, thereby delivering solutions that meets our clients’ need. We understand the importance of approaching integrally, thereby delivering solutions that meets our clients’ need.
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default StoryHero
