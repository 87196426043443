import React, {useEffect} from 'react'
import './CareersV2.css'
import Topmenu from '../../components/Topmenu/Topmenu'
import Footer from '../../components/Footer/Footer'
import Careerherov2 from '../../components/Careerherov2/Careerherov2'
import OpenPositions from '../../components/CareerAplication'
import CareerOpenings from '../../components/CareerAplication/CareerOpenings'

function CareersV2() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Topmenu/>
     <Careerherov2/>
     <OpenPositions/>
     {/* <CareerOpenings/> */}
      <Footer/>
    </div>
  )
}

export default CareersV2