import React, { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";
import Topmenu from "../../../components/Topmenu/Topmenu";
import providus from "../../../assets/images/Providus_Bank_logo.png";
import "./Cstudy1.css";

const TicketsirCaseStudy = () => {
  const img_link = process.env.REACT_APP_IMG_LINK;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topmenu />
      <div className="payday__container">
        <div className="payday__overview flex">
          <div className="payday__overview--content">
            <h3 className="cstudytopic">Overview</h3>
            <p className="cstudynormtxt2">
              TicketSir, a leading ticketing platform, empowers event organizers
              and attendees with a user-friendly suite of applications.
            </p>
          </div>
          <div className="payday__overview--img">
            <img src={`${img_link}/ticketsir.png`} alt="CRC Logo" />
            {/* src={`${img_link}/crc.png`} */}
          </div>
        </div>

        <div className="cstudysolution">
          <p>
            <div className="cstudytopic">
              Challenges with their Initial Infrastructure
            </div>
            <div className="cstudynormtxt">
              The initial infrastructure that TicketSir relied on to manage
              their ticketing platform presented several limitations:
            </div>
          </p>
          <div className="icon_text_sub_cont">
            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Limited Scalability:</div>
                <div className="cstudynormtxt2">
                  Their servers couldn't effectively accommodate fluctuating
                  traffic demands during peak events. This led to performance
                  bottlenecks, causing website crashes and impacting user
                  experience.
                </div>
              </div>
            </div>

            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">
                  Increased Management Overhead:
                </div>
                <div className="cstudynormtxt2">
                  Maintaining and managing these infrastructures required
                  significant IT resources. This limited TicketSir's ability to
                  focus on core business initiatives like innovation and feature
                  development.
                </div>
              </div>
            </div>
            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Rising Costs:</div>
                <div className="cstudynormtxt2">
                  The cost of maintaining and scaling these infrastructures grew
                  steadily, hindering cost-efficiency.
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="payday__opportunity">
          <h3 className="cstudytopic">The Challenge</h3>
          <p className="cstudynormtxt2">
            CRC Credit Bureau, a leading player in the financial services
            industry, was confronted with the end-of-life of its hardware. At
            the same time the company experienced a surge in data processing
            requirements and user traffic, its existing infrastructure struggled
            to maintain optimal performance levels. This bottleneck not only
            risked customer satisfaction but posed a significant impediment to
            CRC's growth trajectory.
            <br />
            To address this pressing challenge, CRC sought a robust solution
            that seamlessly accommodated its escalating workload demands while
            ensuring reliability, scalability, and performance. Recognizing the
            major need for a comprehensive cloud infrastructure, CRC turned to
            industry leader Amazon Web Services (AWS) for a transformative
            solution that could propel its operations to new heights of
            efficiency and agility.
          </p>
        </div> */}

        <div className="cstudysolution">
          <p>
            <div className="cstudytopic">Why Migrate to AWS</div>
            <div className="cstudynormtxt">
              To overcome these limitations and ensure future growth, TicketSir
              decided to migrate their entire platform, including applications
              and data, to AWS. AWS offered several key advantages:{" "}
            </div>
          </p>
          <div className="icon_text_sub_cont">
            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Scalability:</div>
                <div className="cstudynormtxt2">
                  AWS provides a highly scalable infrastructure that can easily
                  adapt to TicketSir's dynamic traffic patterns. This ensures
                  the platform can handle peak loads without performance issues.{" "}
                </div>
              </div>
            </div>

            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Agility and Innovation:</div>
                <div className="cstudynormtxt2">
                  AWS empowers TicketSir to focus on core business functions by
                  offloading infrastructure management tasks. This allows them
                  to dedicate resources to innovation and feature development.{" "}
                </div>
              </div>
            </div>
            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Cost-Effectiveness:</div>
                <div className="cstudynormtxt2">
                  With AWS, TicketSir only pays for the resources they use,
                  leading to significant cost savings compared to on-premises
                  infrastructure. Additionally, AWS offers a variety of
                  cost-optimization options.{" "}
                </div>
              </div>
            </div>
            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Security:</div>
                <div className="cstudynormtxt2">
                  AWS offers robust security features and services to safeguard
                  TicketSir's sensitive user data and applications.{" "}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="payday__datamellon">
          <h3 className="cstudytopic">Partnering with Datamellon</h3>
          <p className="cstudynormtxt2">
            TicketSir partnered with Datamellon, an AWS Advanced Consulting
            Partner, to execute the migration and optimize their new AWS
            environment. Datamellon's expertise in cloud migration, DevOps, and
            application management ensured a smooth transition and efficient
            resource utilization.
          </p>
        </div>

        <div className="cstudysolution">
          <p>
            <div className="cstudytopic">Partner Solution</div>
            <div className="cstudynormtxt">
              Datamellon designed and implemented a comprehensive migration plan
              to move TicketSir's platform to AWS:
            </div>
          </p>
          <div className="icon_text_sub_cont">
            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Modernized Infrastructure:</div>
                <div className="cstudynormtxt2">
                  TicketSir's applications (including frontend websites, admin
                  portal, mobile apps, Node.js and .NET APIs) were migrated from
                  their on-premises data center to AWS. They leveraged Amazon
                  Elastic Container Service (ECS) with Fargate for serverless
                  container orchestration. This provided a scalable and
                  cost-effective platform for running their applications.
                </div>
              </div>
            </div>

            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Centralized Management:</div>
                <div className="cstudynormtxt2">
                  A multi-account strategy using AWS Control Tower was
                  implemented. This provided a well-organized and secure landing
                  zone for all TicketSir's AWS resources in the new account.
                  This centralized approach simplified management and cost
                  optimization.
                </div>
              </div>
            </div>
            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Enhanced Security:</div>
                <div className="cstudynormtxt2">
                  Robust security best practices were implemented, including
                  role-based access control (RBAC) and continuous security
                  monitoring through GuardDuty and SecurityHub. This ensured
                  robust security for TicketSir's platform and user data.
                </div>
              </div>
            </div>

            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">
                  Infrastructure as Code (IaC):
                </div>
                <div className="cstudynormtxt2">
                  Datamellon implemented IaC using Terraform to automate
                  infrastructure provisioning and management within the new AWS
                  account. This improved efficiency, repeatability, and cost
                  control.
                </div>
              </div>
            </div>

            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Streamlined Development:</div>
                <div className="cstudynormtxt2">
                  A robust CI/CD pipeline powered by AWS Developer Tools was
                  established. This enabled faster and more reliable application
                  development and deployment cycles.{" "}
                </div>
              </div>
            </div>

            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Optimized Databases:</div>
                <div className="cstudynormtxt2">
                  TicketSir's databases were migrated from their on-premises
                  environment to a managed RDS MSSQL database services on AWS.
                  This reduced administrative overhead and improved database
                  performance and management.{" "}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cstudysolution">
          <p>
            <div className="cstudytopic">Cost Optimization</div>
            <div className="cstudynormtxt">
              After the migration, Datamellon focused on optimizing costs to
              ensure TicketSir maximized their AWS investment:{" "}
            </div>
          </p>
          <div className="icon_text_sub_cont">
            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">
                  Scaling Down RDS Compute Capacity in Production:
                </div>
                <div className="cstudynormtxt2">
                  By analyzing usage patterns, Datamellon identified
                  opportunities to scale down the RDS compute capacity, reducing
                  costs without compromising performance.{" "}
                </div>
              </div>
            </div>

            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Scaling Down ECS Tasks:</div>
                <div className="cstudynormtxt2">
                  ECS tasks were optimized based on actual utilization, ensuring
                  resources were not over-provisioned.{" "}
                </div>
              </div>
            </div>
            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">
                  Terminating Unused EC2 Instances:
                </div>
                <div className="cstudynormtxt2">
                  Regular audits identified and terminated unused EC2 instances,
                  minimizing waste and reducing costs.{" "}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cstudybenefits">
          <div className="cstudytopic">Outcome</div>
          <div
            className="cstudynormtxt"
            style={{
              marginBottom: "20px",
            }}
          >
            The migration to AWS and partnership with Datamellon delivered
            significant benefits for TicketSir:
          </div>
          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">Improved Scalability:</div>
              <div className="cstudynormtxt2">
                TicketSir's platform can now easily scale to accommodate peak
                traffic without performance degradation. This ensures a smooth
                user experience during high-demand events.
              </div>
            </div>
          </div>
          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">Reduced Management Overhead:</div>
              <div className="cstudynormtxt2">
                AWS managed services and IaC significantly reduced the burden on
                TicketSir's IT team, allowing them to focus on core business
                initiatives like innovation and feature development.
              </div>
            </div>
          </div>

          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">Enhanced Security:</div>
              <div className="cstudynormtxt2">
                Robust security measures implemented on AWS ensure the
                protection of TicketSir's user data and applications.
              </div>
            </div>
          </div>

          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">Cost Savings:</div>
              <div className="cstudynormtxt2">
                The migration and optimization strategies led to significant
                cost savings compared to their on-premises and other host
                platform infrastructure.
              </div>
            </div>
          </div>
          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">Faster Development Cycles:</div>
              <div className="cstudynormtxt2">
                The streamlined CI/CD pipeline enabled faster and more efficient
                application updates.
              </div>
            </div>
          </div>
          {/* <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Improved User Experience and Reliability:
              </div>
              <div className="cstudynormtxt2">
                The collaborative solution addressed immediate challenges and
                contributed to an improved user experience. The reliability of
                the AWS infrastructure, coupled with the scalability of the API
                Gateway and serverless architecture, ensure uninterrupted
                services during peak periods, enhancing customer satisfaction.
              </div>
            </div>
          </div> */}
        </div>

        <div
          className="cstudybenefits"
          style={{
            marginTop: "40px",
          }}
        >
          <div className="cstudytopic">Next Steps</div>
          <div className="cstudynormtxt">
            With a robust and scalable AWS infrastructure in place, TicketSir is
            well-positioned to continue its growth trajectory. They can focus on
            enhancing their platform, offering new features to users, and
            expanding their reach in the ticketing industry. Datamellon will
            continue to provide ongoing support and expertise to ensure
            TicketSir maximizes the benefits of the AWS.
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TicketsirCaseStudy;
