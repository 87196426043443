import React from 'react'
import Button from '../Button/Button'
import CircularImage from '../Circular_Image/CircularImage'
import './Service_offered.css'

export default function ServiceOffered() {
    return (
        <div className='Container_service_offered'>
            <h1 className='ServiceTitles'>Services</h1>
            <div>
                <CircularImage/>
            </div>
        </div>
    )
}

