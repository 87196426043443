import React, { useEffect } from 'react'
import "./Overcenterhead.css"

function Overcenterhead({ title, subtitle, HeaderBG,pretitle }) {
  return (
    <div className='overLayHeaderBody' style={{
      backgroundImage: `${HeaderBG}`,
    }}>
      <div className='home_max_width'>
        <div className='overLayHeader2'>
          <div className='overLayHeaderwap' >
            <h1> <span style={{color:'#75B748'}} >{pretitle}</span>{title}</h1>
            <h3>{subtitle}</h3>

          </div>
          
          <div style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0', backgroundColor: 'rgba(0,0,0,0)' }}></div>
        </div>
      </div>
    </div>

  )
}

export default Overcenterhead