import React, { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";
import Topmenu from "../../../components/Topmenu/Topmenu";
import payday from "../../../assets/images/bg-payday.png";
import "./Cstudy1.css";

const Payday = () => {
  const img_link = process.env.REACT_APP_IMG_LINK;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topmenu />
      <div className="payday__container">
        <div className="payday__overview flex">
          <div className="payday__overview--content">
            <h3 className="cstudytopic">Overview</h3>
            <p className="cstudynormtxt2">
              PayDay is a fintech platform that provides global payment
              solutions for Africans. It allows users to send and receive money
              in different currencies, create virtual Mastercards that work
              anywhere online, and own multiple global accounts in their name.
              PayDay also helps foreign companies to handle payroll, taxes, and
              compliance for their African employees. PayDay aims to be the
              “PayPal” for Africa, connecting its users with vast opportunities
              around the world in seconds.
            </p>
          </div>
          <div className="payday__overview--img">
            <img src={payday} alt="Payday Logo" />
          </div>
        </div>

        <div className="payday__opportunity">
          <h3 className="cstudytopic">Opportunity</h3>
          <p className="cstudynormtxt2">
            As PayDay's user base expanded and the platform gained widespread
            adoption, the need for a more dynamic and scalable architecture
            became increasingly evident. The company's growth meant dealing with
            fluctuating demands, which required an infrastructure capable of
            scaling up and down seamlessly. PayDay recognized that any
            degradation in user experience, due to scalability issues or
            downtime during deployments, would not be acceptable. At the same
            time, it sought to minimize management overhead for its small
            development team, particularly in the context of infrastructure
            management. Ensuring the highest standards of security was of
            paramount importance, given the sensitivity of the financial
            transactions handled by the platform. Furthermore, the company aimed
            to maintain cost-efficiency in its operations.
          </p>
        </div>

        <div className="payday__aws">
          <h3 className="cstudytopic">Why AWS</h3>
          <p className="cstudynormtxt2">
            PayDay chose AWS as its cloud provider because of its reliability,
            scalability, security, and cost-effectiveness. AWS offers a wide
            range of services and features that enable PayDay to build a modern
            and resilient platform that can handle high volumes of transactions
            and data. AWS also provides PayDay with the flexibility to choose
            the best tools and technologies for its specific use cases and
            requirements. The comprehensive suite of services and tools, such as
            Amazon ECS for container orchestration and serverless computing, was
            well-suited to address these requirements. AWS also helps PayDay to
            comply with the relevant regulations and standards in the financial
            industry, such as PCI DSS, GDPR, and ISO 27001.
          </p>
        </div>

        <div className="payday__datamellon">
          <h3 className="cstudytopic">Why Datamellon</h3>
          <p className="cstudynormtxt2">
            Datamellon is an AWS Advanced Consulting Partner that specializes in
            cloud migration, DevOps, data analytics, artificial intelligence
            (AI), and machine learning (ML). Datamellon has extensive experience
            in working with financial institutions and helping them leverage
            cloud technologies to transform their businesses. Datamellon has a
            proven track record of delivering successful projects for its
            clients, such as PayDay, using agile methodologies and best
            practices. Datamellon also provides PayDay with ongoing support and
            maintenance, ensuring that its platform is always up to date and
            secure.
          </p>
        </div>

        <div className="cstudysolution">
          <p>
            <div className="cstudytopic">Partner Solution</div>
            <div className="cstudynormtxt">
              Datamellon provided a comprehensive solution to modernize PayDay's
              infrastructure and enhance its security posture:
            </div>
          </p>
          <div className="icon_text_sub_cont">
            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">
                  Financial Platform Built on AWS:
                </div>
                <div className="cstudynormtxt2">
                  Datamellon initiated the transformation by migrating PayDay's
                  infrastructure to a cloud-native architecture, hosted on
                  Amazon Elastic Container Service (ECS) with the Fargate launch
                  type. A secured Cloud Foundation was established by
                  implementing a multi-account strategy using AWS Control Tower.
                  This approach allowed for the creation of a well-organized
                  Landing Zone, accompanied by various organizational units and
                  accounts. By enabling GuardDuty and SecurityHub at the
                  organization level, PayDay gained a more comprehensive and
                  unified approach to security monitoring, bolstering its
                  defenses against potential threats.
                </div>
              </div>
            </div>

            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">
                  Authentication and Authorization:
                </div>
                <div className="cstudynormtxt2">
                  Datamellon implemented a role-based access control system that
                  allowed users and services to access resources securely
                  through the AWS console and the command-line interface (CLI).
                  Following the principle of least privilege and the adoption of
                  a zero-trust model, Datamellon minimized the risk of
                  unauthorized access or breaches, ensuring that only necessary
                  actions were permitted.
                </div>
              </div>
            </div>
            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Infrastructure as Code:</div>
                <div className="cstudynormtxt2">
                  To simplify resource and application deployment, Datamellon
                  introduced Infrastructure as Code (IaC) using Terraform and
                  AWS CloudFormation. This change enhanced the efficiency of
                  resource management and made it easier to maintain and scale
                  PayDay's infrastructure as needed. In parallel, Datamellon
                  implemented a robust Continuous Integration and Continuous
                  Deployment (CI/CD) pipeline using AWS Developer Tools enabling
                  automated testing, building, and deployment of code changes.
                  Additionally, a seamless connection between PayDay's GitHub
                  repository and AWS services was established through AWS
                  CodeStar, further streamlining the development process.
                </div>
              </div>
            </div>

            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Re-architected Deployment:</div>
                <div className="cstudynormtxt2">
                  The PayDay application underwent a significant transformation
                  and was re-architected for deployment on Amazon Elastic
                  Container Service (ECS) with the Fargate launch type. AWS
                  CodeDeploy played a pivotal role in facilitating Blue/Green
                  deployments with rolling updates. This approach ensured that
                  updates and enhancements could be rolled out seamlessly
                  without any deployment downtime, maintaining a consistent and
                  uninterrupted service for PayDay's users.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cstudybenefits">
          <div className="cstudytopic">Outcome</div>
          <div
            className="cstudynormtxt"
            style={{
              marginBottom: "20px",
            }}
          >
            The collaboration with Datamellon and the migration to AWS delivered
            a range of noteworthy outcomes for PayDay:
          </div>
          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Making Compliance Central to Risk Strategy:
              </div>
              <div className="cstudynormtxt2">
                PayDay was able to meet the compliance requirements of the
                financial industry, such as PCI DSS, GDPR, and ISO 27001, by
                using AWS services and features that are designed to help
                customers adhere to these standards. For example, PayDay used
                AWS KMS to encrypt its data at rest and in transit, AWS WAF to
                protect its web applications from common attacks, and AWS Config
                to monitor and audit its AWS resources.
              </div>
            </div>
          </div>
          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                A highly available workload on AWS:
              </div>
              <div className="cstudynormtxt2">
                PayDay was able to improve its availability and performance by
                using AWS services that are scalable, reliable, and
                fault-tolerant. For example, PayDay used Amazon ECS Fargate to
                run its containers without having to manage servers or clusters.
                This meant that PayDay's users could access their financial
                services without interruption, even during peak usage periods or
                infrastructure changes.
              </div>
            </div>
          </div>

          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Rapid Development Means Faster Fixes and Updates:
              </div>
              <div className="cstudynormtxt2">
                PayDay was able to accelerate its development and deployment
                cycles by using AWS services that are easy to use, integrate,
                and automate. PayDay used AWS Developer Tools to create a
                seamless CI/CD pipeline that enables faster and safer delivery
                of code changes, AWS CloudFormation and Terraform to provision
                and manage its infrastructure as code.
              </div>
            </div>
          </div>

          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">Cost Reduction by Over 40%:</div>
              <div className="cstudynormtxt2">
                Datamellon's modernization efforts, combined with AWS's cost
                optimization features, translated into a substantial reduction
                in operational costs for PayDay. This cost-efficiency enabled
                PayDay to allocate more resources to development, innovation,
                and further enhancements to its platform.
              </div>
            </div>
          </div>
        </div>

        <div
          className="cstudybenefits"
          style={{
            marginTop: "40px",
          }}
        >
          <div className="cstudytopic">Next Steps</div>
          <div className="cstudynormtxt">
            In summary, the strategic partnership with Datamellon and the
            migration to AWS not only improved PayDay's scalability, security,
            and cost-efficiency but also enabled the company to respond more
            quickly to user needs. This successful transformation positioned
            PayDay to continue its mission of becoming the leading fintech
            platform for Africa, connecting users with global opportunities
            seamlessly, and setting new industry standards in the process.
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Payday;
