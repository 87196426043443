import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Topmenu from "../../components/Topmenu/Topmenu";
import Overhead from "../../components/OverLayHeader/Overhead";
import "./LambdaAws.css";
import { useNavigate } from "react-router-dom";
import Ready from "../../components/Ready/Ready";

const WAF = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const img_link = process.env.REACT_APP_IMG_LINK;
  const navigate = useNavigate();

  return (
    <div>
      <Topmenu />
      <div className="lambda_main">
        <Overhead
          HeaderBG={`url("${img_link}/Lambda_img1.svg")`}
          title="Amazon WAF( Web Application Firewall) Specialization"
          subsub="Tailored, Real-time Web Application Defense"
          subtitle="AWS Web Application Firewall (WAF) is a powerful security service that safeguards your web applications from malicious activities, exploits, and other online threats. WAF provides protections against SQL injection and Cross-Site Scripting (XSS), and is tightly integrated with Amazon CloudFront, the Application Load Balancer (ALB), Amazon API Gateway, and AWS AppSync."
          subtitle1="Datamellon's AWS WAF Specialization takes this protection to the next level, providing tailored solutions and expert guidance to secure your applications seamlessly. "
        />
        <div className="home_max_width">
          {/* <div className="boxes">
            <div className="contentsBlueBox">
              <div className="normtext">
                Run code without provisioning or managing infrastructure. Simply
                write and upload code as a .zip file or container image.
              </div>
            </div>
            <div className="contentsBlueBox">
              <div className="normtext">
                Automatically respond to code execution requests at any scale,
                from a dozen events per day to hundreds of thousands per second.
              </div>
            </div>
            <div className="contentsBlueBox">
              <div className="normtext">
                Save costs by paying only for the compute time you use—by
                per-millisecond—instead of provisioning infrastructure upfront
                for peak capacity.
              </div>
            </div>
            <div className="contentsBlueBox">
              <div className="normtext">
                Optimize code execution time and performance with the right
                function memory size. Respond to high demand in double-digit
                milliseconds with Provisioned Concurrency.
              </div>
            </div>
          </div> */}
          <div
            className="Usecases"
            style={{
              marginTop: "80px",
            }}
          >
            <div className="title">Use cases</div>
            <div className="content_usecases">
              <div className="content">
                <div className="subtitlex">Financial Services</div>
                <div className="normtext">
                  AWS WAF provides a fortified defense against account takeover
                  attempts, phishing attacks, and fraudulent activities,
                  ensuring the confidentiality and integrity of financial data.
                </div>
              </div>
              <div className="content">
                <div className="subtitlex">E-commerce Platforms</div>
                <div className="normtext">
                  AWS WAF ensures the integrity of e-commerce platforms by
                  protecting customer data and transactional processes against
                  SQL injection, cross-site scripting (XSS), and other common
                  attacks.
                </div>
              </div>
              <div className="content">
                <div className="subtitlex"> Healthcare Applications</div>
                <div className="normtext">
                  WAF protects healthcare applications from data breaches,
                  ensuring compliance with industry regulations and maintaining
                  patient confidentiality.
                </div>
              </div>
              <div className="content">
                <div className="subtitlex">Media and Entertainment</div>
                <div className="normtext">
                  Web applications in the media and entertainment sector often
                  face distributed denial of service (DDoS) attacks. AWS WAF
                  shields against these attacks, ensuring uninterrupted access
                  to content for users around the globe.
                </div>
              </div>
            </div>
          </div>

          
          <div>
            <div className="home_max_width">
              <div className="containPadding">
                <div className="titler">Benefits</div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Advanced Threat Detection and Prevention
                      </div>
                      <div className="cstudynormtxt2">
                        AWS WAF employs sophisticated threat detection
                        mechanisms to identify and thwart malicious activities
                        in real-time. The specialization can detect and prevent
                        attacks by analyzing incoming traffic and comparing it
                        against predefined security rules, including SQL
                        injection, cross-site scripting (XSS), and other
                        standard web application vulnerabilities. This advanced
                        threat detection ensures your web applications remain
                        resilient against emerging cyber threats.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Customized Security Policies for Tailored Protection
                      </div>
                      <div className="cstudynormtxt2">
                        No two web applications are identical, nor should their
                        security strategies be. AWS WAF offers the flexibility
                        of creating customized security policies that align with
                        your applications' unique characteristics and
                        vulnerabilities. This tailored approach ensures that the
                        security measures are precisely calibrated to provide
                        maximum protection without compromising the performance
                        and functionality of your applications.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Real-time Updates and Threat Intelligence Integration
                      </div>
                      <div className="cstudynormtxt2">
                        The threat landscape is dynamic, requiring a proactive
                        approach to security. AWS WAF integrates real-time
                        threat intelligence, continuously updating its databases
                        to stay ahead of emerging threats. By leveraging the
                        latest threat information, the specialization enhances
                        its ability to identify and block new and sophisticated
                        attacks promptly, providing your web applications with a
                        robust defense against evolving cyber risks.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        {" "}
                        Automated Incident Response and Mitigation
                      </div>
                      <div className="cstudynormtxt2">
                        Speed is paramount in responding to security incidents.
                        AWS WAF includes automated incident response and
                        mitigation capabilities. When predefined security rules
                        are triggered, automated actions are initiated to
                        swiftly respond to and mitigate potential threats in
                        real-time. This ensures that your web applications are
                        protected around the clock, reducing the risk of
                        prolonged exposure to security vulnerabilities.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flexer">
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Comprehensive Web Traffic Insights for Informed
                        Decision-Making
                      </div>
                      <div className="cstudynormtxt2">
                        Understanding your web traffic is fundamental to
                        strengthening your security posture. AWS WAF provides
                        comprehensive analytics and insights into your web
                        traffic patterns. This valuable information allows you
                        to continually identify potential threats, track user
                        behavior, and make data-driven decisions to refine and
                        optimize your security strategy.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Ready />
      </div>
      <Footer />
    </div>
  );
};
export default WAF;
