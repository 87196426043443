import React from 'react'
import ViewServiceTemplate from '../ViewServiceTemplate'
import {IoIosSend} from 'react-icons/io'
import {FaFileInvoice, FaUnlockAlt} from 'react-icons/fa'
import {FiTarget} from 'react-icons/fi'
// import Cloud_foundation from '../../../assets/images/Cloud_foundation.png'
// import CloudFoundationHeader from '../../../assets/images/CloudFoundationHeader.png'



const CloudFormation = () => {
    const img_link = process.env.REACT_APP_IMG_LINK

    const data = {
        // headerBg : `url(${CloudFoundationHeader})`,
        headerBg : `url("${img_link}/CloudMigHeader.png")`,
        service_title: "Cloud Foundation",
        service_subtitle : "Build a secure, scalable cloud foundation to accelerate your cloud adoption, application mordernisation and business transformation journey.",
        paragraph: [
            'Whether you are new to cloud or looking to expand your cloud footprint, we guide you in building your Cloud Foundations with planet scale capabilities. The service implements standard guardrails to enforce best practices and provides flexibility for continuous scaling and iteration.',
            'With an emphasis on reliability, security, cost optimisation, performance and operational efficiency, Foundations service delivers AWS landing zone, GCP project or Azure subscription factory and Oracle cloud compartment management. Accelerates your cloud adoption with training, documentation and resources to help you and your teams get the skills they need for long term digital business innovation.',
            'With several decisons to make to establish a secure foothold in the cloud, our Foundation service helps you avoid missteps that could lead to serious security risks, unscalable systems, and operational ineffficiencies. Our consultants have deep cloud expertise across all the public platforms and are ready to accelerate architectural design and build effective processes. Cloud Foundations reduces risk and accelerates your cloud adoption by streamlining the process of creating an extensive cloud-native and hybrid-cloud foundations.'
        ],
        cardTitle: "Benefits",
        serviceImage: `${img_link}/Cloud_foundation.png`,
        cardInfo:[
            {
                iconColor:"linear-gradient(180deg, #FF5B00 0%, #FF7300 100%)",
                title:"Accelerate time to value",
                subtitle:"Quickly establish a foundational Cloud architecture and speed the deployment of high-value applications.",
                icon:<IoIosSend size={30} color="white"/>
            },
            {
                iconColor:"#AA3CBA",
                title:"Operations efficiency",
                subtitle:"Keep teams goal-focused and limit technical debt with automation that optimises cloud management with self-service.",
                icon:<FaFileInvoice size={30} color="white"/>
            },
            {
                iconColor:"#75B748",
                title:"Reliability",
                subtitle:"Ensure consistent and secure account creation and operation across your virtual private clouds.",
                icon:<FiTarget size={30} color="white"/>
            },
            {
                iconColor:"linear-gradient(180deg, #004CFF 0%, #691FFF 100%)",
                title:"Zero trust security",
                subtitle:"Reduce the risk of security breaches and vulnerabilities with a secure by design approach and best-practice foundation guardrails.",
                icon:<FaUnlockAlt size={30} color="white"/>
            }
        ]
    }
  return (
    <div>
        <ViewServiceTemplate 
            showServices = {true}
            showReady = {true}
            showApproach = {false}
            showWhy = {false}
            ServiceBg={data?.headerBg}
            service_title={data?.service_title}
            service_subtitle={data?.service_subtitle}
            paragraph={data.paragraph}
            serviceImage={data?.serviceImage}
            cardInfo={data.cardInfo}
            cardTitle={data.cardTitle}
        />
    </div>
  )
}

export default CloudFormation