import React, { useEffect } from 'react'
import "./OverlayheadeventB.css"
import CustomBtn from '../Button/CustomButton'
import { useGoogleCalendarApi } from 'react-google-calendar-api';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'

function OverlayheadeventB({ title, subtitle, HeaderBG, pretitle, HeaderNew }) {
  const img_link = process.env.REACT_APP_IMG_LINK

  const [showCalendar, setShowCalendar] = useState(false);
//   const { handleApiLoaded } = useGoogleCalendarApi();


  const handleButtonClick = () => {


//    navvigate('https://calendar.app.google/M584mp7rwWNNov8r8');
   window.open('https://calendar.app.google/M584mp7rwWNNov8r8')
  };

 

  return (
   <div>
     <div className='overlayheadeventBodyB'>
      <div className='home_max_widthB'>
        <div className='overlayheadevent2B'>
          <div className='overlayheadeventwapB' >
            <h1> <span style={{ fontWeight:"400", }} >{title}</span> <span style={{ color: '#75B748', }} >{pretitle}</span>
            </h1>
            {/* <h3>{subtitle}  </h3> */}
          <button className='startup_btn ' onClick={handleButtonClick}  >Book a pre-discovery session</button>
          {/* <CustomBtn
              type='button'
              txtColor='#75B748'
              pd='4px'
              br='10px'
              fs='16px'
              w='400px'
              bg='#F6FEF9'
              hoverBG='#F6FEF9'
              txt='Book a pre-discovery session'
              fw='700'
              border="1px solid #75B748"
              txtside="center"
              jcont="center"
              pleft="0px"
              fm='HK Grotesk'/> */}

        </div>
        {/* {showCalendar && (
        <div style={{ width: '800px', height: '600px' }}>
          <iframe
            src="https://calendar.google.com/calendar/embed?src=https://calendar.app.google/M584mp7rwWNNov8r8"
            width="800"
            height="600"
            frameBorder="0"
            scrolling="no"
            onLoad={handleApiLoaded}
            title="Google Calendar"
          ></iframe>
        </div>
      )} */}
        </div>
      </div>
    </div>
   </div>







  )
}

export default OverlayheadeventB