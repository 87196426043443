import React from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi';
import "./servicecard.css"
import { useNavigate } from 'react-router-dom';

export default function ServiceCard({ content_header, icon, content, url,url_large, }) {
    const navigate = useNavigate();
    return (
        <div className="cloud-services" style={{ cursor: 'pointer' , }} onClick={() => navigate(url_large)}>
            <div className="cloud_icon">
                {
                    url === '/aws' ?
                        <img src={icon} alt="serve-icon" style={{ cursor: 'pointer' , }} onClick={() => navigate(url)} />

                        :
                        <a style={{ textDecoration: 'none', color: "#75B748" }} href={url} target="_blank" rel="noopener noreferrer">
                            <img src={icon} alt="serve-icon" />
                        </a>
                }
            </div>
            <div className="cloud-text">
                <div className="text-topic">{content_header}
                </div>
                <div className="text-info"><p>{content}</p>
                </div>
            </div>
            <div className="con-button">
                {
                    url === "/aws" ?
                        <div style={{
                            cursor: 'pointer',
                            display: "flex", alignItems: "center", gap: "5px", textDecoration: "none" ,color: "#75B748"
                        }}
                            onClick={() => navigate(url)}
                        >
                            <div>
                                <span >More </span>
                            </div>
                            <div style={{
                                marginTop: "10px"
                            }} ><HiArrowNarrowRight /></div>
                        </div>
                        :
                        <a style={{ textDecoration: 'none', color: "#75B748" }} href={url} target="_blank" rel="noopener noreferrer">

                            <div style={{
                                display: "flex", alignItems: "center", gap: "5px", textDecoration: "none"
                            }}
                            >
                                <div>
                                    <span >More </span>
                                </div>
                                <div style={{
                                    marginTop: "10px"
                                }} ><HiArrowNarrowRight /></div>
                            </div>
                        </a>
                }

            </div>

        </div>
        

        

    )
}