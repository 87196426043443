import React, { useEffect, useState } from "react";
import Topmenu from "../../components/Topmenu/Topmenu";
import Footer from "../../components/Footer/Footer";
import { RxDotFilled } from "react-icons/rx";
import { BsDot } from "react-icons/bs";
import CustomBtn from "../../components/Button/CustomButton";
import { useParams, useSearchParams } from "react-router-dom";
import { openPositions } from "../../components/CareerAplication/openPositions";

const ViewCareer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const img_link = process.env.REACT_APP_IMG_LINK;

  // const { jobTitle } = useParams();

  const [jobData, setjobData] = useState({});

  let [searchParams, _setSearchParams] = useSearchParams();

  const jobTitle = searchParams.get("position");

  console.log(jobTitle, "job title");

  useEffect(() => {
    const value = openPositions.find((data) => data.slug === jobTitle);
    setjobData(value);
  }, [jobTitle]);

  return (
    <div>
      <Topmenu />
      {!!jobData.title && (
        <>
          <div className="careerherocontainerCopy">
            <div className="home_max_width">
              <div className="careerherosectionbody">
                <div className="careerleft-hero-section" style={{ gap: 0 }}>
                  <h2 className="careerlhero-section-text-bold">
                    Job <span style={{ color: "#75B748" }}>Description</span>
                  </h2>
                  <div className="careerlefthero-section-text-norm ">
                    <h3 className="bold">{jobData.title}</h3>
                    <h3 style={{ color: "#474545" }}>
                      {jobData.location} | {jobData.type}
                    </h3>
                  </div>
                </div>
                <div className="careerright-hero-section">
                  <div className="part-image-context">
                    <img
                      data-aos="fade-right"
                      src={`${img_link}/careerSingle.png`}
                      alt="step by step career"
                      className="heroimage"
                      style={{}}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="jobDesc">
            <div className="home_max_width">
              <div className="divJob">
                <div
                  style={{
                    backgroundColor: "#e7e7e7",
                    width: 70,
                    height: 70,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={`${img_link}/Datamellon_header_logo.svg`}
                    width="60px"
                  />
                </div>
                <div>
                  <h2>{jobData.title}</h2>
                  <div className="pointerCont">
                    <div className="pointer">
                      <BsDot size={30} color="var(--main)" />
                      {jobData.location}
                    </div>
                    <div className="pointer">
                      <BsDot size={30} color="var(--main)" />
                      {jobData.mode}
                    </div>
                  </div>
                </div>
              </div>

              <div className="jobDescGroup">
                <div className="jobDescHeading">Job Description</div>
                <div className="jobDescText">{jobData.jobDescription}</div>
              </div>

              <div className="jobDescGroup">
                {/* <div className="jobDescHeading">Responsibilities:</div> */}
                <div className="jobDescHeading">
                  {jobTitle === "graduate-trainee"
                    ? "Key Areas of Interest"
                    : jobTitle === "experienced-hire"
                    ? "Roles in"
                    : "Responsibilities"}
                </div>
                {jobData?.responsibilities?.map((job, index) => (
                  <div key={index} style={{ display: "flex", gap: "10px" }}>
                    <BsDot size={24} style={{ marginTop: "6px" }} />
                    <div className="jobDescText" style={{ flex: 1 }}>
                      {job}
                    </div>
                  </div>
                ))}
              </div>

              <div className="jobDescGroup">
                <div className="jobDescHeading">Qualifications:</div>
                {jobData?.qualifications?.map((job, index) => (
                  <div key={index} style={{ display: "flex", gap: "10px" }}>
                    <BsDot size={24} style={{ marginTop: "6px" }} />
                    <div className="jobDescText" style={{ flex: 1 }}>
                      {job}
                    </div>
                  </div>
                ))}
              </div>

              <div className="jobDescText">
                {jobTitle === "graduate-trainee"
                  ? "If you're eager to solve for what's next and want to be part of a forward-thinking, innovative team, apply now and take the next step in your career journey."
                  : jobTitle === "experienced-hire"
                  ? "If you're ready to shape the future with Datamellon and apply today."
                  : "If these qualifications fit your profile, complete the form below:"}
              </div>
                {console.log(jobData, 'job dataa')}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <CustomBtn
                  type="button"
                  txtColor="white"
                  pd="10px"
                  br="8px"
                  fs="18px"
                  w="250px"
                  bg="var(--main)"
                  hoverBG="var(--main)"
                  txt="Apply Here"
                  fw="500"
                  border="1px solid var(--main)"
                  // onClick={() => window.open('https://forms.gle/xvDmiJKtvNMgTgEQ9', '_blank')}
                  onClick={() => {
                    let formLink = "";

                    if (jobData.title === "Graduate Trainee") {
                      formLink = "https://docs.google.com/forms/d/e/1FAIpQLSfClnEZrDVpglbigZF42FU6cbiV05vymow2eP0UMBGeNJor3A/viewform";
                    }
                    else if (jobData.title === "Experienced Hire") {
                      formLink = "https://docs.google.com/forms/d/e/1FAIpQLSeoHWM5yp1iqRRtPLdXNIME3qdjC6T7r_0j5XQB8UmrQHC-RA/viewform";
                    }
                    else if (
                      jobData.title === "Product Manager – AI & Data Solutions"
                    ) {
                      formLink = "https://forms.gle/ij3WWGZCMaUNA9eAA";
                    } else if (
                      jobData.title === "Business Development Manager - Ghana"
                    ) {
                      formLink = "https://forms.gle/xDsrct4Gqn5njvo47";
                    } else if (jobData.title === "Senior Developer") {
                      formLink = "https://forms.gle/Qq8qTeU1ctMw4mt98";
                    } else if (jobData.title === "Finance Analyst") {
                      formLink = "https://forms.gle/xRcTrm5GJdKP2QJa9";
                    } else if (jobData.title === "Digital Marketing Lead") {
                      formLink = "https://forms.gle/GfK84oxGgik1hneQA";
                    } else if (jobData.title === "AI Engineer") {
                      formLink = "https://forms.gle/1gFZx3TwPziiqySo9";
                    } else {
                      formLink = "https://forms.gle/xvDmiJKtvNMgTgEQ9";
                    }

                    window.open(formLink, "_blank");
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}

      <Footer />
    </div>
  );
};

export default ViewCareer;
