import React from 'react'
import './Readstrt.css'
import MailCustomBtn from '../Button/MailCustomButton';

function Readstrt() {
    return (
        <div className='readstrt'>
            <div className="startred1">
                <div className="mainstrt">
                    <div className="startred1txt-major">
                        <h2 className="com_ctrl-txt">Ready to get started?</h2>
                    </div>
                    <div className='startred-btn-div' >
              <MailCustomBtn
                type='button'
                txtColor='white'
                pd='10px'
                br='8px'
                fs='18px'
                w='200px'
                bg='var(--main)'
                hoverBG='var(--main)'
                txt='Let’s Talk'
                fw='500'
                border="1px solid var(--main)"
                onClick={() => { }} />
            </div>

                </div>
            </div>
        </div>
    )
}

export default Readstrt