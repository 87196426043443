import React from 'react'
import "./ReadStoryHero.css"
import Our_story from '../../assets/images/OurStory.svg';
import CustomBtn from '../Button/CustomButton';

const ReadStoryHero = () => {
    return (
        <div className='readStoryContainer'>
            <div className="home_max_width">
                <div className="readStoryBody">
                    <div className="imageleftDiv">
                        <img src={Our_story} alt="" width="100%" style={{ borderRadius: '20px' }} />
                    </div>
                    <div className="textLeftStory">
                        <p className="subtitleTag">
                            Datamellon are a trusted partner on Vodafone's cloud adoption journey. The skills from their team have helped accelerate the use of AWS within our global organisation. Datamellon are a trusted partner on Vodafone's cloud adoption journey. The skills from their team have helped accelerate the use of AWS within our global organisation.
                        </p>
                        <div>
                            <CustomBtn
                                type='button'
                                txtColor='white'
                                pd='5px'
                                br='8px'
                                fs='17px'
                                w='220px'
                                bg='var(--main)'
                                hoverBG='var(--main)'
                                txt='Read our story'
                                fw='400'
                                border="1px solid var(--main)"
                                onClick={() => { }} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ReadStoryHero
