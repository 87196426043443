import React, {useEffect} from 'react'
import './OurStory.css'
import Topmenu from '../../components/Topmenu/Topmenu'
import Footer from '../../components/Footer/Footer'
import StoryHero from '../../components/StoryHero/StoryHero'
import ReadStoryHero from '../../components/ReadStoryHero/ReadStoryHero'

function OurStory() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Topmenu/>
      <StoryHero/>
      {/* <ReadStoryHero/> */}
      <Footer/>
    </div>
  )
}

export default OurStory