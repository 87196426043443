import React from 'react'
import "./WhyAdv.css"
import Solution_Datamellon from '../../assets/images/Solution_Datamellon.png';
import Solutions_Datamellon from '../../assets/images/Solutions_Datamellon.png';
import Tech_Datamellon from '../../assets/images/Tech_Datamellon.png';

const WhyAdv = () => {
  return (
    <div className='WhyAdvHeroContainer'>
        <div className="home_max_width">
            <div className="WhyAdvBody">
                <div className="WhyAdvBodytext">
                    <h2 className='titleTag'>
                        The Datamellon Advantage
                    </h2>
                    <p className="subtitleTag">
                        Datamellon are a trusted partner on Vodafone's cloud adoption journey. The skills from their team have helped accelerate the use of AWS within our global organisation
                    </p>
                </div>
                <div className="card_hcontainer">
                    <div className="card_card">
                        <img src={Solution_Datamellon} alt="" width="100px" height="100px" />
                        <p>Solutions</p>
                        <span>We understand the importance of approaching each work integrally, thereby delivering solutions that meets our clients’ need.</span>
                    </div>
                    <div className="card_card">
                        <img src={Tech_Datamellon} alt="" width="100px" height="100px" />
                        <p>Technology</p>
                        <span>We understand the importance of approaching each work integrally, thereby delivering solutions that meets our clients’ need.</span>
                    </div>
                    <div className="card_card">
                        <img src={Solutions_Datamellon} alt="" width="100px" height="100px" />
                        <p>Solutions</p>
                        <span>We understand the importance of approaching each work integrally, thereby delivering solutions that meets our clients’ need.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WhyAdv
