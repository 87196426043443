import React, { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";
import Topmenu from "../../../components/Topmenu/Topmenu";
import providus from "../../../assets/images/Providus_Bank_logo.png";
import "../Cstudy1/Cstudy1.css";

export default function Providus() {
  const img_link = process.env.REACT_APP_IMG_LINK;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Topmenu />
      <div className="cstudy1">
        <div className="home_max_width">
          <div className="casestudcontainer">
            <div className="csheadbg"></div>
            <div className="cstudbody">
              <div className="cstudyclient">
                <div>
                  <div className="cstudytopic">Well Architected Review</div>
                  <div className="cstudynormtxt">
                    Datamellon conducted a comprehensive Well-Architected Review
                    of ProvidusBank's AWS environment, identifying areas for
                    improvement and providing valuable recommendations. The
                    review encompassed the six pillars of the Well-Architected
                    Framework, including security, operational excellence, cost
                    optimization, and reliability.
                  </div>
                </div>
                <div className="vodafone">
                  <img src={providus} alt="Providius Logo" />
                </div>
              </div>

              <div className="cstudychallenge">
                <div className="cstudytopic">Customer Challenge</div>
                <div className="cstudynormtxt">
                  In order to align with best practices and optimize their AWS
                  workloads, ProvidusBank, a leading financial institution in
                  Nigeria, sought to enhance security and reduce costs. With
                  their mobile app hosted on AWS, they aimed to leverage the
                  Well-Architected Framework to achieve these objectives.
                </div>
              </div>

              <div className="cstudysolution">
                <p>
                  <div className="cstudytopic">The Solution</div>
                  <div className="cstudynormtxt">
                    Datamellon conducted a comprehensive Well-Architected Review
                    of ProvidusBank's AWS environment, identifying areas for
                    improvement and providing valuable recommendations. The
                    review encompassed the six pillars of the Well-Architected
                    Framework, including security, operational excellence, cost
                    optimization, and reliability.
                  </div>
                </p>
                <div className="icon_text_sub_cont">
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Security</div>
                      <div className="cstudynormtxt2">
                        To enhance security, recommendations were made to
                        implement Federated Identity Sources, enable MFA, manage
                        access keys, and ensure proper access controls for S3
                        and RDS instances.
                      </div>
                    </div>
                  </div>

                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Operational Excellence</div>
                      <div className="cstudynormtxt2">
                        Suggestions focused on automating resource deployment
                        using Infrastructure as Code (IaC) and creating a custom
                        AWS VPC for improved operational efficiency.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Cost Optimization</div>
                      <div className="cstudynormtxt2">
                        In order to optimize costs, recommendations included
                        rightsizing EC2 instances, utilizing appropriate
                        instance types during peak and non-peak periods, and
                        leveraging EC2 Saving plans and RDS Reservation plans.
                      </div>
                    </div>
                  </div>

                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Reliability</div>
                      <div className="cstudynormtxt2">
                        To enhance reliability, recommendations were made to
                        deploy instances across multiple availability zones
                        (AZs) within a region, reducing potential points of
                        failure and improving overall system reliability.
                      </div>
                    </div>
                  </div>
                  {/* <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Results</div>
                      <div className="cstudynormtxt2">
                        As a result of the Well-Architected Framework review,
                        ProvidusBank achieved significant benefits. They
                        realized a 33% reduction in AWS costs, with a 26%
                        decrease in EC2 instances cost and a 59% decrease in RDS
                        cost. High-risk and medium-risk issues were effectively
                        mitigated, and security vulnerabilities and cost
                        inefficiencies were promptly addressed.
                        <br />
                        Additionally, the review identified workloads suitable
                        for migration through the MAP Assessment Phase, enabling
                        ProvidusBank to further optimize their cloud
                        infrastructure.
                        <br />
                        By embracing the Well-Architected Framework and
                        implementing the recommended changes, ProvidusBank has
                        achieved enhanced cost efficiency, improved security,
                        and a more resilient and optimized AWS environment.
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              <div
                className="cstudychallenge"
                style={{
                  paddingTop: "20px",
                }}
              >
                <div className="cstudytopic">Results</div>
                <div className="cstudynormtxt">
                  As a result of the Well-Architected Framework review,
                  ProvidusBank achieved significant benefits. They realized a
                  33% reduction in AWS costs, with a 26% decrease in EC2
                  instances cost and a 59% decrease in RDS cost. High-risk and
                  medium-risk issues were effectively mitigated, and security
                  vulnerabilities and cost inefficiencies were promptly
                  addressed.
                  <br />
                  Additionally, the review identified workloads suitable for
                  migration through the MAP Assessment Phase, enabling
                  ProvidusBank to further optimize their cloud infrastructure.
                  <br />
                  By embracing the Well-Architected Framework and implementing
                  the recommended changes, ProvidusBank has achieved enhanced
                  cost efficiency, improved security, and a more resilient and
                  optimized AWS environment.
                </div>
              </div>
              {/* <div className="cstudybenefits">
                <div className="cstudytopic">The Benefits</div>
                <div className="cstudynormtxt">
                  The solution provided a highly scalable, flexible, and highly
                  available system with zero downtime and cost optimization.
                  This improves the user experience of the platform making sure
                  users don’t experience any downtime while making use of the
                  application while providing an efficient infrastructure that
                  is flexible, scalable, and saves cost for the company.
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
