import React from 'react'
import ProductCard from './ProductCard'

const AllProducts = () => {

    const img_link = process.env.REACT_APP_IMG_LINK

    const services = [
        {
            reverse: true,
            title: 'Dillivry' ,
            subtitle:'is an online logistics based marketplace (aggregator) that connect senders and logistics providers. ',
            subtitle1:'As a sender, make your delivery request and get multiple bids from verified logistics providers.',
            subtitle2:'As a provider, sign up and get access to daily delivery requests and place your quotes for the job',
            link:'/',
            img:`${img_link}/Dillivry_icon.svg`
        },
        {
            reverse: false,
            title: 'Cloud formation' ,
            subtitle:'is an online logistics based marketplace (aggregator) that connect senders and logistics providers. ',
            subtitle1:'As a sender, make your delivery request and get multiple bids from verified logistics providers.',
            subtitle2:'As a provider, sign up and get access to daily delivery requests and place your quotes for the job',
            link:'/',
            img:`${img_link}/Klaud_works.svg`
        },

    ]
    return (
        <div className='home_max_width'>
            <div className='product_mai'>
                {
                    services.map((x, index) => (
                        <ProductCard
                            reverse={x.reverse}
                            key={index}
                            title={x.title}
                            subtitle={x.subtitle}
                            subtitle1={x.subtitle1}
                            subtitle2={x.subtitle2}
                            link={x.link}
                            img={x.img}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default AllProducts
