import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BiChevronDown } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router-dom";
import "./DropDownMenu.css";

export default function ServicesDropDownMenu() {
  let navigate = useNavigate();
  let location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLinkClick = (link) => {
    setAnchorEl(null);
    navigate(`/${link}`);
  };

  return (
    <div>
      <div
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onMouseOver={handleClick}
        style={{
          textDecoration: "none",
          fontSize: "18px",
          fontWeight: "500",
          lineHeight: "19px",
          color: "#141811",
          display: "flex",
          gap: "5px",
          cursor: "pointer",
        }}
      >
        <span>Services</span>
        <span>
          <BiChevronDown />
        </span>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          style={{
            color:
              location.pathname === "/services"
                ? "var(--main)"
                : "var(--textColor)",
          }}
          onClick={() => handleLinkClick("services")}
        >
          Our Services
        </MenuItem>

        <MenuItem
          style={{
            color:
              location.pathname === "/migration-fsi"
                ? "var(--main)"
                : "var(--textColor)",
          }}
          onClick={() => handleLinkClick("migration-fsi")}
        >
          FSI Migration
        </MenuItem>
      </Menu>
    </div>
  );
}
