import React, { useEffect } from 'react'
import "./Overlayheadevent.css"

function Overlayheadevent({ title, subtitle, HeaderBG, pretitle, HeaderNew }) {
  const img_link = process.env.REACT_APP_IMG_LINK
  return (
    <div className='overlayheadeventBody'>
      <div className='home_max_width'>
        <div className='overlayheadevent2'>
          <div className='overlayheadeventwap' >
            {/* <img className='dmel_img' src={`${img_link}/Datamellon_footer_logo.svg`} alt='Datamellon icon' /> */}
            <h1> <span style={{ fontWeight:"400" }} >{title}</span> <span style={{ color: '#75B748', }} >{pretitle}</span>
            </h1>
            <h3>{subtitle}  </h3>
            <h4>VIRTUAL  EVENT</h4>

        </div>
         
        </div>
      </div>
    </div>







  )
}

export default Overlayheadevent