import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import "./Compecard_2.css";
import { Link } from "react-router-dom";

export default function Compecard_2({ content_header, content, url, color }) {
  // const history = useHistory();
  return (
    <div className="con_for_comp">
      <Link to={url}>
        <div className="compe_card-services">
          <div className="compe_card-text">
            <div className="compe_cardtext-topic">{content_header}</div>
            <div className="compe_cardtext-info">
              <p style={{ color: `${color}`, fontWeight: "700" }}>{content}</p>
            </div>
          </div>

          {/* <div className="con-button">
                
            <a style={{textDecoration:'none',color: "#75B748"}} href={url}  target="_blank" rel="noopener noreferrer">
                
                   <div  style={{
                       display: "flex", alignItems: "center", gap: "5px" ,textDecoration:"none"
                       
                   }} >
                       <div>
                       <span >More </span>
                       </div>
                       <div style={{
                           marginTop: "10px"
                       }} ><HiArrowNarrowRight /></div>
                   </div>
                    </a>
            </div> */}
        </div>
      </Link>
    </div>
  );
}
