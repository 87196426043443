import React from "react";
import "./fsi.css";
import benefitsbulb from "../../assets/images/benefits-bulb.png";
import benefitsobject from "../../assets/images/benefits-objects.png";
import benefitsstar from "../../assets/images/benefits-star.png";

const FSIBenefits = () => {
  const fsibenefits = [
    {
      img: benefitsbulb,
      heading: "Reduced Operational Costs",
      subtext:
        "Organizations reduced operational costs to save 31% by migrating to AWS.",
    },
    {
      img: benefitsobject,
      heading: "Improved Staff Productivity",
      subtext:
        "Organizations running on AWS delivered up to three times more application features per year and saw improved productivity with 62% more efficient IT infrastructure Management",
    },
    {
      img: benefitsstar,
      heading: "Less unplanned downtime",
      subtext:
        " Organizations experience up to 69% reduction in unplanned downtime.",
    },
  ];
  return (
    <div className="fsibenefits__container">
      <h2>Realizing the Benefits of Migration</h2>
      <div className="fsibenefits__grid">
        {fsibenefits.map((benefit) => (
          <div className="fsisbenefits__child" key={benefit.heading}>
            <div className="fsibenefits__image">
              <img src={benefit.img} alt={benefit.heading} />
            </div>
            <div className="fsibenefits__text">
              <h4>{benefit.heading}</h4>
              <p>{benefit.subtext}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FSIBenefits;
