import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Topmenu from "../../components/Topmenu/Topmenu";
import Overhead from "../../components/OverLayHeader/Overhead";
import "./LambdaAws.css";
import { useNavigate } from "react-router-dom";
import Ready from "../../components/Ready/Ready";

const ServiceCatalog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const img_link = process.env.REACT_APP_IMG_LINK;
  const navigate = useNavigate();

  return (
    <div>
      <Topmenu />
      <div className="lambda_main">
        <Overhead
          HeaderBG={`url("${img_link}/Lambda_img1.svg")`}
          title="AWS Service Catalog"
          subsub="Datamellon's Service Catalog Solutions"
          subtitle="AWS Service Catalog is a fully managed service that enables organizations to centrally manage and govern cloud resources and services across multiple AWS accounts and regions."
          subtitle1="It provides a catalog of approved AWS services, products, and resources, allowing administrators to define and enforce compliance policies. It also empowers users to provide self-service resources based on their requirements."
        />
        <div className="home_max_width">
          <div>
            <div className="home_max_width">
              <div className="containPadding">
                <div className="titler">Key Features</div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Centralized Service Catalog
                      </div>
                      <div className="cstudynormtxt2">
                        AWS Service Catalog offers a centralized repository of
                        approved AWS services, products, and resources, curated
                        by administrators to ensure compliance with
                        organizational standards and best practices.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Portfolio Management</div>
                      <div className="cstudynormtxt2">
                        It allows organizations to create and manage portfolios
                        of AWS resources, including EC2 instances, S3 buckets,
                        RDS databases, Lambda functions, and custom products
                        tailored to specific business units or use cases.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Versioning and Lifecycle Management
                      </div>
                      <div className="cstudynormtxt2">
                        The Service Catalog supports versioning and lifecycle
                        management of products and resources, enabling
                        administrators to control access, updates, and
                        deprecation of offerings over time.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Access Controls and Permissions
                      </div>
                      <div className="cstudynormtxt2">
                        It integrates with AWS Identity and Access Management
                        (IAM) for fine-grained access controls and permissions
                        management, ensuring that only authorized users can
                        provision and manage resources from the catalog.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flexer">
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Tagging and Cost Allocation
                      </div>
                      <div className="cstudynormtxt2">
                        The service Catalog supports resource tagging and cost
                        allocation tags, allowing organizations to enforce
                        tagging policies and track resource usage and costs
                        across the AWS environment.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="home_max_width">
              <div className="containPadding">
                <div className="titler">Use Cases</div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Governance and Compliance
                      </div>
                      <div className="cstudynormtxt2">
                        AWS Service Catalog helps enforce governance and
                        compliance policies by offering a curated catalog of
                        approved AWS services and resources, ensuring that only
                        compliant configurations are provisioned.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Standardization and Consistency
                      </div>
                      <div className="cstudynormtxt2">
                        It promotes standardization and consistency by providing
                        pre-approved templates and configurations for common use
                        cases, reducing the risk of configuration drift and
                        ensuring uniformity across deployments.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Self-Service Provisioning
                      </div>
                      <div className="cstudynormtxt2">
                        The Service Catalog empowers users to self-service
                        provision resources from the catalog based on their
                        specific requirements, reducing the burden on IT and
                        accelerating time-to-market for new projects and
                        initiatives.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Resource Reusability</div>
                      <div className="cstudynormtxt2">
                        Organizations can create reusable templates and products
                        within the Service Catalog, facilitating resource
                        sharing and collaboration across teams and departments
                        while maintaining control over resource usage and costs.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flexer">
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Enterprise IT Governance
                      </div>
                      <div className="cstudynormtxt2">
                        It enables enterprise IT teams to enforce governance
                        controls and compliance standards across multiple AWS
                        accounts and regions, providing visibility and control
                        over cloud resources and services.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Ready />
      </div>
      <Footer />
    </div>
  );
};
export default ServiceCatalog;
