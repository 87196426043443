import React, { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";
import Topmenu from "../../../components/Topmenu/Topmenu";
import providus from "../../../assets/images/Providus_Bank_logo.png";
import "./Cstudy1.css";

const GTCaseStudy = () => {
  const img_link = process.env.REACT_APP_IMG_LINK;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topmenu />
      <div className="payday__container">
        <div className="payday__overview flex">
          <div className="payday__overview--content">
            <h3 className="cstudytopic">Executive Summary</h3>
            <p className="cstudynormtxt2">
              Guaranty Trust Bank Limited (GTBank) is one of Africa’s leading
              banking groups, serving over 20 million customers across ten
              African countries and the United Kingdom. With the largest
              concentration of its customer base in Nigeria, GTBank is committed
              to continuously enhancing its service offerings to meet the
              evolving needs of its customers.
              <br />
              As part of its strategic initiative to provide superior digital
              services, GTBank sought to optimize the performance and
              scalability of its GTWorld mobile app. This platform is vital for
              delivering a wide range of banking services, including transfers,
              bill payments, and other financial transactions. To achieve this,
              GTBank recognized the need to migrate the app's backend
              infrastructure to a more flexible and scalable environment.
              <br />
              The bank turned to Datamellon Limited, an AWS Advanced Consulting
              Partner, to facilitate this migration to AWS Cloud. This move was
              driven by GTBank's goal to enhance the app's reliability,
              performance, and security, while also achieving cost efficiency.
              By leveraging AWS Cloud’s robust infrastructure, GTBank aimed to
              ensure its app could seamlessly handle increasing transaction
              volumes and provide a superior user experience, all while
              maintaining compliance with regulatory requirements by keeping its
              database on-premises.
            </p>
          </div>
          <div className="payday__overview--img">
            <img src={`${img_link}/gtb-co.png`} alt="GT Logo" />
            {/* src={`${img_link}/crc.png`} */}
          </div>
        </div>

        {/* <div className="payday__opportunity">
          <h3 className="cstudytopic">Opportunity</h3>
          <p className="cstudynormtxt2">
            As CRC Credit Bureau approached the end-of-life of its hardware
            infrastructure, it took the decision to migrate its applications and
            data to the cloud which offers numerous advantages beyond addressing
            the immediate hardware limitations. Here's an expanded list of
            benefits and advantages: Infinite Scalability, Pay-as-You-Go
            Pricing, Improved Security, Enhanced Reliability, Optimal
            Performance, Global Reach, Scalable Storage Innovation and Agility.
            Overall, migrating to the cloud offers CRC Credit Bureau the
            opportunity to modernize its IT infrastructure, improve operational
            efficiency, and unlock new capabilities for innovation and growth.
            By harnessing the benefits of cloud computing, the organization can
            better serve its growing customer base while remaining agile and
            competitive in a rapidly evolving business landscape.
          </p>
        </div> */}

        <div className="payday__opportunity">
          <h3 className="cstudytopic">The Challenge</h3>
          <p className="cstudynormtxt2">
            GTWorld is an essential digital platform for GTBank, providing
            millions of customers with the ability to perform a wide range of
            banking transactions, including transfers, bill payments, airtime
            purchases, and card management. It also offers access to other
            banking services such as loan applications, investment products, and
            financial education.
            <br />
            To continue delivering excellent service to its growing customer
            base, GTBank recognized the need to enhance the reliability,
            performance, and scalability of its digital banking app. The
            existing on-premises infrastructure, while functional, had
            limitations in scaling efficiently to meet the increasing demand for
            digital transactions and could not fully support the evolving
            expectations of customers for fast, seamless, and secure online
            banking experiences.
            <br />
            GTBank's commitment to providing superior digital services led them
            to explore cloud migration options that could offer improved
            agility, flexibility, and security. By moving its mobile app
            infrastructure to AWS, GTBank aimed to leverage cloud benefits, such
            as on-demand scalability, enhanced security features, and
            cost-effective resource management, to better serve its customers
            and adapt to changing market dynamics
          </p>
        </div>

        <div className="payday__aws">
          <h3 className="cstudytopic">Why AWS</h3>
          <p className="cstudynormtxt2">
            GTBank decided to migrate its GTWorld backend infrastructure to AWS
            Cloud for several reasons. First, AWS had a network of trusted
            partners that could help GTBank with the migration process and
            provide ongoing support. Second, AWS provided flexible pricing
            models that would allow GTBank to pay only for what it used and
            optimize its costs. Third, AWS had a strong presence in Africa with
            local data centers and support teams that would ensure low latency
            and compliance with local regulations. Fourth, AWS offered a range
            of services and features that would enable GTWorld to achieve high
            availability, scalability, performance, security, and reliability
            for its app.
          </p>
        </div>

        <div className="payday__datamellon">
          <h3 className="cstudytopic">Why Datamellon</h3>
          <p className="cstudynormtxt2">
            GTBank selected Datamellon as its partner to migrate its mobile app
            infrastructure to AWS. As an AWS Advanced Consulting Partner,
            Datamellon specializes in Secure Zero-Downtime Migration and
            Modernisation for Financial Servicesc, DevOps, Data analytics,
            Artificial Intelligence (AI), and machine learning (ML). With
            extensive experience working with financial institutions, Datamellon
            helps clients harness cloud technologies to drive business
            transformation. Datamellon stood out to GTBank due to its deep
            expertise in AWS services and best practices, as well as its strong
            understanding of GTBank’s business needs and challenges. The
            company’s ability to deliver solutions on time and within budget
            further reinforced its value. Throughout the project, Datamellon
            ensured proactive communication, regular updates, and a focus on
            quality assurance, demonstrating a strong commitment to customer
            satisfaction.
          </p>
        </div>

        <div className="payday__datamellon">
          <h3 className="cstudytopic">Partner Solution</h3>
          <p className="cstudynormtxt2">
            Datamellon worked closely with GTBank’s IT team to design and
            implement a solution that would migrate GTBank’s mobile app
            infrastructure to AWS Cloud while keeping the database on-premises
            due to regulatory reasons.
            <br />
            The solution involved migrating the backend APIs that handled the
            app’s business logic and transactions to Amazon Elastic Compute
            Cloud (Amazon EC2) instances. Datamellon used Terraform to automate
            the provisioning and configuration of the EC2 instances and ensure
            consistency and repeatability. Datamellon also used AWS Auto Scaling
            to automatically adjust the number of EC2 instances based on the
            app’s traffic and demand as well as placing the instances behind an
            application load balancer. This enabled GTBank to scale up or down
            its infrastructure as needed and optimize its performance and costs.
            <br />
            The solution also involved re-architecting the API that stored
            transaction receipts to a serverless architecture using API Gateway,
            AWS Lambda, DynamoDB and Amazon Simple Storage Service (Amazon S3).
            <br />
            Datamellon also used Amazon CloudFront and AWS Web Application
            Firewall (AWS WAF) to enable customers to access their transaction
            receipts in Amazon S3 through a fast and secure content delivery
            network (CDN). Amazon CloudFront is a service that speeds up the
            distribution of your static and dynamic web content to your users.
            AWS WAF is a service that helps protect your web applications from
            common web exploits that could affect availability, compromise
            security, or consume excessive resources.
            <br />
            The solution also involved establishing a secure and reliable
            connection between the app’s backend infrastructure on AWS Cloud and
            the database on-premises using AWS Direct Connect and AWS
            Site-to-Site VPN. AWS Direct Connect is a service that lets you
            establish a dedicated network connection between your premises and
            AWS. AWS Site-to-Site VPN is a service that lets you establish a
            secure and private encrypted tunnel between your network and AWS.
            Datamellon used these services to ensure that the app’s data was
            transferred securely and efficiently between AWS Cloud and the
            on-premises database.
            <br />
            Datamellon followed the AWS Well-Architected Framework and the AWS
            Migration Acceleration Program (MAP) methodologies to ensure that
            the solution was designed and implemented according to the best
            practices for security, reliability, performance efficiency, cost
            optimization, and operational excellence.
          </p>
        </div>

        {/* <div className="cstudysolution">
          <p>
            <div className="cstudytopic">Partner Solution</div>
            <div className="cstudynormtxt">
              In response to CRC Credit Bureau's migration needs, Datamellon, an
              AWS Advanced Consulting Partner, offered a comprehensive suite of
              professional services tailored to ensure a seamless transition to
              the AWS. Leveraging their expertise and experience, Datamellon
              delivered a robust solution encompassing the following key
              components:
            </div>
          </p>
          <div className="icon_text_sub_cont">
            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">AWS Cloud Foundation:</div>
                <div className="cstudynormtxt2">
                  Datamellon designed and set up a robust cloud foundation and
                  Control Tower Landing Zone, ensuring a secure and compliant
                  AWS environment. DevOps practices were implemented across all
                  environments, and networking architecture, including VPC
                  design and implementation, was meticulously crafted to
                  optimize performance and security. Cloud governance and
                  security guardrails were established to enforce compliance
                  standards and mitigate risks effectively.
                </div>
              </div>
            </div>

            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Automated CI/CD Pipelines:</div>
                <div className="cstudynormtxt2">
                  Datamellon implemented automated CI/CD pipelines for
                  infrastructure and application deployments across different
                  environments. This streamlined the development and deployment
                  processes, enhancing efficiency, reducing errors, and
                  accelerating time-to-market for CRC's applications and
                  services.
                </div>
              </div>
            </div>
            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Migration Services:</div>
                <div className="cstudynormtxt2">
                  Datamellon facilitated the migration of CRC's application and
                  web servers to the AWS Cloud using AWS Migration services such
                  as Application Migration Service (MGN) and Migration Tool,
                  ensuring a smooth and efficient transition with minimal
                  disruption to operations.
                </div>
              </div>
            </div>

            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">
                  Integration with On-premise Database:
                </div>
                <div className="cstudynormtxt2">
                  Datamellon successfully connected CRC's workloads in AWS to
                  their on-premise database, enabling seamless data access and
                  synchronization between cloud and on-premise environments.
                </div>
              </div>
            </div>
            <p
              style={{
                marginTop: "35px",
              }}
            >
              Datamellon utilized a range of AWS services to deliver these
              solutions, including AWS Control Tower and Landing Zone setup for
              multi-account provisioning and controls, AWS CodeCommit for
              version control of infrastructure code, AWS CodePipeline for CI/CD
              pipeline orchestration, AWS CodeBuild for source code compilation,
              Amazon EC2 for hosting application and web servers, AWS IAM for
              identity management, AWS Key Management System for security and
              encryption, AWS Security Hub for security orchestration, AWS S3
              for object storage, and AWS VPC for virtual network environments.
            </p>
          </div>
        </div> */}

        <div className="cstudybenefits">
          <div className="cstudytopic">Results and Benefits</div>
          <div
            className="cstudynormtxt"
            style={{
              marginBottom: "20px",
            }}
          >
            By migrating its mobile app infrastructure to AWS Cloud with the
            help of Datamellon, GTBank achieved several benefits such as:
          </div>
          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Improved customer experience and satisfaction:
              </div>
              <div className="cstudynormtxt2">
                The app’s performance, availability, and reliability improved
                significantly after the migration, resulting in faster
                transactions, fewer errors, and happier customers. The app also
                gained new features such as transaction receipts that enhanced
                the customer experience.
              </div>
            </div>
          </div>
          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Increased scalability and agility:
              </div>
              <div className="cstudynormtxt2">
                The app’s infrastructure was able to handle the increased
                traffic and transactions without any issues, thanks to the
                scalability and elasticity of AWS services. The app was also
                able to adapt quickly to changing customer needs and market
                conditions, thanks to the agility and flexibility of AWS
                services.
              </div>
            </div>
          </div>

          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Reduced operational costs and complexity:
              </div>
              <div className="cstudynormtxt2">
                The app’s operational costs reduced by 40% after the migration,
                thanks to the pay-as-you-go pricing model of AWS services and
                reservation of compute resources. The app’s operational
                complexity also reduced by 60%, thanks to the automation,
                management, and monitoring capabilities of AWS services.
              </div>
            </div>
          </div>

          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Enhanced security and compliance:
              </div>
              <div className="cstudynormtxt2">
                The app’s security posture improved after the migration, thanks
                to the multi-account strategy adopted, encryption, firewall,
                identity, access management, logging, auditing, and compliance
                features of AWS services. The app also met the regulatory
                requirements of keeping its database on-premises while
                leveraging the benefits of cloud computing.
              </div>
            </div>
          </div>

          {/* <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Improved User Experience and Reliability:
              </div>
              <div className="cstudynormtxt2">
                The collaborative solution addressed immediate challenges and
                contributed to an improved user experience. The reliability of
                the AWS infrastructure, coupled with the scalability of the API
                Gateway and serverless architecture, ensure uninterrupted
                services during peak periods, enhancing customer satisfaction.
              </div>
            </div>
          </div> */}
        </div>

        <div
          className="cstudybenefits"
          style={{
            marginTop: "40px",
          }}
        >
          <div className="cstudytopic">Next Steps</div>
          <div className="cstudynormtxt">
            Datamellon and GTBank plan to continue their partnership and
            collaboration to further enhance the app’s functionality and
            performance. The next steps include re-architecting the app to run
            on Amazon Elastic Kubernetes Service (Amazon EKS) Fargate. Amazon
            EKS is a fully managed service that makes it easy to run Kubernetes
            on AWS without needing to install, operate, or maintain your own
            Kubernetes control plane or nodes. Fargate is a serverless compute
            engine for containers that works with both Amazon Elastic Container
            Service (Amazon ECS) and Amazon EKS. Fargate makes it easy for you
            to focus on building your applications by removing the need to
            provision and manage servers. By using Amazon EKS Fargate,
            Datamellon and GTBank aim to achieve higher levels of scalability,
            performance, security, reliability, portability, and cost-efficiency
            for the app.
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GTCaseStudy;
