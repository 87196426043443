import React, { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";
import Topmenu from "../../../components/Topmenu/Topmenu";
import fincra from "../../../assets/images/new-fincra.png";
import "../Cstudy1/Cstudy1.css";

export default function Fincra() {
  const img_link = process.env.REACT_APP_IMG_LINK;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Topmenu />
      <div className="cstudy1">
        <div className="home_max_width">
          <div className="casestudcontainer">
            <div className="csheadbg"></div>
            <div className="cstudbody">
              <div className="cstudyclient">
                <div>
                  <div className="cstudytopic">Well Architected Review</div>
                  <div className="cstudynormtxt">
                    Fincra is a leading payment infrastructure provider for
                    fintechs, global businesses, and platforms based in Nigeria.
                    With their application hosted on Amazon Web Services (AWS),
                    Fincra aimed to align with industry best practices, enhance
                    security measures, and reduce operational costs. To achieve
                    these objectives, they sought the expertise of Datamellon, a
                    trusted AWS consulting partner, to conduct a comprehensive
                    Well-Architected Review of their AWS environment.
                  </div>
                </div>
                <div className="vodafone">
                  <img src={fincra} alt="Providius Logo" />
                </div>
              </div>

              <div className="cstudychallenge">
                <div className="cstudytopic">Challenge</div>
                <div className="cstudynormtxt">
                  Fincra recognized the need to optimize their AWS workloads and
                  ensure they were following the recommended Well-Architected
                  Framework. They faced challenges in improving security
                  measures, achieving operational excellence, and optimizing
                  costs across their infrastructure.Fincra recognized the need
                  to optimize their AWS workloads and ensure they were following
                  the recommended Well-Architected Framework. They faced
                  challenges in improving security measures, achieving
                  operational excellence, and optimizing costs across their
                  infrastructure.Fincra recognized the need to optimize their
                  AWS workloads and ensure they were following the recommended
                  Well-Architected Framework. They faced challenges in improving
                  security measures, achieving operational excellence, and
                  optimizing costs across their infrastructure.
                </div>
              </div>

              <div className="cstudysolution">
                <p>
                  <div className="cstudytopic">The Solution</div>
                  <div className="cstudynormtxt">
                    Datamellon's solution involved a thorough evaluation of
                    Fincra's AWS environment based on the six pillars of the
                    Well-Architected Framework: security, operational
                    excellence, cost optimization, reliability, performance
                    efficiency, and scalability. The review identified areas for
                    improvement and provided valuable recommendations tailored
                    to Fincra's specific requirements.
                  </div>
                </p>
                <div className="icon_text_sub_cont">
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Security Enhancement</div>
                      <div className="cstudynormtxt2">
                        To enhance security, Datamellon recommended various
                        measures, including provisioning RDS databases in
                        private subnets, implementing multi-factor
                        authentication (MFA), adopting federated identity for
                        access control, encrypting data at rest, and
                        establishing proper access controls for databases. These
                        recommendations ensured robust security measures across
                        Fincra's infrastructure.
                      </div>
                    </div>
                  </div>

                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Operational Excellence</div>
                      <div className="cstudynormtxt2">
                        Datamellon focused on enhancing operational efficiency
                        by suggesting the adoption of Infrastructure as Code
                        (IaC) to automate resource deployment. They also
                        recommended the creation and deployment of resources
                        into a custom AWS Virtual Private Cloud (VPC) to
                        streamline operations and improve overall efficiency.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Cost Optimization</div>
                      <div className="cstudynormtxt2">
                        Cost optimization recommendations played a crucial role
                        in reducing Fincra's AWS expenditure. Datamellon advised
                        rightsizing EC2 instances, utilizing appropriate
                        instance types based on peak and non-peak periods,
                        leveraging Auto Scaling Group behind load balancers for
                        efficient resource allocation, and optimizing costs
                        through the utilization of EC2 Saving plans and RDS
                        Reservation plans.
                      </div>
                    </div>
                  </div>

                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Reliability Enhancements</div>
                      <div className="cstudynormtxt2">
                        To ensure high reliability, Datamellon suggested
                        deploying instances across multiple availability zones
                        (AZs) within a region. By doing so, Fincra reduced the
                        potential points of failure and significantly improved
                        the overall system reliability of their infrastructure.
                      </div>
                    </div>
                  </div>
                  {/* <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Results</div>
                      <div className="cstudynormtxt2">
                        As a result of the Well-Architected Framework review,
                        ProvidusBank achieved significant benefits. They
                        realized a 33% reduction in AWS costs, with a 26%
                        decrease in EC2 instances cost and a 59% decrease in RDS
                        cost. High-risk and medium-risk issues were effectively
                        mitigated, and security vulnerabilities and cost
                        inefficiencies were promptly addressed.
                        <br />
                        Additionally, the review identified workloads suitable
                        for migration through the MAP Assessment Phase, enabling
                        ProvidusBank to further optimize their cloud
                        infrastructure.
                        <br />
                        By embracing the Well-Architected Framework and
                        implementing the recommended changes, ProvidusBank has
                        achieved enhanced cost efficiency, improved security,
                        and a more resilient and optimized AWS environment.
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              <div
                className="cstudychallenge"
                style={{
                  paddingTop: "20px",
                }}
              >
                <div className="cstudytopic">Results</div>
                <div className="cstudynormtxt">
                  Fincra experienced substantial benefits as a result of the
                  Well-Architected review conducted by Datamellon. The
                  implementation of the recommendations led to a remarkable 33%
                  reduction in AWS costs. This included a 40% decrease in EC2
                  instance costs and an impressive 60% reduction in RDS costs.
                  Furthermore, high-risk and medium-risk issues were effectively
                  mitigated, addressing security vulnerabilities and improving
                  cost inefficiencies.
                  <br />
                  By embracing the Well-Architected Framework and implementing
                  the recommended changes, Fincra achieved enhanced cost
                  efficiency, improved security measures, and a more resilient
                  and optimized AWS environment. This comprehensive
                  transformation empowered Fincra to focus on their core
                  business objectives while leveraging the full potential of
                  their AWS infrastructure.
                </div>
              </div>
              {/* <div className="cstudybenefits">
                <div className="cstudytopic">The Benefits</div>
                <div className="cstudynormtxt">
                  The solution provided a highly scalable, flexible, and highly
                  available system with zero downtime and cost optimization.
                  This improves the user experience of the platform making sure
                  users don’t experience any downtime while making use of the
                  application while providing an efficient infrastructure that
                  is flexible, scalable, and saves cost for the company.
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
