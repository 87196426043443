import React from 'react'
import ViewServiceTemplate from '../ViewServiceTemplate'
// import Cloud_migration from '../../../assets/images/Cloud_migration.svg'
import {FaChartLine} from 'react-icons/fa'
import {FiTarget} from 'react-icons/fi'
// import CloudMigrationHeader from '../../../assets/images/CloudMigrationHeader.png'
import './CloudMigration.css'
import { HiLightBulb } from 'react-icons/hi'
import {BsBarChartLineFill} from 'react-icons/bs'
import {RiShieldKeyholeFill} from 'react-icons/ri'
// import Whypartner1 from './Whypartner1'


const CloudMigration = () => {
    const img_link = process.env.REACT_APP_IMG_LINK

    const data = {
        headerBg : `url("${img_link}/CloudFoundHeader.png")`,
        // headerBg : `url(${CloudMigrationHeader})`,
        service_title: "Cloud Migration",
        service_subtitle : "Accelerate your cloud migration strategy with expert support from Datamellon. Our cloud migration consultants help you achieve a successful migration to the cloud to accelerate your business innovation.",
        paragraph: [
            'Choosing the right cloud solution and managing your cloud migration is a complex undertaking. Datamellon will partner with you to deliver the expertise you need, so you can avoid common pitfalls and accelerate your return on investment. We help you sidestep this challenge. Our expert, cloud architect and DevOps engineers have the skills you need to unblock your strategy and accelerate cloud adoption. As a partner of AWS, Google Cloud, Azure and OCI we understand the end-to-end cloud migration journey.',
            'Our certified cloud migration consultants start by conducting a holistic assessment of your IT environment and business goals.  We then create a customised cloud migration plan designed to support those goals. We’ll handle migration of your applications and workloads, so you can minimise your business risk, disruption and downtime. After a successful migration, our experts can continue to work with you through our ongoing management and optimisation services.',
            'With DevSecOps in our DNA,  we use the latest cloud native DevOps automation and cloud technologies to build secure cloud environments and automated deployment pipelines to make your migration safe, secure and simple. We work closely with your in-house teams to transfer the skills and knowledge they need to ensure your investment in cloud adoption meets the long-term needs of your business. '
        ],
        cardTitle: "Benefits",
        serviceImage: `${img_link}/Cloud_migration.png`,
        cardInfo:[
            {
                iconColor:"linear-gradient(180deg, #C200DE 0%, #DA00DE 100%)",
                title:"Reduced TCO",
                subtitle:"Dynamically matching your capacity to your demand means you only pay for what you need, when you need it.",
                icon:<HiLightBulb size={30} color="white"/>
            },
            {
                iconColor:"linear-gradient(180deg, #FF5B00 0%, #FF7300 100%)",
                title:"Business agility & innovation",
                subtitle:"By removing capacity constraints on development, test and production environments you can accelerate your innovation strategy.",
                icon:<FaChartLine size={30} color="white"/>
            },
            {
                iconColor:"linear-gradient(180deg, #004CFF 0%, #691FFF 100%)",
                title:"Enhanced security",
                subtitle:"All major cloud providers meet, or exceed, various security compliance frameworks and offer advanced security capabilities.",
                icon:<RiShieldKeyholeFill size={30} color="white"/>
            },
            {
                iconColor:"#75B748",
                title:"Improved operability",
                subtitle:"Leveraging features like advanced monitoring, ops automation, data replication and encryption enhances applications’ operability.",
                icon:<BsBarChartLineFill size={30} color="white"/>
            }
        ]
    }
  return (
    <div>
        <ViewServiceTemplate 
            showServices = {false}
            showReady = {false}
            showApproach = {true}
            showWhy = {true}
            ServiceBg={data?.headerBg}
            service_title={data?.service_title}
            service_subtitle={data?.service_subtitle}
            serviceImage={data?.serviceImage}
            paragraph={data.paragraph}
            cardInfo={data.cardInfo}
            cardTitle={data.cardTitle}
        />
    </div>
  )
}

export default CloudMigration