import React from 'react'
import "./WhyHero.css"
import WhyDatamellon from '../../assets/images/WhyDatamellon.png';

const WhyHero = () => {
  return (
    <div className='WhyHeroContainer'>
        <div className="home_max_width">
            <div className="whyBody">
                <div className="textLeftDiv">
                    <h2 className='titleTag'>
                    Why Datamellon
                    </h2>
                    <p className="subtitleTag">
                    Datamellon are a trusted partner on Vodafone's cloud adoption journey. The skills from their team have helped accelerate the use of AWS within our global organisation

                    </p>
                    <p className="subtitleTag">
                    Datamellon are a trusted partner on Vodafone's cloud adoption journey. The skills from their team have helped accelerate the use of AWS within our global organisation

                    </p>
                </div>
                <div className="imageRightDiv">
                    <img src={WhyDatamellon} alt="" width="100%" style={{borderRadius:'20px'}} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default WhyHero
