import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

import "./Startup.css";

function NewEvent() {
  const navigation = useNavigate();
  const img_link = process.env.REACT_APP_IMG_LINK;

  const handleButtonClick = () => {
    window.open("https://bit.ly/devjamlsw23");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="awsstartupbg">
      <section className="topbannersection">
        <div className="devjambanner">
          <div className="banner-image-container">
            <img
              src={`${img_link}/lagosstartupweek.png`}
              className="banner-image"
              alt="Startup Banner"
            />
            <div className="btn_div">
              <button className="image-button" onClick={handleButtonClick}>
                Register Now
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="mobile_btn">
        <div className="btn_div_mobile">
          <button className="image-button-mobile" onClick={handleButtonClick}>
            Register Now
          </button>
        </div>
      </section>

      <section className="second-section">
        <div className="date">
          <div className="date_div">
            <p>Date</p>
          </div>

          <div className="main_div">
            <h1>15 JULY'23</h1>
          </div>
        </div>

        <div className="time">
          <div className="date_div">
            <p>Time</p>
          </div>

          <div className="main_div">
            <h1>9:00am</h1>
          </div>
        </div>

        <div className="venue">
          <div className="date_div">
            <p>Venue</p>
          </div>

          <div className="main_div">
            <h1>
              Datamellon Marquee, Federal Palace Hotel & Casino, V.I. Lagos.{" "}
            </h1>
          </div>
        </div>
      </section>

      <div className="table_part">
        <section className="Table_section">
          <table>
            <tbody>
              <tr>
                <th>Time</th>
                <th>Activity</th>
              </tr>
              <tr>
                <td>9:00 am - 10: 00 am</td>
                <td>Registration</td>
              </tr>
              <tr>
                <td>10:00 am</td>
                <td>Welcome Address</td>
              </tr>

              <tr>
                <td>10:05 am</td>
                <td>Opening Keynote</td>
              </tr>

              <tr>
                <td>10:15 am</td>
                <td>AWS Keynote: Accelerating to Scale on the AWS Cloud</td>
              </tr>

              <tr>
                <td>10:30 am</td>
                <td>
                  First Panelist Session: Success Stories from top startups
                  running on AWS
                </td>
              </tr>
              <tr>
                <th>11:00 am</th>
                <th>Break</th>
              </tr>

              <tr>
                <td>11:05 am</td>
                <td>
                  Data Analytics and Al in the Cloud: Leveraging AWS Services
                  for Insights and Innovation
                </td>
              </tr>

              <tr>
                <td>11:40 am</td>
                <td>
                  Scaling for Success: AWS Managed Services and Auto Scaling
                </td>
              </tr>
              <tr>
                <td>12:20 pm</td>
                <td>
                  Serverless Computing: Revolutionising Application Development
                  with AWS Lambda
                </td>
              </tr>

              <tr>
                <td>1:00 pm</td>
                <td>
                  Cloud Security and Compliance: Safeguarding Your AWS
                  Infrastructure
                </td>
              </tr>
              <tr>
                <th>1:35 pm</th>
                <th>Lunch Break</th>
              </tr>
              <tr>
                <td>2:00 pm</td>
                <td>
                  Leveraging AWS Startup Funding Packages for Growth and
                  Modernisation
                </td>
              </tr>
              <tr>
                <td>2:30 pm</td>
                <td>
                  Second Panelist Session: Q&A with AWS Solution Architects
                </td>
              </tr>

              <tr>
                <td>3:00 pm</td>
                <td>Closing Remarks & Photo Ops</td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>

      <section className="above_footer">
        <div className="image_div">
          <img
            alt="dmawsstartupsbanner"
            src={
              "https://storage.googleapis.com/dm-dev-001.appspot.com/datamellon-web-images/dm-aws-startups-banner.png"
            }
          />
        </div>
      </section>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default NewEvent;
