import React from 'react'
import Footer from '../../components/Footer/Footer'
import Topmenu from '../../components/Topmenu/Topmenu'

const Moonshot = () => {

    const img_link = process.env.REACT_APP_IMG_LINK

  return (
    <div>
      <Topmenu />

<div

style={{backgroundImage: `url("${img_link}/Moonshot.svg")`, height : "100%", minHeight :'100vh', width : '100%', padding : '20px', paddingTop : '140px', display : 'flex', justifyContent : 'center', backgroundPosition :'center', backgroundSize : 'cover', backgroundRepeat : 'no-repeat'}}>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeFMYJUTfYYZ3s4FPW7xttP7WwXxibSD_y8rt_6lcYkeModsQ/viewform?embedded=true" width="640" height="2994" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>

      <Footer />
    </div>
  )
}

export default Moonshot