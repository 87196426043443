import React from "react";
import "./events.css";
import abujasmall from "../../assets/images/abuja-small.png";
import nitda from "../../assets/images/nitda-logo 1.png";
import ondi from "../../assets/images/ondi-logo 1.png";
import fxchallenge from "../../assets/images/fxchallenge.svg";
import levAI from "../../assets/images/LeveragingAI.png";
import aws from "../../assets/images/awss.svg";
import locIcon from "../../assets/images/greenLocationIcon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import qrcode from "../../assets/images/qrcode.svg";
import qrcodenew from "../../assets/images/qrcode_new.svg";

const LatestEvent = () => {
  const img_link = process.env.REACT_APP_IMG_LINK;
  const navigation = useNavigate();
  let location = useLocation();

  const handleClick = () => {
    window.open(
      "https://datamellonevent.zohobackstage.com/VCbreakfast",
      "_blank"
    );
  };

  return (
    // <div className="latestevent">
    //   <div className="latestevent__container">

    //     <img src={abujasmall} alt="Latest Event" className="latestevent__image"/>

    //    <div className="latestevent__description">
    //     <div className="latestevent__description--heading">
    //       <p className="latestevent__green">Upcoming Event</p>
    //       <p className="latestevent__text">Date 26th October 2023</p>
    //       <p className="latestevent__text">Abuja, Nigeria</p>
    //     </div>
    //     <div className="latestevent__description--main">
    //       <h3>Datamellon Summit: Public Sector Digital Innovation Summit</h3>
    //       <div className="latest__moreinfo">
    //         <p className="latestevent__green">Powered by</p>
    //         <div className="latest__moreinfo--images">
    //           <img src={nitda} alt="Nitda Logo" className="latest__nitda"/>
    //           <img src={ondi} alt="Ondi Logo" className="latest__ondi"/>
    //         </div>
    //       </div>
    //       <button>Register Now</button>
    //     </div>
    //   </div>
    // </div>
    // </div>

    <div
      className="latestevent_container"
      style={{
        marginBottom: "50px",
      }}
    >
      <div>
        <img src={levAI} alt="Latest Event" className="latestevent__image" />
      </div>

      <div className="latestevent__description--main-">
        <div className="latestevent__description--heading-">
          <div className="items">
            <span className="latestevent__green small_text_green">
              Upcoming Event
            </span>
          </div>
          <div className="items">
            <span className="latestevent__text small_text">
              Date: 15th August 2024
            </span>
          </div>
          {/* <div className="items" >
            <span className="latestevent__text small_text">Livestorm</span>
          </div> */}
        </div>

        <div className="latestevent__description--main">
          <h3 style={{ fontWeight: "bold" }}>INNOVATE & SCALE </h3>
          <p style={{ fontWeight: "600" }}>
            Leveraging Gen AI for Startup Success
          </p>
          {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: "20px" }}>
  
    <img src={locIcon} style={{ width: "20px" }} />
  <span style={{ color: "#036123", fontWeight: "600", fontSize: "25px", marginLeft: "20px" }}>
    Wheatbaker Hotel, Ikoyi, Lagos
  </span>
</div> */}

          <div className="qrcode_button">
            <div className="latest__moreinfo">
              <span className="latestevent__green">Powered by</span>
              <div className="latest__moreinfo--images">
                {/* <img src={nitda} alt="Nitda Logo" className="latest__nitda" /> */}
                <img
                  onClick={() => navigation("/")}
                  src={`${img_link}/Datamellon_header_logo.svg`}
                  alt="Datamellon icon"
                  style={{ cursor: "pointer" }}
                />
                <span
                  className="latest__aws"
                  style={{ width: "100px", marginLeft: "20px" }}
                >
                  <img src={aws} />
                </span>
              </div>
              {/* <button   >Register here</button> */}
              <button type="button" onClick={handleClick}>
                Register here
              </button>
            </div>

            {/* <div>
              <img src={qrcodenew} alt="qrcode" className="qr_code_img" />
              <p style={{
                visibility: 'hidden'
              }}  >datamellon.com/events</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestEvent;
