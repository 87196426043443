import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Topmenu from "../../components/Topmenu/Topmenu";
import Overhead from "../../components/OverLayHeader/Overhead";
import voda from "../../assets/images/vodafone.svg";
import dil from "../../assets/images/Dillivry.svg";
import "./LambdaAws.css";
import { useNavigate } from "react-router-dom";
import { HiOutlineChevronDoubleRight } from "react-icons/hi";
import Ready from "../../components/Ready/Ready";

const WindowsEC2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const img_link = process.env.REACT_APP_IMG_LINK;
  const navigate = useNavigate();

  return (
    <div>
      <Topmenu />
      <div className="lambda_main">
        <Overhead
          HeaderBG={`url("${img_link}/Lambda_img1.svg")`}
          title="Amazon EC2 for Microsoft Windows Specialization"
          subsub="The trusted, secure and reliable cloud for Windows"
          subtitle="Enterprises running Microsoft workloads are shifting to Amazon EC2 for improved agility, performance, security, and cost savings. But, transitioning from on-premise Windows applications to the cloud can seem challenging and requires careful planning for design and configuration during migration. AWS supports all the necessary tools to build and run Windows applications, including Active Directory, .NET, Microsoft SQL Server, Windows desktop-as-a-service, and all versions of Windows Server."
          subtitle1="Datamellon, an AWS Advanced Partner with expertise in Microsoft technologies, provides comprehensive support for Amazon EC2 and Microsoft Windows Server and relational databases. With deep domain knowledge and hands-on experience, we help you optimize your Amazon EC2 workloads for maximum agility, security, and cost efficiency. Our services include design, architecture, migration, and management, ensuring a seamless and efficient transition to AWS while improving the dependability of your Windows infrastructure."
        />
        <div className="class_cases">
          <div className="home_max_width">
            <div className="containPadding">
              <div className="titler">Benefits</div>
              <div className="flexer" style={{ marginBottom: "40px" }}>
                <div className="icon_text_sub">
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <div className="content__main">
                    <div className="cstudytopic2">
                      Higher reliability and performance
                    </div>
                    <div className="cstudynormtxt2">
                      By migrating Windows workloads to AWS, customers can
                      experience significant improvements in reliability and
                      performance, including a 98% reduction in unscheduled
                      downtime, 71% faster deployment, and a 26% increase in
                      developer productivity.{" "}
                    </div>
                  </div>
                </div>
                <div className="icon_text_sub">
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <div className="content__main">
                    <div className="cstudytopic2">
                      Greater security and identity services
                    </div>
                    <div className="cstudynormtxt2">
                      For greater security and identity services, AWS offer AWS
                      Managed Active Directory securely in the cloud eliminating
                      the need for you to synchronize or replicate data from
                      your existing Active Directory during migrations. With AWS
                      Identity Services, you can also manage identities and
                      permissions at scale.{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flexer" style={{ marginBottom: "40px" }}>
                <div className="icon_text_sub">
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <div className="content__main">
                    <div className="cstudytopic2">
                      More migration experience
                    </div>
                    <div className="cstudynormtxt2">
                      AWS supports organizations in their migration journey with
                      its unparalleled experience and a range of tools and
                      services. With over 230,000 databases migrated using the
                      AWS Database Migration Service, AWS ensure quick and
                      secure migrations with minimal downtime. Our
                      End-of-Support Migration Program helps move legacy Windows
                      Server applications to the latest supported versions on
                      AWS. The AWS Migration Acceleration Program (MAP) for
                      Windows simplifies and reduces the cost of migration with
                      best practices, tools, and incentives, supported by AWS
                      partners and professional services.{" "}
                    </div>
                  </div>
                </div>
                <div className="icon_text_sub">
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <div className="content__main">
                    <div className="cstudytopic2">Lower TCO</div>
                    <div className="cstudynormtxt2">
                      Running Windows on AWS enables customers to boost growth,
                      increase efficiency, and achieve long-term cost savings.
                      IDC reports that customers can reduce their 5-year
                      operational costs by 56% and experience 37% lower
                      infrastructure costs, leading to up to 442% ROI over 3
                      years. AWS also provides cost-saving options with
                      innovative pricing models such as Savings Plans for up to
                      72% savings on Amazon EC2 instances and Amazon EC2 Spot
                      for up to 90% cost reduction on compute instances for
                      fault-tolerant workloads.{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flexer">
                <div className="icon_text_sub">
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <div className="content__main">
                    <div className="cstudytopic2">
                      Flexible licensing options
                    </div>
                    <div className="cstudynormtxt2">
                      AWS provides the most versatile options for utilizing new
                      and existing Microsoft software licenses in the cloud.
                      With Amazon Elastic Compute Cloud (Amazon EC2) or Amazon
                      Relational Database Service (Amazon RDS) license-included
                      instances, customers receive fully compliant SQL Server
                      licenses from AWS. Existing licenses can also be utilized
                      with Amazon EC2 Dedicated Hosts, Dedicated Instances, or
                      EC2 instances with default tenancy through Microsoft
                      License Mobility with Software Assurance. AWS License
                      Manager simplifies tracking of software license usage and
                      minimizes the risk of non-compliance.{" "}
                    </div>
                  </div>
                </div>
                <div className="icon_text_sub">
                  <div className="icon_img">
                    <img src={`${img_link}/Bulb.svg`} alt="" />
                  </div>
                  <div className="content__main">
                    <div className="cstudytopic2">
                      Largest and most extensive Capabilities
                    </div>
                    <div className="cstudynormtxt2">
                      AWS provides access to over 350 Amazon EC2 instances, more
                      than any other cloud provider, offering unparalleled
                      capabilities. AWS is the only provider that offers fully
                      managed services for native-Windows file systems with
                      Amazon FSx for Windows File Server and for Kubernetes on
                      Windows with Amazon EKS, simplifying management and
                      reducing costs. Amazon Elastic Block Store offers
                      exceptional availability (99.999%), durability (99.999%),
                      and performance for demanding SQL Server deployments.
                      Customers prefer AWS for the best and most consistent
                      network performance for Microsoft applications, delivering
                      3 times more throughput and 25% lower latency compared to
                      other cloud providers.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='home_max_width'>
          <div className='Usecases' style={{paddingTop:'50px'}}>
            <div className='title'>
              Customers
            </div>
            <div className='content_usecases' style={{marginBottom:'0px'}}>
              <div className='content'>
                <div className='subtitle' >
                  <img src={voda} alt="" />
                  <div className='imgsub_title'>Group</div>
                </div>
                <div className='normtextx' onClick={() => navigate("/vodafone-case-study")} target="_blank" style={{ cursor: 'pointer', }} >
                  Vodafone delivers Digital FrontDoor apps using AWS Lambda and Amazon DynamoDB <HiOutlineChevronDoubleRight fontSize={10} />
                </div>
              </div>
              <div className='content'>
                <div className='subtitle' >
                  <img src={dil} alt="" />
                  <div style={{ padding: "10px" }}></div>
                </div>
                <div className='normtextx'>
                  Dillivry built an event driven online logistics marketplace with AWS Lambda and Amazon DynamoDB <HiOutlineChevronDoubleRight fontSize={10} />
                </div>
              </div>
              <div className='content'>
                <div className='subtitle'>
                  <img src={voda} alt="" />
                  <div className='imgsub_title'>UK</div>
                </div>
                <div className='normtextx'>
                Vodafone UK serves static assets using CloudFront and AWS Lambda@Edge to process all requests to its website <HiOutlineChevronDoubleRight fontSize={10} />
                </div>
              </div>
              <div className='content'>
                <div className='subtitle' >
                  <img src={voda} alt="" />
                  <div className='imgsub_title' >Cloud Analytics</div>
                </div>
                <div className='normtextx'>
                  Vodafone Group Cloud Analytics automates and delivers a unified machine learning platform with AWS Lambda and Amazon DynamoDB <HiOutlineChevronDoubleRight fontSize={10} />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <Ready bg="white" />
      </div>
      <Footer />
    </div>
  );
};
export default WindowsEC2;
