import React, { useEffect } from 'react'
import Footer from '../../components/Footer/Footer'
import HeroSection from '../../components/HeroSection/HeroSection'
import CloudService from '../../components/Cloud-service/CloudService'
import ServiceOffered from '../../components/Service_offered/ServiceOffered'
import Carouselslide from '../../components/Carouselslide/Carouselslide'
import Complogo from '../../components/Complogo/Complogo'
import Aws_compe from '../../components/Aws_compe/Aws_compe'
import Topmenu from '../../components/Topmenu/Topmenu'

import './Home.css'

<meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Topmenu />
      <HeroSection />
      <CloudService titled={true} />
      <ServiceOffered />
      <Aws_compe />
      <Carouselslide />
      <Complogo />
      <Footer />
    </div>
  )
}

export default Home