import React from 'react'
import CustomBtn from '../Button/CustomButton';
import './Webinar_comp.css';
import "../../../src/"

function Webinar_comp() {
  const img_link = process.env.REACT_APP_IMG_LINK;
  
  return (
    <div className='Webinar_head'>

      <div className="webinar_max_width">


        <div className='click_webinar' >
          <a className='b1' href="https://app.livestorm.co/datamellon/publicsectorcloudpowereddigitaltransformationwithaws?type=detailed"
            target="_blank" rel="noopener noreferrer"><CustomBtn
              type='button'
              txtColor='#75B748'
              pd='4px'
              br='10px'
              fs='16px'
              w='400px'
              bg='#F6FEF9'
              hoverBG='#F6FEF9'
              txt='Click here to reserve a seat to the event'
              fw='700'
              border="1px solid #75B748"
              txtside="center"
              jcont="center"
              pleft="0px"
              fm='HK Grotesk'
              
            // onClick={() => {navigation("https://docs.google.com/forms/d/e/1FAIpQLSfXqOwW5ptXT3wd2WO0fCSzC1f77_l-8WZfMukCctogQkHBSw/viewform?embedded=true") }} 
            /></a>

          <a className='b2' href="https://app.livestorm.co/datamellon/publicsectorcloudpowereddigitaltransformationwithaws?type=detailed"
            target="_blank" rel="noopener noreferrer"><CustomBtn
              type='button'
              txtColor='#75B748'
              pd='4px'
              br='10px'
              fs='16px'
              w='100%'
              mw='400px'
              bg='#F6FEF9'
              hoverBG='#F6FEF9'
              txt='Click here to reserve a seat to the event'
              fw='700'
              border="1px solid #75B748"
              jcont="center"
              fm='HK Grotesk'

            // onClick={() => {navigation("https://docs.google.com/forms/d/e/1FAIpQLSfXqOwW5ptXT3wd2WO0fCSzC1f77_l-8WZfMukCctogQkHBSw/viewform?embedded=true") }} 
            /></a>

        </div>


        <div className="eventinfo">
          <div className="event_txt">
            <div className='event_topic'> About the event</div>
            <div className="event_txt_contain">
              <div className="event_info_txt1">
                Amazon Web Services (AWS) is the world’s most comprehensive and broadly adopted cloud platform, offering over 200 fully featured services from data centers globally. Millions of customers including the fastest-growing startups, largest enterprises, and leading government agencies are using AWS to lower costs, become more agile, and innovate faster.




              </div>
              <div className="event_info_txt2">
                Whether you are looking to leverage AWS for innovation, agility, cost savings, operation efficiency, analytics, machine learning & artificial intelligence or all of the above, Datamellon as an AWS Partner Network (APN) Select Consulting and Service Delivery Partner, our expertise with Amazon Web Services means we've been there and done it when it comes to AWS Cloud. We know you want to do it right and we'll guide and show you how to get there.
              </div>
              <div className="event_info_txt3">
                Join us for this first webinar to learn about how Public Sector Organisations in Nigeria can power their Digital Transformation with Amazon Web Services. With the recent availability of AWS Outposts racks for Nigeria, government ministries, departments, agencies and other public services institutions who are desirous of reducing running costs and improving service delivery can get started with AWS without further delay.
              </div>
            </div>


          </div>

          <div className="event_schedule">
            <div className='event_topic'> Agenda</div>

            <div className="schedule_txt_main">
              {/* <div className="schedule_txt1">
                08:00 - 09:30 | Check in and breakfast
              </div>
              <div className="schedule_txt2">
                09:30 : 09:45 | Welcome and overview
              </div>
              <div className="schedule_txt3">
                09:45 - 10:45 | Customer fireside chats
              </div> */}
              <div className="schedule_txt4">
                10:00 - 10:45 | Public Sector Cloud-Powered Digital Transformation with AWS
                <hr style= {{height:"1px" ,lcolor:"black",backgroundColor:"black",width:"50%",marginTop:"20px" }} />
              </div>
              <div className="schedule_txt5">
                10:45 - 11:00 | Questions and Answers
              </div>
            </div>

          </div>

          <div className="event_txt">
            <div className='event_topic'> About Datamellon</div>
            <div className="event_txt_contain">
              <div className="event_info_txt1">
                Trusted by customers globally, Datamellon has been helping customers to harness the tremendous power of AWS to mordernise and transform their businesses. Whether your business is early in its journey or well on its way to digital transformation, we help chart a path to success.



              </div>
              {/* <div className="event_info_txt2">
                Let's have our Select APN, Service Delivery and Marketplace seller logo on the right hand side of the above paragraph.
              </div> */}
              {/* <div className="event_info_txt3">
                Join us for this first webinar to learn about how Public Sector Organisations in Nigeria can power their Digital Transformation with Amazon Web Services. With the recent availability of AWS Outposts racks for Nigeria, government ministries, departments, agencies and other public services institutions who are desirous of reducing running costs and improving service delivery can get started with AWS without further delay.
              </div> */}
            </div>


          </div>
        </div>


        <div className="event_img_contain">
          <img src={`${img_link}/select.svg`} alt="" />
          <img src={`${img_link}/markets.svg`} alt="" />
          <img src={`${img_link}/service.svg`} alt="" />


        </div>
        <div className='click_webinar2' >
          <a className='b1' href="https://app.livestorm.co/datamellon/publicsectorcloudpowereddigitaltransformationwithaws?type=detailed"
            target="_blank" rel="noopener noreferrer"><CustomBtn
              type='button'
              txtColor='#75B748'
              pd='4px'
              br='10px'
              fs='16px'
              w='400px'
              bg='#F6FEF9'
              hoverBG='#F6FEF9'
              txt='Click here to reserve a seat to the event'
              fw='700'
              border="1px solid #75B748"
              txtside="left"
              jcont="center"
              pleft="0px"
              fm='HK Grotesk'
              
            // onClick={() => {navigation("https://docs.google.com/forms/d/e/1FAIpQLSfXqOwW5ptXT3wd2WO0fCSzC1f77_l-8WZfMukCctogQkHBSw/viewform?embedded=true") }} 
            /></a>

          <a className='b2' href="https://app.livestorm.co/datamellon/publicsectorcloudpowereddigitaltransformationwithaws?type=detailed"
            target="_blank" rel="noopener noreferrer"><CustomBtn
              type='button'
              txtColor='#75B748'
              pd='4px'
              br='10px'
              fs='16px'
              w='100%'
              mw='400px'
              bg='#F6FEF9'
              hoverBG='#F6FEF9'
              txt='Click here to reserve a seat to the event'
              fw='700'
              border="1px solid #75B748"
              jcont="center"
              fm='HK Grotesk'
            

            // onClick={() => {navigation("https://docs.google.com/forms/d/e/1FAIpQLSfXqOwW5ptXT3wd2WO0fCSzC1f77_l-8WZfMukCctogQkHBSw/viewform?embedded=true") }} 
            /></a>

        </div>
      </div>
    </div>
  )
}

export default Webinar_comp