import React from "react";
import "./FSIHerosection.css";

const FSIHerosection = () => {
  const pdfUrl =
    // "https://drive.google.com/uc?export=download&id=1PvE15JEDXNkrMPXGD68qDNqZUkXUzUsi";
    "https://drive.google.com/file/d/1g6fFnpjcHX4QZ2cTMKKpHCCcB-CSjeev/view?usp=sharing";

  return (
    <div className="fsiherosection__container">
      <div className="fsiherosection__description">
        <h2>Financial Services Migration and Modernisation</h2>
        <p>
          Discover how Datamellon can expertly lead your Financial Services
          Institution through a seamless migration and modernization journey.
          Our comprehensive approach unlocks your FSI's complete potential,
          spanning from infrastructure enhancements to fostering innovation.
        </p>
        <a
          href={pdfUrl}
          target="_blank"
          rel="noopener noreferrer"
          // download
          style={{
            textDecoration: "none",
          }}
        >
          <button className="fsi__button">Get PDF</button>
        </a>
      </div>
    </div>
  );
};

export default FSIHerosection;
