import React, { useEffect } from 'react'
import Footer from '../../../components/Footer/Footer'
import Topmenu from '../../../components/Topmenu/Topmenu'
import './Cstudy1.css'

export default function Cstudy1() {
  const img_link = process.env.REACT_APP_IMG_LINK
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Topmenu />
      <div className="cstudy1">
        <div className="home_max_width">
          <div className="casestudcontainer">
            <div className="csheadbg"></div>
            <div className="cstudbody">
              <div className="cstudyclient">
                <div>
                  <div className="cstudytopic">The Client</div>
                  <div className="cstudynormtxt">
                    Datamellon are a trusted partner on <b>Vodafone's</b> cloud
                    adoption journey.
                    <p>
                      The skills from their team have helped accelerate the use
                      of AWS within our global organisation.
                    </p>
                  </div>
                  <div className="cstudysmalltxt">
                    - Oj Adekoya | Principal Cloud Architect GDC Public Cloud
                    Services
                  </div>
                </div>
                <div className="vodafone">
                  <img src={`${img_link}/vodafone_new.svg`} alt="" />
                </div>
              </div>
              <div className="cstudychallenge">
                <div className="cstudytopic">The Challenge</div>
                <div className="cstudynormtxt">
                  Vodafone Group Public Cloud Services(PCS) team manages the
                  global public cloud estate of Vodafone all over the world.
                  Business divisions and the local market’s journey to AWS cloud
                  starts with an AWS account to be provisioned, configured and
                  hardened with security baselines and guardrails by the PCS
                  team. The team needs to evolve an innovative self-service
                  solution to enable everyone in Vodafone wherever they are in
                  the world to create an AWS account within minutes.
                </div>
              </div>
              <div className="cstudysolution">
                <p>
                  <div className="cstudytopic">The Solution</div>
                  <div className="cstudynormtxt">
                    Self-Serve Multi Master AWS Account Provisioning and
                    Management for Vodafone Group Datamellon worked with the PCS
                    team to design and build a serverless self-service solution
                    with ServiceNow Service Catalog integration. The solution -
                    Self-Serve Multi Master AWS Account Provisioning and
                    Management for Vodafone Group was developed with suites of
                    AWS managed serverless services below:
                  </div>
                </p>
                <div className="icon_text_sub_cont">
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">API Integration</div>
                      <div className="cstudynormtxt2">
                        Amazon API Gateway was used to create, publish, monitor
                        and secure all the solution APIs which ServiceNow
                        Service Catalog consumes for application access and
                        functionality from the backend services powered by AWS
                        Lambda. API Gateway handles all the tasks involved in
                        accepting and processing up to hundreds of thousands of
                        concurrent API calls, including traffic management, CORS
                        support, authorization and access control, throttling,
                        monitoring, and API version management.
                      </div>
                    </div>
                  </div>

                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Compute</div>
                      <div className="cstudynormtxt2">
                        The application backend logic was implemented with AWS
                        Lambda. AWS Lambda automatically runs the application
                        code without requiring us to provision or manage
                        servers. Lambda continuously scales with sub second
                        metering and consistent performance.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Database</div>
                      <div className="cstudynormtxt2">
                        Amazon DynamoDB Global Tables with deployments in two
                        regions. Fully managed NoSQL service that provides fast
                        and predictable performance with seamless scalability.
                        DynamoDB performs all of the necessary tasks to maintain
                        and propagate ongoing data changes between the tables
                        across the regions.
                      </div>
                    </div>
                  </div>

                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Deployment</div>
                      <div className="cstudynormtxt2">
                        The solution was deployed with AWS native continuous
                        integration and continuous deployment (CI/CD) toolchains
                        of AWS CodeCommit, CodePipeline and CodeBuild.
                        Deployment artifacts are stored in Amazon Simple Storage
                        Service (Amazon S3). Amazon Simple Storage Service
                        (Amazon S3) is an object storage service that offers
                        industry-leading scalability, data availability,
                        security and performance.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Security</div>
                      <div className="cstudynormtxt2">
                        All data is encrypted at rest and in transit. Deployment
                        artifacts are encrypted with strong, self-signed
                        cryptographic customer keys managed by AWS KMS. User
                        access management was implemented with Amazon Cognito. A
                        simple and secure user sign-up, sign-in, and access
                        control.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cstudybenefits">
                <div className="cstudytopic">The Benefits</div>
                <div className="cstudynormtxt">
                  The solution enables everyone in Vodafone irrespective of
                  location or time in the world to create an AWS account baked
                  with Vodafone security guardrails under their group function
                  or local market master account within minutes without any
                  interaction with any member of the PCS team. The solution
                  freed up a significant amount of time for the PCS team to
                  focus on other innovative solutions and fast tracked the AWS
                  cloud journey for various group functions and local markets in
                  Vodafone.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
