import React from 'react'
import SCard from './SCard'


const AllServices = () => {

    const img_link = process.env.REACT_APP_IMG_LINK

    const services = [
        {
            flexDirection:'row',
            title: 'Cloud foundation' ,
            subtitle:'Build and automate designed platform architecture with speed into secure, scalable and cost optimized cloud infrastructure platforms.',
            link:'/cloud-foundation',
            img:`${img_link}/Cloud_foundation_icon.svg`
        },
        {
            flexDirection:'row-reverse',
            title: 'Cloud Migration' ,
            subtitle:'Migrate application workloads onto built cloud platforms, re-architect and modernise applications throughout the process. We also cover migration strategy, including platform and tooling selection, as well as detailed TCO and business case development.',
            link:'/cloud-migration',
            img:`${img_link}/Cloud_migration.svg`
        },
        {
            flexDirection:'row',
            title: 'Security & DevSecOps' ,
            subtitle:'Improve and modernise your approach to IT security, shifting application and platform security concerns earlier in the development lifecycle and increasing the use of automation. Assistance in building secure platform, deployment and operation of enterprise class cloud platforms.',
            link:'',
            img:`${img_link}/Security_DevSecOps_services.svg`
        },
        {
            flexDirection:'row-reverse',
            title: 'Cost Management & Optimization' ,
            // subtitle:'Improve time-to-market by transforming applications to serverless architecture, allowing you to focus on application development instead of infrastructure. This provides you with increased flexibility and scalability, high availability and no idle capacity.',
            subtitle:'Continuously analyze consumption trends against budgets and get maximum business value with data-driven spending decision.',
            link:'',
            img:`${img_link}/Cost_management _ optimizations1.svg`
        },
        {
            flexDirection:'row',
            title: 'Application Modernisation with Kubernetes' ,
            // subtitle:'Customise the Reference Architecture to your needs, deploy into your respective public cloud accounts, and give you 100% code in days.',
            subtitle:'Deliver and manage containerized, legacy, and cloud-native applications, as well as those being refactored into microservices with Kubernetes.',
            link:'',
            img:`${img_link}/Application_moderns1.svg`
        },
        {
            flexDirection:'row-reverse',
            title: 'Cloud-Native Application Development' ,
            // subtitle:'Build and automate designed platform architecture with speed into secure, scalable and cost optimized cloud infrastructure platforms.',
            subtitle:'Improve time-to-market with cloud-native application and products to maximise the game-changing benefits of the cloud.',
            link:'',
            img:`${img_link}/Cloud-native_Applications1.svg`
        },
        {
            flexDirection:'row',
            title: 'Data Analytics & Machine Learning' ,
            subtitle:'Build big data, analytics, insight and AI/ML by leveraging cloud platforms and data capabilities. Extract data from silos and legacy data warehouses, leverage real-time solutions allowing you to benefit from data-driven competitive advantage.',
            link:'',
            img:`${img_link}/Data analytics _ Machine learnings1.svg`
        },
    ]
  return (
      <div className='home_max_width'>
        <div className='service_mai'>
            {
                services.map((x, index)=>(
                    <SCard 
                        flexDirection={x.flexDirection}
                        key={index}
                        title={x.title} 
                        subtitle={x.subtitle} 
                        link={x?.link}
                        img={x.img}
                    />
                ))
            }
        </div>
      </div>
  )
}

export default AllServices
