import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Topmenu from "../../components/Topmenu/Topmenu";
import Overhead from "../../components/OverLayHeader/Overhead";
import "./LambdaAws.css";
import { useNavigate } from "react-router-dom";
import Ready from "../../components/Ready/Ready";

const CloudFormationSpecialization = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const img_link = process.env.REACT_APP_IMG_LINK;
  const navigate = useNavigate();

  return (
    <div>
      <Topmenu />
      <div className="lambda_main">
        <Overhead
          HeaderBG={`url("${img_link}/Lambda_img1.svg")`}
          title="AWS CloudFormation Solutions"
          subsub="Datamellon's CloudFormation Solutions"
          subtitle="Managing infrastructure resources efficiently and consistently in today’s dynamic cloud environment is crucial for the success of any organization."
          subtitle1="As an AWS Advanced Consulting Partner, Datamellon specializes in leveraging cutting-edge technologies to optimize cloud infrastructures."
        />
        <div className="home_max_width">
          <div>
            <div className="home_max_width">
              <div className="containPadding">
                <div className="titler">Key Features</div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Declarative Templates</div>
                      <div className="cstudynormtxt2">
                        CloudFormation templates describe the desired state of
                        AWS resources and their interdependencies, allowing
                        users to define infrastructure configurations as code.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Resource Provisioning</div>
                      <div className="cstudynormtxt2">
                        It automates the provisioning and lifecycle management
                        of AWS resources, including EC2 instances, S3 buckets,
                        RDS databases, Lambda functions, and more, based on the
                        template specifications.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Dependency Management</div>
                      <div className="cstudynormtxt2">
                        CloudFormation handles resource dependencies and
                        orchestrates the order of resource creation, ensuring
                        that resources are provisioned in the correct sequence
                        to maintain consistency and integrity.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Change Management</div>
                      <div className="cstudynormtxt2">
                        It supports change sets, allowing users to preview
                        proposed changes to their infrastructure before
                        implementation, enabling safe and controlled updates
                        without impacting production environments.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flexer">
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Stack Management</div>
                      <div className="cstudynormtxt2">
                        CloudFormation organizes related resources into logical
                        groups called stacks, enabling the management and
                        versioning of entire cloud environments as a single
                        unit.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="home_max_width">
              <div className="containPadding">
                <div className="titler">Use Cases</div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Infrastructure Orchestration
                      </div>
                      <div className="cstudynormtxt2">
                        CloudFormation simplifies the orchestration of complex
                        infrastructure deployments, including multi-tier
                        applications, distributed systems, and microservices
                        architectures.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Automated Provisioning</div>
                      <div className="cstudynormtxt2">
                        It facilitates the automated provisioning of
                        development, testing, and production environments,
                        ensuring consistency and reproducibility across the
                        software development lifecycle.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flexer" style={{ marginBottom: "40px" }}>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">Disaster Recovery</div>
                      <div className="cstudynormtxt2">
                        CloudFormation enables the creation of disaster recovery
                        (DR) solutions by defining and deploying backup
                        infrastructure configurations in secondary AWS regions,
                        ensuring business continuity and data resilience.
                      </div>
                    </div>
                  </div>
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Compliance and Governance
                      </div>
                      <div className="cstudynormtxt2">
                        It helps enforce compliance and governance policies by
                        codifying security best practices, resource tagging
                        standards, and access controls into CloudFormation
                        templates.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flexer">
                  <div className="icon_text_sub">
                    <div className="icon_img">
                      <img src={`${img_link}/Bulb.svg`} alt="" />
                    </div>
                    <div className="content__main">
                      <div className="cstudytopic2">
                        Hybrid Cloud Deployments
                      </div>
                      <div className="cstudynormtxt2">
                        Organizations can use CloudFormation to automate the
                        deployment of hybrid cloud environments, integrating
                        on-premises infrastructure with AWS resources using AWS
                        Direct Connect or VPN connections.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Ready />
      </div>
      <Footer />
    </div>
  );
};
export default CloudFormationSpecialization;
