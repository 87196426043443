import React from 'react'
import './Whypartner1.css'

import Whypartner2 from '../Services/Whypartner2'

function Whypartner1({titled}) {
     

   const Whyptinfo =[
        {
            Whypttext:" Whether you need expert hands-on engineering from our great team, or a full end-to-end service from initial migration readiness assessments to Discovery and Design through to a fully completed migration, we can help. ",

            Whypttexthead:"Amazing Team",

        },

        {
            Whypttext:"As an AWS, Google Cloud, Microsoft and OCI partner we can unlock partner funding options that can help accelerate your business case for cloud adoption. ",

            Whypttexthead:"Cloud Partner",

        },


        {
            Whypttext:"We have a proven track record in the successful delivery of large and small scale cloud migrations. ",

            Whypttexthead:"Excellent Track Record",

        },
            

        
    ]
   

   


  return (
    <div className="whypartner1main">

       <div className="whypartner1contain">

       <div className="why_part1_contain">
        <div className="home_max_width">
{
   
    <div className="whypartner1txt-major">
                <h2 className="whypartner1ctrl-txt"> Why choose Datamellon as your cloud migration partner?</h2>
            </div>
            
}
            

            <div className="whypartner1capability-contain">
                {
                    Whyptinfo.map((x,i)=>{
                        return(
                            <Whypartner2 whypart_header={x.Whypttexthead}
                            key={i}
                            partcontent={x.Whypttext}
                            />

                        )
                    })
                }
            </div>
        </div>
       </div>
       </div>

    </div>
  )
}

export default Whypartner1