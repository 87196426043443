import React, { useEffect } from 'react'
import "./OverLayHeader.css"

function OverLayHeader({ title, subtitle, subsub, HeaderBG }) {
  return (
    <div className='overLayHeaderBody' style={{
      backgroundImage: `${HeaderBG}`,
    }}>
      <div className='home_max_width'>
        <div className='overLayHeader'>
          <h1>{title}</h1>
          <h2>{subsub}</h2>
          <h3>{subtitle}</h3>
          <div style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0', backgroundColor: 'rgba(0,0,0,0.4)' }}></div>
        </div>
      </div>
    </div>

  )
}

export default OverLayHeader