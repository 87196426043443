import React, { useEffect } from 'react'
import Footer from '../../components/Footer/Footer'
import OverLayHeader from '../../components/OverLayHeader/OverLayHeader'
import Topmenu from '../../components/Topmenu/Topmenu'
import FlexImageText from './FlexImageText/FlexImageText'
import Benefits from './Benefit/Benefits'
import CustomBtn from '../../components/Button/CustomButton'
import CloudService from '../../components/Cloud-service/CloudService'
import MailCustomBtn from '../../components/Button/MailCustomButton'
import Approach from './Services/Approach'
import Whypartner1 from './Services/Whypartner1'

const ViewServiceTemplate = ({
  ServiceBg,
  service_title,
  service_subtitle,
  paragraph,
  cardInfo,
  cardTitle,
  serviceImage,
  showServices,
  showReady,
  showApproach,
  showWhy
}) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Topmenu />
      {
        service_title ?
          <OverLayHeader
            HeaderBG={ServiceBg}
            title={service_title}
            subtitle={service_subtitle}
          /> : <></>
      }
      <FlexImageText
        bgColor="#F9FAFB"
        paragraph={paragraph}
        serviceImage={serviceImage}
      />
      {
        showApproach ? 
        <Approach/>
        :
        null
      }
      <Benefits
        cardInfo={cardInfo}
        cardTitle={cardTitle}
      />
      
      {/* <CloudService/> */}
      {
        showReady ?

        <div style={{width:'100%', padding:'50px 0', display:'flex', gap:'30px', alignItems:'center', flexDirection:'column'}}>
          <div className="titleTag">Ready to connect with us?</div>
            <MailCustomBtn
              type='button'
              txtColor='white'
              pd='5px'
              br='8px'
              fs='16px'
              w='150px'
              bg='var(--main)'
              hoverBG='var(--main)'
              txt="Let's talk"
              fw='400'
              border="1px solid var(--main)"
              onClick={() => { }} />
          </div>
          :
          null
      }
      {
        showWhy ? 
        <Whypartner1/>
        :
        null
      }
      {
        showServices ?
          <CloudService />
          :
          null
      }

      <Footer />
    </div>
  )
}

export default ViewServiceTemplate
