import "./Footer.css";

import { useNavigate } from 'react-router-dom'

export default function Footer() {
  const img_link = process.env.REACT_APP_IMG_LINK

  const navigation = useNavigate()

  return (
    <div className="footer-main-body">
      <div className="home_max_width">

        <div className="footer-main">
          <div className="Container">
            <div className="row">
              {/* Column1 */}
              <div className="col">
                <div className='bolditem'>
                  <h4>Get in touch</h4>
                </div>
                <ul className="List-unstyled">
                  <li>connect@datamellon.com</li>
                  <li>London: +44 (0) 2039954988</li>
                  <li >Lagos: +234 (0) 16331141,</li>
                  <li className="lagnumbers">+234 (0) 16331142 </li>
                  {/* <li className="lagnumbers">+234 (0) 8147728469 </li> */}


                </ul>
              </div>
              {/* Column2 */}
              <div className="col">
                <div className='bolditem'>
                  <h4>London office</h4>
                </div>
                <ul className="List-unstyled">
                  <li>Epworth House,</li>
                  <li>25 City Rd,</li>
                  <li>Shoreditch,</li>
                  <li>London EC1Y 1AA</li>
                </ul>
              </div>
              {/* Column3 */}
              <div className="col">
                <div className='bolditem'>
                  <h4>Lagos office</h4>
                </div>
                <ul className="List-unstyled">
                  <li>7 Adefowope Street,</li>
                  <li>Ilupeju,</li>
                  <li>Lagos,</li>
                  <li>Nigeria</li>

                </ul>
              </div>
              <div className="ctricons">
                <img onClick={() => navigation('/')} src={`${img_link}/Datamellon_footer_logo.svg`} alt='Datamellon icon' style={{ cursor: 'pointer', width: "200px" }} />
                <div className="housicons">
                <span>
                    <a href="https://www.linkedin.com/company/datamellon-limited"
                      target="_blank" rel="noopener noreferrer">
                      <img src={`${img_link}/linkedin_icon.svg`} alt='linkedinicon' style={{ width: "25px" }} />
                    </a>

                  </span>
                  <span> <img src={`${img_link}/facebookicon.svg`} alt='facebookicon' style={{ width: "25px" }} /></span>
                  <span><img src={`${img_link}/twittericon.svg`} alt='twittericon' style={{ width: "25px" }} /></span>
                
                  <span>
                    <img src={`${img_link}/youtubeicon.svg`} alt='youtubeicon'style={{ width: "25px" }} />
                  </span>
                  <span><img src={`${img_link}/instagram_icon.svg`} alt='instagramicon' style={{ width: "25px" }} /> </span>
                </div>
              </div>

            </div>
            <div className="row-pol">
              <p className="col-sm">
                &copy;{new Date().getFullYear()} Datamellon Limited. All rights reserved </p>
              <p>Privacy policy|Cookie Policy</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
