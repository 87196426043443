import React, { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";
import Topmenu from "../../../components/Topmenu/Topmenu";
import providus from "../../../assets/images/Providus_Bank_logo.png";
import "./Cstudy1.css";

const ProvidusCaseStudy = () => {
  const img_link = process.env.REACT_APP_IMG_LINK;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topmenu />
      <div className="payday__container">
        <div className="payday__overview flex">
          <div className="payday__overview--content">
            <h3 className="cstudytopic">Overview</h3>
            <p className="cstudynormtxt2">
              ProvidusBank is an innovative financial institution that provides
              a range of services, including personal, private, corporate,
              commercial, and digital banking products. Their offerings include
              Business Advisory, Portfolio Management, Personalized Relationship
              Management, Fast-tracked Service delivery, and Self-service
              solutions. The bank serves various sectors such as agriculture,
              mining, hospitality, e-commerce, art, and entertainment.
            </p>
          </div>
          <div className="payday__overview--img">
            <img src={providus} alt="Providus Bank Logo" />
          </div>
        </div>

        <div className="payday__opportunity">
          <h3 className="cstudytopic">Opportunity</h3>
          <p className="cstudynormtxt2">
            As ProvidusBank progressed in its digital transformation, the need
            for a more streamlined and efficient solution to manage their API
            endpoints, cloud environment, and critical applications emerged as a
            crucial opportunity. The demand for robust infrastructure capable of
            supporting their growing digital services, including mobile
            applications and core banking operations, was essential for
            sustaining their competitive edge.
          </p>
        </div>

        <div className="payday__opportunity">
          <h3 className="cstudytopic">The Challenge</h3>
          <p className="cstudynormtxt2">
            ProvidusBank faced challenges in maintaining and provisioning
            internal infrastructure, particularly with their mobile application,
            Corporate Internet Banking (CIB) platform, and backup solutions. The
            existing systems were resource-intensive and struggled with
            scalability, limiting the bank's ability to meet evolving digital
            demands. Leadership recognized that failing to address these issues
            could hinder customer experience, compromise security, and increase
            operational costs.
          </p>
        </div>

        <div className="payday__aws">
          <h3 className="cstudytopic">Why AWS</h3>
          <p className="cstudynormtxt2">
            ProvidusBank selected Amazon Web Services (AWS) as their cloud
            provider due to its proven reliability, scalability, security, and
            cost-effectiveness. AWS provided a robust infrastructure capable of
            handling the varied workloads of ProvidusBank, including the
            migration of their Mobile App, CIB, and Veeam Backup. This migration
            to AWS ensured scalability during demand spikes, aligning perfectly
            with the bank's dynamic operational needs.
            <br />
            AWS also offers a comprehensive suite of services that empowered
            ProvidusBank to build a secure, compliant, and modern platform. The
            adaptability of AWS allowed ProvidusBank to choose the most suitable
            tools and technologies for their specific use cases, ensuring the
            infrastructure could evolve with the bank's digital banking needs.
            <br />
            In terms of security, AWS played a critical role in helping
            ProvidusBank comply with industry regulations and standards. The
            platform's security features and compliance frameworks reassured
            customers that their financial transactions were handled with the
            highest protection standards.
          </p>
        </div>

        <div className="payday__datamellon">
          <h3 className="cstudytopic">Why Datamellon</h3>
          <p className="cstudynormtxt2">
            Datamellon, an AWS Advanced Consulting Partner, specializes in cloud
            migration, DevOps, data analytics, AI, and ML. With extensive
            experience in working with financial institutions, Datamellon was
            well-positioned to help ProvidusBank leverage cloud technologies to
            transform their operations. Datamellon has a proven track record of
            successful projects using agile methodologies and best practices.
            For ProvidusBank, Datamellon provided ongoing support and
            maintenance, ensuring that the bank's platform remains secure and
            up-to-date.
          </p>
        </div>

        <div className="cstudysolution">
          <p>
            <div className="cstudytopic">Partner Solution</div>
            <div className="cstudynormtxt">
              Datamellon delivered a comprehensive solution to modernize
              ProvidusBank's infrastructure and enhance security:
            </div>
          </p>
          <div className="icon_text_sub_cont">
            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">
                  Cloud Foundation and AWS Control Tower Implementation:
                </div>
                <div className="cstudynormtxt2">
                  The foundation of the solution was built using AWS Control
                  Tower, which provided a multi-account setup aligned with AWS
                  best practices. This included the establishment of Cloud
                  Foundation, ensuring governance and compliance across all AWS
                  accounts. AWS Organizations and Control Tower enabled
                  ProvidusBank to manage accounts efficiently while maintaining
                  security and compliance.
                </div>
              </div>
            </div>

            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">CI/CD Repositories:</div>
                <div className="cstudynormtxt2">
                  Continuous Integration and Continuous Deployment (CI/CD)
                  pipelines were implemented to streamline development and
                  deployment processes. This automation ensured that updates to
                  ProvidusBank’s applications were rolled out efficiently and
                  securely.
                </div>
              </div>
            </div>

            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">Networking Implementation:</div>
                <div className="cstudynormtxt2">
                  A robust networking infrastructure was established using AWS
                  Direct Connect and site-to-site VPN connections. This setup
                  provided secure and reliable connectivity between
                  ProvidusBank’s on-premises infrastructure and the AWS cloud,
                  enhancing data transfer and operational stability.
                </div>
              </div>
            </div>

            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2"> Workload Migration:</div>
                <div className="cstudynormtxt2">
                  Datamellon successfully migrated ProvidusBank’s critical
                  workloads, including their Mobile App, Corporate Internet
                  Banking (CIB) platform, and Veeam Backup, to AWS. This
                  migration followed a comprehensive workload assessment,
                  ensuring that the move was smooth and that the new environment
                  was optimized for performance and security.
                </div>
              </div>
            </div>

            <div className="icon_text_sub">
              <div className="icon_img">
                <img src={`${img_link}/Bulb.svg`} alt="" />
              </div>
              <div className="content__main">
                <div className="cstudytopic2">API Gateway Implementation:</div>
                <div className="cstudynormtxt2">
                  The core of the solution involved API Gateway implementation
                  with Amazon Cognito for user authentication and AWS WAF for
                  enhanced security. Serverless architecture using AWS Lambda
                  was employed to handle API backend requests, optimizing
                  scalability and cost efficiency. Load balancers were
                  configured to improve the performance and reliability of the
                  API endpoints.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cstudybenefits">
          <div className="cstudytopic">Outcome</div>
          <div
            className="cstudynormtxt"
            style={{
              marginBottom: "20px",
            }}
          >
            The collaboration with Datamellon and migration to AWS resulted in
            significant benefits for ProvidusBank:
          </div>
          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Enhanced Scalability and Flexibility:
              </div>
              <div className="cstudynormtxt2">
                The adoption of AWS, combined with the Cloud Foundation and
                Control Tower implementation, allowed ProvidusBank to achieve a
                highly scalable and flexible infrastructure. The ability to
                dynamically scale resources ensured that the bank could handle
                fluctuating workloads efficiently, improving customer
                experience.
              </div>
            </div>
          </div>
          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Operational Efficiency and Resource Optimization:
              </div>
              <div className="cstudynormtxt2">
                ProvidusBank benefited from optimized operational processes
                through AWS services. The implementation of CI/CD pipelines and
                Cloud Foundation reduced the operational burden on the
                development team, allowing them to focus on innovation and
                reducing time-to-market.
              </div>
            </div>
          </div>

          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Robust Networking and Connectivity:
              </div>
              <div className="cstudynormtxt2">
                The networking solutions provided by AWS, including Direct
                Connect and site-to-site VPN, strengthened the connectivity
                between ProvidusBank's on-premises infrastructure and the AWS
                cloud. This ensured reliable and secure data transfer,
                supporting the bank’s critical applications.
              </div>
            </div>
          </div>

          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">
                Secured and Compliant Environment:
              </div>
              <div className="cstudynormtxt2">
                The API Gateway, combined with Cognito and WAF, enhanced the
                security of ProvidusBank's applications. The migration also
                ensured compliance with industry standards, such as PCI DSS,
                GDPR, and ISO 27001, building customer trust.
              </div>
            </div>
          </div>
          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">Cost Efficiency:</div>
              <div className="cstudynormtxt2">
                The AWS pay-as-you-go model allowed ProvidusBank to optimize
                costs, paying only for the resources they used. This financial
                efficiency aligned with the bank's goals of maintaining a
                cost-effective operation while supporting growth.
              </div>
            </div>
          </div>
          <div
            className="icon_text_sub"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="icon_img">
              <img src={`${img_link}/Bulb.svg`} alt="" />
            </div>
            <div className="content__main">
              <div className="cstudytopic2">Improved User Experience:</div>
              <div className="cstudynormtxt2">
                The migration and infrastructure improvements led to a more
                reliable and responsive user experience. The robust and scalable
                architecture ensured that ProvidusBank could offer uninterrupted
                services, even during peak demand periods.
              </div>
            </div>
          </div>
        </div>

        <div
          className="cstudybenefits"
          style={{
            marginTop: "40px",
          }}
        >
          <div className="cstudytopic">Next Steps</div>
          <div className="cstudynormtxt">
            ProvidusBank's partnership with Datamellon and the migration to AWS
            has provided a scalable, efficient, and secure infrastructure. The
            successful implementation of Cloud Foundation using AWS Control
            Tower and the migration of key applications has positioned
            ProvidusBank to meet the demands of modern digital banking with
            confidence. As ProvidusBank continues to grow, this strategic
            partnership will ensure that their cloud environment remains agile,
            secure, and cost-effective, ready to support future innovations.
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProvidusCaseStudy;
