import React, { useState } from "react";
import { Button, Modal } from "antd";
import "./fsi.css";

const FsiSolutions = () => {
  const [open, setOpen] = useState(false);
  const img_link = process.env.REACT_APP_IMG_LINK;

  return (
    <div className="fsisolutions__container">
      <h2>Our Comprehensive FSI Solutions</h2>
      <div className="fsisolutions__grid">
        <div className="fsisolutions__child">
          <div className="fsisolutions__image">
            <img src={`${img_link}/CloudFoundation.png`} alt="Cloud Foundation" />
          </div>
          <div className="fsisolutions__text">
            <h4>Cloud Foundation</h4>
            <p>
              Secure, scalable, and cost-efficient cloud infrastructure setup
              using AWS Control Tower and Security Guardrails.
            </p>
          </div>
          <button onClick={() => setOpen(true)} className="fsimodal__btn">See Sample Architecture</button>
          <Modal
            title="Landing Zone"
            centered
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            width={1000}
          >
            <img src={`${img_link}/landing-zone.png`} alt="Landing Zone" />
          </Modal>
        </div>

        <div className="fsisolutions__child">
          <div className="fsisolutions__image">
            <img src={`${img_link}/migrationandtransfer.png`} alt="Cloud Migration" />
          </div>
          <div className="fsisolutions__text">
            <h4>Cloud Migration</h4>
            <p>
              Accelerated workload migration to AWS with a focus on efficiency
              and best practices.
            </p>
          </div>
        </div>

        <div className="fsisolutions__child">
          <div className="fsisolutions__image">
            <img src={`${img_link}/severless.png`} alt="Application Modernization" />
          </div>
          <div className="fsisolutions__text">
            <h4>Application Modernization</h4>
            <p>
              Streamlined modernization of FSI applications using AWS
              containerization and serverless solutions.
            </p>
          </div>
        </div>

        <div className="fsisolutions__child">
          <div className="fsisolutions__image">
            <img src={`${img_link}/developertools.png`} alt="Cloud Native Application Development" />
          </div>
          <div className="fsisolutions__text">
            <h4>Cloud Native Application Development</h4>
            <p>
              Support for building cloud-native applications to speed up
              time-to-market
            </p>
          </div>
        </div>

        <div className="fsisolutions__child">
          <div className="fsisolutions__image">
            <img src={`${img_link}/costandusagereport.png`} alt="" />
          </div>
          <div className="fsisolutions__text">
            <h4>Cost Optimization and Billing Management</h4>
            <p>
              Continuous spending trend analysis for data-driven budget
              decisions using AWS tools.
            </p>
          </div>
        </div>

        <div className="fsisolutions__child">
          <div className="fsisolutions__image">
            <img src={`${img_link}/amazonbedrock.png`} alt="AI Solutions" />
          </div>
          <div className="fsisolutions__text">
            <h4>AI Solutions</h4>
            <p>
              Leveraging AWS AI/ML services to enhance operational efficiency
              and drive innovation with FSI data hosted on AWS.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FsiSolutions;
