import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { IoMdMenu } from "react-icons/io";

import "./Topmenu.css";
import DropDownMenu from "../DropDownMenu/DropDownMenu";
import { useNavigate, useLocation } from "react-router-dom";
import MailCustomBtn from "../Button/MailCustomButton";
import ServicesDropDownMenu from "../DropDownMenu/ServicesDropDownMenu";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function Sidebar() {
  const img_link = process.env.REACT_APP_IMG_LINK;
  const navigation = useNavigate();
  let location = useLocation();

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <List
        style={{
          padding: "30px 10px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <ListItem
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <ListItemText onClick={() => navigation("/")}>
            <img src={`${img_link}/Datamellon_header_logo.svg`} width="150px" />
          </ListItemText>
        </ListItem>

        <ListItem
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <ListItemText
            onClick={() => navigation("/")}
            className={
              location.pathname === "/"
                ? "linksContLinkActive"
                : "linksContLink"
            }
          >
            Home
          </ListItemText>
        </ListItem>

        {/* <ListItem onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}>
          <ListItemText 
          onClick={()=>navigation('/services')}
          className={location.pathname === "/services" ?'linksContLinkActive' : "linksContLink"}>Services</ListItemText>
        </ListItem> */}

        <ListItem>
          <ServicesDropDownMenu />
        </ListItem>

        <ListItem
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <ListItemText
            onClick={() => navigation("/partners")}
            className={
              location.pathname === "/partners"
                ? "linksContLinkActive"
                : "linksContLink"
            }
          >
            Partners
          </ListItemText>
        </ListItem>

        <ListItem
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <ListItemText
            onClick={() => navigation("")}
            className={
              location.pathname === "/products"
                ? "linksContLinkActive"
                : "linksContLink"
            }
          >
            Products
          </ListItemText>
        </ListItem>

        {/* <Divider /> */}

        <ListItem
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <ListItemText
            onClick={() => navigation("/case-studies")}
            className={
              location.pathname === "/case-studies"
                ? "linksContLinkActive"
                : "linksContLink"
            }
          >
            Case Studies
          </ListItemText>
        </ListItem>

        <ListItem
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <ListItemText
            onClick={() => navigation("/careers")}
            className={
              location.pathname === "/careers"
                ? "linksContLinkActive"
                : "linksContLink"
            }
          >
            Careers
          </ListItemText>
        </ListItem>

        <ListItem>
          <DropDownMenu />
        </ListItem>

        <ListItem>
          <MailCustomBtn
            type="button"
            txtColor="white"
            pd="3px"
            br="5px"
            fs="14px"
            w="100px"
            bg="var(--main)"
            hoverBG="var(--main)"
            txt="Let's talk"
            fw="500"
            border="1px solid var(--main)"
            onClick={() => {}}
          />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className="sideBarDrawer">
            <IoMdMenu
              onClick={toggleDrawer(anchor, true)}
              style={{ fontSize: "30px" }}
            >
              {anchor}
            </IoMdMenu>
            <img
              src={`${img_link}/Datamellon_header_logo.svg`}
              onClick={() => navigation("/")}
            />
          </div>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
