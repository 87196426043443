import React from 'react'
import CustomBtn from '../Button/CustomButton'
import './Services_card.css'

const ServicesCard = ({ title, subtitle, onClick }) => {
  return (
    <div className='serv_card'>
      <h3>{title}</h3>
      <span>{subtitle}</span>
      <CustomBtn
        type='button'
        txtColor='var(--main)'
        pd='4px'
        br='10px'
        fs='18px'
        w='190px'
        bg='white'
        hoverBG='whitesmoke'
        txt='Learn More'
        fw='700'
        border="1px solid var(--main)"
        onClick={onClick}
      />
    </div>
  )
}

export default ServicesCard
