import React from 'react'
import EventHeroSection from '../../components/Events/EventHeroSection'
import EventInfo from '../../components/Events/EventInfo'
import EventList from '../../components/Events/EventList'
import EventPictures from '../../components/Events/EventPictures'
import LatestEvent from '../../components/Events/LatestEvent'
import Footer from '../../components/Footer/Footer'
import Topmenu from '../../components/Topmenu/Topmenu'

const AllEvents = () => {
  return (
    <div>
      <Topmenu />
      <EventHeroSection />
      {/* <LatestEvent /> */}
      <EventList />
      <EventPictures />
      <EventInfo />
      <Footer />
    </div>
  )
}

export default AllEvents