import React, { useState, useEffect } from 'react'
import './Careerherov2.css';
import ImageSwitcher from './../ImageSwitcher/ImageSwitcher';
import ImageSwitchV3 from '../ImageSwitcher/ImageSwitchV3';
import CustomBtn from '../Button/CustomButton';
import { Link, useNavigate } from 'react-router-dom';

function Careerherov2() {
  const navigation = useNavigate();
  const [index, setindex] = useState(0);

  const img_link = process.env.REACT_APP_IMG_LINK

  const textswitches = [
    {
      text: "Design",
      textcolor: "#3498FE"
    },
    {
      text: "Code",
      textcolor: "#75B748"
    },
    {
      text: "Build",
      textcolor: "#6941C6"
    },
    {
      text: "Create",
      textcolor: "#EB5757"
    },
  ]

  const switchText = (i) => {
    setTimeout(() => {
      if (i === (textswitches.length - 1)) {
        setindex(0)
      }
      else {
        setindex(i + 1)
      }
    }, 5000)
  }

  useEffect(() => {
    switchText(index)
  }, [index])


  return (
    <div className="careerherocontainer" >
      <div className="home_max_width">
        <div className='careerherosectionbody' >
          <div className="careerleft-hero-section">
            {/* <h2 className="careerlhero-section-text-bold">Let’s <span style={{ color: textswitches[index]?.textcolor }}>{textswitches[index]?.text}</span> the future together.</h2> */}
            <h2 className="careerlhero-section-text-bold">Let’s <span style={{ color: '#75B748' }}>Build</span> the future together.</h2>

            <div className="careerlefthero-section-text-norm ">
              <h3>Join a fun and flexible workplace where you’ll be inspired to do what you love.</h3>
              <h2 style={{ marginBottom: '20px' }}> It’s more than a job!</h2>
              <h3 >We are always on the lookout for exceptional talents. What are you waiting for? Start your future at Datamellon today.</h3>

            </div>
          </div>
          <div className="careerright-hero-section">
            {/* <ImageSwitchV3 /> */}

            <div className="part-image-context">
              <img data-aos="fade-right" src={`${img_link}/careerNew.png`} alt="step by step career" className='heroimage' style={{}} />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Careerherov2