import React from 'react'
import './events.css'

const EventInfo = () => {
  return (
    <div className='eventinfo__container'>
        <h3>Let's talk about maximising the value of your cloud.</h3>
        <p>From cost optimization to cloud migration we’re here to make the public cloud easy — without the costs.</p>
        <button>Get Connected</button>
    </div>
  )
}

export default EventInfo