import React from 'react'
import './Cloud_service.css'
import ServiceCard from '../Servicecard/ServiceCard';





function CloudService({titled}) {
    const img_link = process.env.REACT_APP_IMG_LINK 

   const Cardinfo =[
        {
            text:"Experience the broad range of AWS services to power your business innovation with agility and lower costs ",

            texthead:"Amazon Web Services",

            icon: `${img_link}/aws_icon.svg`,

            url:"/aws",

            url_large:"/aws"

            

        },

        {   
            text:"Explore how google cloud solutions can help improve efficiency and agility, reduce cost, participate in new business models, and capture new market opportunities. ",

            texthead:"Google cloud",

            icon: `${img_link}/Google_cloud_homepage.svg`,

            url:"https://cloud.google.com/",
        },


        {
            text:"Bring new solutions to life with Azure to solve today's challenges and create the future for your business. ",

            texthead:"Azure",

            icon: `${img_link}/Azure_image_home.svg`,

            url:"https://azure.microsoft.com/",
        },
            

        {   
            text:"Rethink your engineering and systems design with Oracle cloud innovations that accelerate migrations, deliver better reliability and performance for all applications.",

            texthead:"Oracle Cloud Infrastructure",

            icon: `${img_link}/Oracle_webview.svg`,

            url:"https://www.oracle.com/",
        },
    ]


  return (
    <div className="cloud_service">

       <div className="contain">

       <div className="c_service_contain">
        <div className="home_max_width">
        {
            titled ? 
            <div className="txt-major">
                <h2 className="ctrl-txt"> Our expertise and capabilities cuts across all the cloud platforms</h2>
            </div>
            :null
        }

            <div className="capability-contain">
                {
                    Cardinfo.map((x,i)=>{
                        return(
                            <ServiceCard content_header={x.texthead}
                            key={i}
                            icon={x.icon} 
                            content={x.text}
                            url={x.url}
                            url_large={x.url_large}/>

                        )
                    })
                }
            </div>
        </div>
       </div>
       </div>

    </div>
  )
}

export default CloudService